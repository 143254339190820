import React, { FunctionComponent, MutableRefObject, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import { LocationCustomPagingComponent, Main, PagingQuote, Slide, Text } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import GTM from 'features/services';
import { useMultilingualText } from 'features/hooks';
import { useLocationData } from 'features/hooks/useLocationData';

const settings = {
  className: 'center',
  dots: false,
  speed: 800,
  slidesToShow: 3,
  centerMode: true,
  dotsClass: 'dots',
  swipe: true,
  arrows: false,
  infinite: true,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,

      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }],
};


export const SliderLocationQuote: FunctionComponent = () => {
  const { key } = useLocationData();
  const quote1 = useMultilingualText('quote1', 'whereWork');
  const quote2 = useMultilingualText('quote2', 'whereWork');
  const quote3 = useMultilingualText('quote3', 'whereWork');

  const items = [quote1, quote2, quote3];

  const slider: MutableRefObject<any> = useRef<Slider>(null);
  const slidesCount = items.length;
  const [activeSlide, setActiveSlide] = useState(0);

  const { t } = useTranslation("whereWork");
  const getMultilingualItem = (key) => t(key);

  const onSwipeHandler = (direction) => {
    if (direction === 'left' && activeSlide < slidesCount)
      setActiveSlide(prevState => ++prevState);
    if (direction === 'left' && activeSlide + 1 >= slidesCount)
      setActiveSlide(0);
    if (direction === 'right' && activeSlide === 0)
      setActiveSlide(slidesCount - 1);
    if (direction === 'right' && activeSlide > 0)
      setActiveSlide(prevState => --prevState);

  };
  const renderDots = () => {
    const table: number[] = [];
    for (let i = 0; i < items.length; i++) {
      table.push(i);
    }

    return table.map((x) => <LocationCustomPagingComponent
      key={`slide-no-${x}`}
      active={activeSlide === x}
      onClick={() => {
        GTM(`${key}ClickSlider`, `${getMultilingualItem('interesting')}: ${items[x]}`, `${activeSlide + 1} to ${x + 1}`);
        setActiveSlide(x);
        slider.current.slickGoTo(x);
      }} />);
  };

  const renderQute = () => {
    return items.map((items, index) =>
      <Slide key={index} slideOpacity={activeSlide === index ? false : true}>
        <Text>
          <p>
            {items}
          </p></Text>
      </Slide>,
    );
  };

  return (
    <Main>
      <h1><MultilingualText tKey="interesting" area="whereWork" /></h1>
      <Slider {...settings} ref={slider} onSwipe={onSwipeHandler}>

        {renderQute()}
      </Slider>
      <PagingQuote>
        {renderDots()}
      </PagingQuote>
    </Main>
  );
};
