import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import { ActionText, WatchItBox } from 'view/pages/about-us/components/Feature/styles';
import playButton from 'assets/images/employee-programs/play-button-large.png';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import BannerVideoImg from 'assets/images/smart-work/masrt-work-banner-video.jpg';
import Container from 'view/components/content/container';
import GTM from 'features/services';
import VideoModal from 'view/pages/about-us/components/VideoModal';
import { RowTitleVideoHappyParents, TitleVideoHappyParents, VideoHappy, PlayButtonHappy } from './styles';
import {useSelector} from "react-redux";
import {languageSelect} from "../../../../features/language";


export const VideoSmartWork: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const youtubeVideoIdPL = 'f8Dtk-oAzoE';
  const youtubeVideoIdEN = 'r72BC15UPNY';
  const { lang } = useSelector(languageSelect);
  const aboutUsFeatureHeader = <MultilingualText tKey="video-happy-parents-title" area="smartWork" />;
  const onModalClose = () => {
    setIsModalOpen(false);
    setTags('aboutUsVideoExits');
  };
  const onModalOpen = () => {
    setIsModalOpen(true);
    setTags('aboutUsVideoEnters');
  };

  const setTags = (id) => {
    GTM(id, `https://www.youtube.com/watch?v=${ lang === 'en' ? youtubeVideoIdEN : youtubeVideoIdPL }`);
  };

  return <>
    <Container>
      <RowTitleVideoHappyParents>
        <TitleVideoHappyParents><MultilingualText tKey="video-smart-work-title"
                                                  area="smartWork" /></TitleVideoHappyParents>
      </RowTitleVideoHappyParents>

    </Container>
    <Container type="large">
      <Row>
        <Col col={ 12 } noGutter={ true }>
          <VideoModal youtubeVideoId={ lang === 'en' ? youtubeVideoIdEN : youtubeVideoIdPL } open={ isModalOpen } closeModal={ onModalClose }
                      header={ aboutUsFeatureHeader.toString() } />
          <LazyloadBackgroundImage image={ BannerVideoImg } width="100%" height="50rem">
            <VideoHappy backgroundImage={ BannerVideoImg } onMouseUp={ onModalOpen }>
              <WatchItBox>
                <PlayButtonHappy src={ playButton } />
                <ActionText><MultilingualText tKey="featureVideoButtonText" area="aboutUs" /></ActionText>
              </WatchItBox>
            </VideoHappy>
          </LazyloadBackgroundImage>
        </Col>
      </Row>
    </Container>
  </>;
};
