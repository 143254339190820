import styled, { css } from 'styled-components';


export const StyledPopup = styled.div<{
  open: boolean;
  hasPadding?: boolean;

}>`
  display: none;
  position:absolute;
  top:100%;
  box-shadow: 5px 5px 25px 0 #19191919;
  background-color: #ffffff;
  padding: 2.5rem;
  z-index: 1;
  
  ${props => props.open && css`
    display: block;
`}
  ${props => props.hasPadding === false && css`
    padding: 0;
  `}
`;


export const StyledTrigger = styled.span`
  span:hover + ${StyledPopup}{
    display: block;
  }
`;

