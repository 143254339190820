import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import { Col, Row } from 'styled-bootstrap-grid';
import Quote from '../../../../assets/images/shared/quote.png';
import FirstPersoneImg from 'assets/images/employee-programs/firstpersone-review.png';
import SecondPersoneImg from 'assets/images/employee-programs/secondpersone-review.png';
import ThirdPersoneImg from 'assets/images/employee-programs/thirdpersone-review.png';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';
import { RowItemReview, ItemReviewImg, ItemReviewDescription, ItemReviewIcon } from './styles';


export const ReviewsWorkers: FunctionComponent = () => {

  return <>
    <Container>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={true}>
          <ItemReviewImg backgroundImage={ FirstPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="first-person-desc" area="happy_parents" /></p>
          </ItemReviewDescription>
        </Col>

      </RowItemReview>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={true}>
          <ItemReviewImg backgroundImage={ SecondPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="second-person-desc" area="happy_parents" /></p>

          </ItemReviewDescription>
        </Col>

      </RowItemReview>
      <RowItemReview>
        <Col lg={ 5.134 } md={ 7 } sm={ 7 } xs={ 6.5 } noGutter={true}>
          <ItemReviewImg backgroundImage={ ThirdPersoneImg } />
        </Col>
        <Col offset={ 1 } lg={ 5.3 } md={ 10 } sm={ 12 } noGutter={true}>
          <ItemReviewDescription>
            <ItemReviewIcon src={ Quote } alt="" />
            <p><MultilingualText tKey="third-person-desc" area="happy_parents" /></p>
          </ItemReviewDescription>
        </Col>

      </RowItemReview>
    </Container>

  </>;
};
