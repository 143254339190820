const teamsData = [
  { id: 6, name: 'Procurement', tKey: 'teams_procurement', path: 'procurement' },
  { id: 3, name: 'IT', tKey: 'teams_it', path: 'it' },
  { id: 4, name: 'Finances', tKey: 'teams_finances', path: 'finances' },
  { id: 2, name: 'People & Culture', tKey: 'teams_peopleCulture', path: 'people-culture' },
  { id: 12, name: 'Commercial Strategy & Planning', tKey: 'teams_commercialStrategyPlanning', path: 'commercial-strategy-planning' },
  { id: 8, name: 'Legal & Ethics & Compliance', tKey: 'teams_legalEthicsCompliance', path: 'legal-ethics-compliance' },
  { id: 9, name: 'Administration', tKey: 'teams_administration', path: 'administration' },
  { id: 10, name: 'Marketing & Digital', tKey: 'teams_marketingDigital', path: 'marketing-digital' },
  {
    id: 11,
    name: 'Consumer Experience & Customer Care',
    tKey: 'teams_consumerExperienceCustomerCare',
    path: 'consumer-experience-customer-care',
  },
  { id: 13, name: 'Production', tKey: 'teams_production', path: 'production' },
  { id: 1, name: 'Sales', tKey: 'teams_sales', path: 'sales' },

];
export { teamsData };
