import * as React from 'react';
import { Col } from 'styled-bootstrap-grid';
import {
  BlockSocialMedia,
  FacebookLogoIcon,
  LinkedinLogoIcon,
  LinkSocialMedia,
  LiSocialMedia,
  TitleSocialMedia,
  TwitterLogoIcon,
  UlSocialMedia,
} from '../styles';
import { FunctionComponent } from 'react';

type PropsLink = {
  hrefLinkedIn: string,
  hrefFacebook:string,
  hrefTwitter:string
}

export const ShereSocialBlock: FunctionComponent<PropsLink> = ({hrefLinkedIn,hrefFacebook,hrefTwitter}) => {

  return <>
    <Col lg={ 3 }><BlockSocialMedia>
      <UlSocialMedia>
        <TitleSocialMedia>Udostępnij</TitleSocialMedia>
        <LiSocialMedia><LinkSocialMedia href={ hrefLinkedIn }
                                        target="_blank"> <LinkedinLogoIcon /> <span>LinkedIn</span>
        </LinkSocialMedia></LiSocialMedia>
        <LiSocialMedia> <LinkSocialMedia href={ hrefFacebook }
                                         target="_blank"><FacebookLogoIcon />
          <span>Facebook</span></LinkSocialMedia></LiSocialMedia>
        <LiSocialMedia><LinkSocialMedia href={ hrefTwitter }
                                        target="_blank"><TwitterLogoIcon />
          <span>Twitter</span></LinkSocialMedia></LiSocialMedia>
      </UlSocialMedia>
    </BlockSocialMedia>
    </Col>
  </>;
};