import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'styled-bootstrap-grid';

import Container from 'view/components/content/container';
import { BreadcrumbsComponent } from 'view/components/content/Breadcrumbs/breadcrums';
import { ContentPrivacy, CustomTable, HeaderPrivacy } from './styles';
import MultilingualText from '../../components/content/MultilingualText/MultilingualText';
import TextComponent from 'view/components/content/TextComponent/TextComponent';

export const SplitDots = ({ klucz, area }) => {
  const { t } = useTranslation(area);

  return <Fragment>{t(klucz).split('&dot&').map(x => <li><TextComponent>{x}</TextComponent></li>)}</Fragment>;
};
export const SplitP = ({ klucz, area }) => {
  const { t } = useTranslation(area);

  return <Fragment>{t(klucz).split('&p&').map(x => <p><TextComponent>{x}</TextComponent></p>)}</Fragment>;
};

export const PrivacyPage: FunctionComponent = () => {


  return <>
    <BreadcrumbsComponent nameOfPage={'Polityka prywatności'} />
    <Container>
      <Row>
        <Col lg={8} lgOffset={2}>
          <HeaderPrivacy><MultilingualText tKey="headerPrivacy" area="privacy" /></HeaderPrivacy>
          <ContentPrivacy>
            <h3><MultilingualText tKey="subHeader" area="privacy" /></h3>
            <p><MultilingualText tKey="text1" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul1li1" area="privacy" />
              </li>
              <li><MultilingualText tKey="ul1li2" area="privacy" />
              </li>
            </ul>
            <h3><MultilingualText tKey="subTitle1" area="privacy" /></h3>
            <p><MultilingualText tKey="text2" area="privacy" /></p>
            <ul>
              <li>
                <MultilingualText tKey="ul2li1" area="privacy" />
              </li>
              <li><MultilingualText tKey="ul2li2" area="privacy" />
              </li>
              <li>
                <MultilingualText tKey="ul2li3" area="privacy" />
              </li>
            </ul>
            <p><MultilingualText tKey="subtitle1text" area="privacy" /></p>
            <p><MultilingualText tKey="text3" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul3li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li5" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li6" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li7" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li8" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li9" area="privacy" /></li>
              <li><MultilingualText tKey="ul3li10" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text4" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul4li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li5" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li6" area="privacy" /></li>
              <li><MultilingualText tKey="ul4li7" area="privacy" /></li>
            </ul>

            <p><MultilingualText tKey="text5" area="privacy" /></p>
            <p><MultilingualText tKey="text6" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul5li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul5li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul5li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul5li4" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text7" area="privacy" /></p>

            <h3><MultilingualText tKey="subTitle2" area="privacy" /></h3>
            <p><MultilingualText tKey="text8" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul6li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li5" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li6" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li7" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li8" area="privacy" /></li>
              <li><MultilingualText tKey="ul6li9" area="privacy" /></li>
            </ul>

            <p><MultilingualText tKey="text9" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul7li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul7li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul7li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul7li4" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text10" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul8li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul8li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul8li3" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text11" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul9li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul9li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul9li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul9li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul9li5" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text12" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul10li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li5" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li6" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li7" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li8" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li9" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li10" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li11" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li12" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li13" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li14" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li15" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li16" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li17" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li18" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li19" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li20" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li21" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li22" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li23" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li24" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li25" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li26" area="privacy" /></li>
              <li><MultilingualText tKey="ul10li27" area="privacy" /></li>
            </ul>

            <p><MultilingualText tKey="text13" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul11li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul11li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul11li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul11li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul11li5" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text14" area="privacy" /></p>

            <h3><MultilingualText tKey="subTitle3" area="privacy" /></h3>
            <p><MultilingualText tKey="text15" area="privacy" /></p>
            <p><MultilingualText tKey="text16" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul12li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li4" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li5" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li6" area="privacy" /></li>
              <li><MultilingualText tKey="ul12li7" area="privacy" /></li>

            </ul>

            <p><MultilingualText tKey="text17" area="privacy" /></p>
            <ul>
              <li><MultilingualText tKey="ul13li1" area="privacy" /></li>
              <li><MultilingualText tKey="ul13li2" area="privacy" /></li>
              <li><MultilingualText tKey="ul13li3" area="privacy" /></li>
              <li><MultilingualText tKey="ul13li4" area="privacy" /></li>
            </ul>
            <p><MultilingualText tKey="text18" area="privacy" /></p>
            <Row>
              <Col>
                <CustomTable>
                  <tbody>
                    <tr>
                      <td>&nbsp;<strong><strong><MultilingualText tKey="t1td1" area="privacy" /></strong></strong>
                      </td>
                      <td>&nbsp;<strong><strong><MultilingualText tKey="t1td2" area="privacy" /></strong></strong></td>
                    </tr>
                    <tr>
                      <td><strong><em></em></strong>
                        <p><strong><em><MultilingualText tKey="t1p1" area="privacy" /></em></strong></p>
                        <ul>
                          <li><MultilingualText tKey="t1li1" area="privacy" /></li>
                        </ul>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li2" area="privacy" /></em></li>
                        </ul>
                        <p>
                        </p>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p2" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p3" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><strong><em><MultilingualText tKey="t1p4" area="privacy" /></em></strong></p>
                        <ul>
                          <li><MultilingualText tKey="t1li3" area="privacy" /></li>
                        </ul>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li4" area="privacy" /></em></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p5" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p6" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;<strong><em></em></strong>
                        <p><strong><em><MultilingualText tKey="t1p7" area="privacy" /></em></strong></p>
                        <ul>
                          <li><MultilingualText tKey="t1li5" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li6" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li7" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li8" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li9" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li10" area="privacy" /></li>
                        </ul>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li11" area="privacy" /></em></li>
                          <li><MultilingualText tKey="t1li12" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p8" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p9" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p><strong><em></em></strong></p>
                        <p><strong><em><MultilingualText tKey="t1p10" area="privacy" /></em></strong></p>
                        <ul>
                          <li><MultilingualText tKey="t1li13" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li14" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li15" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li16" area="privacy" /></li>
                        </ul>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li17" area="privacy" /></em></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p11" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p12" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><em></em></strong>
                        <p><strong><em><MultilingualText tKey="t1p13" area="privacy" /></em></strong></p>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li18" area="privacy" /></em></li>
                        </ul>
                        <p>
                        </p>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p14" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p15" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><em></em></strong>
                        <p><strong><em><MultilingualText tKey="t1p16" area="privacy" /></em></strong></p>
                        <ul>
                          <li><MultilingualText tKey="t1li19" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li20" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li21" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li22" area="privacy" /></li>
                          <li><MultilingualText tKey="t1li23" area="privacy" /></li>
                        </ul>
                        <ul>
                          <li><strong><em> </em></strong><em><MultilingualText tKey="t1li24" area="privacy" /></em></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p17" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p18" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><em></em></strong>
                        <p><strong><em><MultilingualText tKey="t1p19" area="privacy" /></em></strong></p>
                        <ul>
                          <li>
                            <li><MultilingualText tKey="t1li25" area="privacy" /></li>
                          </li>
                        </ul>
                        <ul>
                          <li><em><MultilingualText tKey="t1li25" area="privacy" /></em></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="t1p20" area="privacy" /></p>
                        <p><MultilingualText tKey="t1p21" area="privacy" /></p>
                      </td>
                    </tr>
                  </tbody>
                </CustomTable>
              </Col>
            </Row>

            <p><MultilingualText tKey="text19" area="privacy" /></p>
            <p><MultilingualText tKey="text20" area="privacy" /></p>
            <h3><MultilingualText tKey="subTitle4" area="privacy" /></h3>
            <p><MultilingualText tKey="text21" area="privacy" /></p>
            <h3><MultilingualText tKey="subTitle5" area="privacy" /></h3>
            <p><MultilingualText tKey="text22" area="privacy" /></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul14'} />

            </ul>
            <p><MultilingualText tKey="text23" area="privacy" /></p>
            <p><strong><em><MultilingualText tKey="subTitle6" area="privacy" /></em></strong></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul15'} />
            </ul>
            <p><MultilingualText tKey="text24" area="privacy" /><a
              href="https://www.pmiprivacy.com/en/candidate/affiliates"><MultilingualText tKey="here" area="privacy" /></a>
            </p>
            <p><strong><em><MultilingualText tKey="text25" area="privacy" /></em></strong></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul16'} />
            </ul>
            <h3><MultilingualText tKey="subTitle7" area="privacy" /></h3>
            <SplitP area={'privacy'} klucz={'texts1'} />
            <ul>

              <li><MultilingualText tKey="lia1" area="privacy" /> <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu"><MultilingualText
                  tKey="textUrl1" area="privacy" /></a>;
              </li>
              <li><MultilingualText tKey="lia2" area="privacy" /> <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"><MultilingualText
                  tKey="textUrl2" area="privacy" /></a>
              </li>
              <li><MultilingualText tKey="lia3" area="privacy" />
              </li>
            </ul>
            <p><MultilingualText tKey="text26" area="privacy" /></p>
            <h3><MultilingualText tKey="subTitle8" area="privacy" /></h3>
            <p><MultilingualText tKey="text27" area="privacy" /></p>
            <h3><MultilingualText tKey="subTitle9" area="privacy" /></h3>
            <SplitP area={'privacy'} klucz={'texts2'} />
            <Row>
              <Col>
                <CustomTable>
                  <tbody>
                    <tr>
                      <td>&nbsp;<strong><strong><MultilingualText tKey="tdTitle1" area="privacy" /></strong></strong>
                      </td>
                      <td>&nbsp;<strong><strong><strong><MultilingualText tKey="tdTitle2"
                        area="privacy" /></strong></strong></strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="tdLi1" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <SplitP area={'privacy'} klucz={'tdTexts'} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="tdLi2" area="privacy" />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="tdText2" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="tdLi3" area="privacy" />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="tdText3" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="tdLi4" area="privacy" />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="tdText4" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="tdLi5" area="privacy" />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="tdText5" area="privacy" /></p>
                      </td>
                    </tr>
                  </tbody>
                </CustomTable>
              </Col>
            </Row>
            <h3><MultilingualText tKey="subTitle10" area="privacy" /></h3>
            <p><MultilingualText tKey="text28" area="privacy" /></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul17'} />
            </ul>
            <p><MultilingualText tKey="text29" area="privacy" /><a
              href="mailto:YourHR@pmi.com">YourHR@pmi.com</a>, <MultilingualText tKey="text30" area="privacy" /></p>
            <p><MultilingualText tKey="text31" area="privacy" /></p>
            <Row>
              <Col>
                <CustomTable>
                  <tbody>
                    <tr>
                      <td><strong><strong><MultilingualText tKey="td1Title1" area="privacy" /></strong></strong>
                      </td>
                      <td><strong><strong><MultilingualText tKey="td1Title2" area="privacy" /></strong></strong></td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li1" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text1" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists'} />

                          <li><MultilingualText tKey="td1aLi1" area="privacy" /> <a
                            href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en#dataprotectionincountriesoutsidetheeu"><MultilingualText
                              tKey="td1a1" area="privacy" /> </a>
                            <MultilingualText tKey="td1aLi2" area="privacy" />
                          </li>
                          <SplitDots area={'privacy'} klucz={'td1ListsN'} />
                        </ul>
                        <p><MultilingualText tKey="td1Text2" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li2" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text3" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li3" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text4" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists1'} />
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li4" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text5" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists2'} />
                          <p><MultilingualText tKey="td1Text6" area="privacy" /></p>
                          <SplitDots area={'privacy'} klucz={'td1Lists3'} />
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li5" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text7" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists4'} />
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li6" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text8" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li7" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Texts1" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists5'} />
                        </ul>
                        <p><MultilingualText tKey="td1Text9" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li8" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <p><MultilingualText tKey="td1Text10" area="privacy" /></p>
                        <ul>
                          <SplitDots area={'privacy'} klucz={'td1Lists8'} />
                        </ul>
                        <p><MultilingualText tKey="td1Text11" area="privacy" /></p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li><MultilingualText tKey="td1Li9" area="privacy" /></li>
                        </ul>
                      </td>
                      <td>
                        <SplitP area={'privacy'} klucz={'td1Texts2'} />
                        <p>
                          <a
                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                            target="_blank" rel="noopener noreferrer"><MultilingualText tKey="td1aText" area="privacy" /></a>
                        </p>
                        <p><MultilingualText tKey="td1Text13" area="privacy" /></p>
                      </td>
                    </tr>
                  </tbody>
                </CustomTable>
              </Col>
            </Row>
            <h3><MultilingualText tKey="subTitle11" area="privacy" /></h3>
            <p><MultilingualText tKey="text32" area="privacy" /></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul18'} />
            </ul>
            <p><MultilingualText tKey="text33" area="privacy" /></p>
            <ul>
              <SplitDots area={'privacy'} klucz={'ul19'} />
            </ul>
            <p><MultilingualText tKey="text34" area="privacy" /></p>
            <h3><MultilingualText tKey="subTitle12" area="privacy" /></h3>
            <p><MultilingualText tKey="text35" area="privacy" /> <a
              href="mailto:YourHR@pmi.com">YourHR@pmi.com</a>, <MultilingualText tKey="text36" area="privacy" /> <a
                href="https://www.pmiprivacy.com/en/candidate/affiliates"
                target="_blank" rel="noopener noreferrer">tutaj</a>.
              <MultilingualText tKey="text37" area="privacy" /></p>
            <p><MultilingualText tKey="subTitle13" area="privacy" /></p>
            <h3><MultilingualText tKey="text38" area="privacy" /></h3>
            <SplitP area={'privacy'} klucz={'texts3'} />
          </ContentPrivacy>
        </Col>
      </Row>
    </Container>
  </>;

};