import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import { useBreakpoints } from 'features/hooks'
import { bootstrapSelect } from 'features/bootstrap';
import { languageSelect } from 'features/language';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import Offer from '../Offer/index';
import {
  Title,
  Description,
  AllOffers,
  AllOffersText,
  AllOffersCounter,
  AllOffersArrow

} from './styles'

export const JobOffers = (props) => {
  const { lang } = useSelector(languageSelect);
  const { t } = useTranslation("home");
  const getMultilingualItem = (key) => t(key);

  const { isSmall } = useBreakpoints();
  const bootstrap = useSelector(bootstrapSelect);
  const [data, setData] = React.useState<any>([]);

  const offersToShow = () => {
    let dataToSave = bootstrap.data.teams.filter(value => value.offersNumber).slice(0, 7);
    if (dataToSave.length === 2 || dataToSave.length === 4 || dataToSave.length === 6) dataToSave = dataToSave.slice(0, dataToSave.length - 1)
    setData(dataToSave)
  }

  React.useEffect(() => {
    if (bootstrap.data.teams) {
      offersToShow();
    }
  }, [bootstrap.data]);

  return <React.Fragment>
    <Col md={12} xs={12} lg={12}>
      <Title>
        <MultilingualText tKey="offersHeader" area="home" />
      </Title>
      <Description>
        <MultilingualText tKey="offersDescription" area="home" />
      </Description>
    </Col>
    {data.map((value, index) => {
      return <Offer key={index} id={value.id} name={value.name} location={value.locations} counter={value.offersNumber} last={data.length - 1 === index} />
    })}
    <Col md={6} lg={6} sm={12}>
      <AllOffers to={`${lang === 'en' ? '/en' : ''}/job-offers`} onMouseUp={() => GTM('homeOffersBlock', `${getMultilingualItem(`${isSmall ? 'allOffersButtonShort' : 'allOffersButton'}`)} (${bootstrap.data.totalJobOffers})`, `${lang === 'en' ? '/en' : ''}/job-offers`)}>
        <AllOffersText ><MultilingualText tKey={`${isSmall ? 'allOffersButtonShort' : 'allOffersButton'}`} area="home" /></AllOffersText>
        <AllOffersCounter>{bootstrap.data.totalJobOffers}</AllOffersCounter>
        <AllOffersArrow />
      </AllOffers>
    </Col>
  </React.Fragment>
};
