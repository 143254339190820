import React, { FunctionComponent, useState, useRef, MutableRefObject, useEffect } from "react";
import Slider from "react-slick";

import GTM from 'features/services';
import { useBreakpoints } from 'features/hooks';
import SlideComponent from './SlideComponent';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';

import { IRecruitersSliderComponentProps } from 'view/pages/recruitment/interfaces';
import { Main, NextArrow, PrevArrow } from './styles';

const SlickButtonFix: FunctionComponent<{ currentSlide?: number, slideCount?: number, children?: any }> = ({ currentSlide, slideCount, children, ...props }) => (
  <div style={{ height: '100%', position: 'absolute', top: '50%', width: '100vw' }} {...props}>{children}</div>
);

const usePrevious = (value: number): number => {
  const ref = useRef<number | undefined>();
  useEffect(() => {
    ref.current = value ? value : 0;
  });
  return ref.current as number;
}

export const SliderComponent: FunctionComponent<IRecruitersSliderComponentProps> = ({ recruiters, title }) => {
  const slider: MutableRefObject<any> = useRef<Slider>(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [sliderMoved, setSliderMoved] = useState<boolean>(false);
  const { width } = useBreakpoints();
  const slidesToShow = width > 1835 ? 3 : width > 1239 ? 2 : width > 1135 ? 4 : width > 880 ? 3 : width > 621 ? 2 : width > 365 ? 1 : 0.5;
  const prevActiveSlide = usePrevious(activeSlide);

  useEffect(() => {
    if (!sliderMoved) {
      setSliderMoved(true);
      return;
    }
    const isLeft = prevActiveSlide > activeSlide;
    GTM('recruitmentSliderNav', `${isLeft ? 'Left' : 'Right'} on ${title}`, `slider position: ${Math.ceil(activeSlide + 1)}`);
  }, [activeSlide])

  const settings = {
    arrows: true,
    dots: false,
    activeSlide: activeSlide,
    infinite: false,
    focusOnSelect: true,
    speed: 800,
    swipe: width < 1000,
    slidesToShow: slidesToShow + 0.3,
    slidesToScroll: 1,
    afterChange: x => setActiveSlide(x),
    nextArrow: width < 800 ? undefined : <SlickButtonFix ><NextArrow onMouseUp={() => { }} /></SlickButtonFix>,
    prevArrow: width < 800 ? undefined : <SlickButtonFix ><PrevArrow onMouseUp={() => { }} /></SlickButtonFix>,
    className: 'center',
    customPaging: () => <span />,
  };

  return (
    <Main>
      <LazyloadBackgroundImage image={recruiters[0].image}>
        <Slider {...settings} ref={slider}
        >
          {recruiters.map((x, idx) =>
            <SlideComponent key={x.key} slide={x} index={idx} slidesToShow={slidesToShow} activeSlide={activeSlide} />
          )}
        </Slider>
      </LazyloadBackgroundImage>
    </Main >
  );
};