import MainBannerWarszawa from 'assets/images/locations/BannerWarszawa.jpg';
import MainBannerWarszawaPhone from 'assets/images/locations/banner-phone-officeWarszawa.jpg';
import placeholderWarszawa from 'assets/images/locations/placeholderWarszawa.jpg';
import img1 from 'assets/images/locations/gallery/office-warszawa/img1.jpg';
import img2 from 'assets/images/locations/gallery/office-warszawa/img2.jpg';
import img3 from 'assets/images/locations/gallery/office-warszawa/img3.jpg';
import img4 from 'assets/images/locations/gallery/office-warszawa/img4.jpg';
import img5 from 'assets/images/locations/gallery/office-warszawa/img5.jpg';
import img6 from 'assets/images/locations/gallery/office-warszawa/img6.jpg';
import img7 from 'assets/images/locations/gallery/office-warszawa/img7.jpg';
import img8 from 'assets/images/locations/gallery/office-warszawa/img8.jpg';
import img9 from 'assets/images/locations/gallery/office-warszawa/img9.jpg';
import img10 from 'assets/images/locations/gallery/office-warszawa/img10.jpg';
import { ILocation } from './interface';
import { Theme } from 'core/theme';

export const officeWarszawa: ILocation = {
  key: 'warsaw',
  bannerImagePhone: MainBannerWarszawaPhone,
  name: 'warsaw-office',
  slug: 'warsaw',
  bannerImageDesktop: MainBannerWarszawa,
  addressLocation: {
    color: `${Theme.colors.location.turquoise} `,
    iframe:
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9769.20187031592!2d20.9947984!3d52.2560886!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1d7d29c6c026c0b8!2sPhilip%20Morris%20Polska%20Distribution%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1601235827977!5m2!1spl!2spl',
    phoneNumber: '123 456 789',
  },
  teamsWorking: ['procurement', 'it', 'finances','people-culture', 'commercial-strategy-planning', 'legal-ethics-compliance','administration','marketing-digital','consumer-experience-customer-care'],
  gallery: {
    firstImg: img5,
    secondImg: img10,
    allImgGallery: [img5, img10, img3, img4, img2, img6, img7, img8, img9, img10, img1],
  },
  workLooks: {
    image: placeholderWarszawa,
  },
};
