import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { SmartWorkStage } from './SmartWorkStage';

import { Header } from './styles';
import {SmartWorkStageComponent} from "./SmartWorkStage/SmartWorkStageComponent";
import styled from "styled-components";

export const SubtitleSmartWorkProcces = styled.h3 `
  white-space: pre-wrap;

`

export const SmartWorkProcess: FunctionComponent = () => {
  return <Container>
    <Col col={12}>
      <Row>
        <Header><MultilingualText tKey="smart-work-process-header" area="smartWork" /></Header>
        <SubtitleSmartWorkProcces><MultilingualText tKey="smart-work-process-desc" area="smartWork" /></SubtitleSmartWorkProcces>

      </Row>
      {SmartWorkStage.map((x, idx) =>
        <SmartWorkStageComponent key={x.key} id={x.key} stage={x} isLast={SmartWorkStage.length - 1 === idx} infoModal={x.infoModal}/>
      )}
    </Col>
  </Container>;

};
