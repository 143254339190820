import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'styled-bootstrap-grid';
import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { useMultilingualText } from 'features/hooks';
import Container from 'view/components/content/container';
import { DivisionName, HeaderSeeMore } from 'view/pages/teams/components/MoreDivisions/styles';
import { useLocationData } from 'features/hooks/useLocationData';
import { LocationList } from '../../location';
import { LocationIcon, MoreLocationsRow, Location } from './styles';

export const MoreLocation: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const { key } = useLocationData();
  const seeMoreHeader = useMultilingualText(`seeMoreHeader`, `whereWork`);
  const redirectText = useMultilingualText('redirect', 'common');

  const filterLocation = () => LocationList.filter(x => x.tKey !== key).slice(0, 4);

  const onAnotherLocationClick = (location) => {
    GTM(`${key}LinkToAnother`, `${redirectText} ${lang === 'en' ? location.nameEng : location.name}`, `${window.location.host}${lang === 'en' ? '/en' : ''}/working-places/${location.id}`);
    window.location.href = `${lang === 'en' ? '/en' : ''}/working-places/${location.id}`;
  }

  return <Container>
    <MoreLocationsRow>
      <Col lg={6} md={12} sm={12}>
        <HeaderSeeMore>{seeMoreHeader}</HeaderSeeMore>
      </Col>
      <Col lg={6} md={12} sm={12}>
        {filterLocation().map((location) =>
          <Location color={location.color} key={location.id}
            onMouseUp={() => onAnotherLocationClick(location)}>
            <DivisionName><MultilingualText tKey="title" area={`locations_${location.tKey}`} /></DivisionName>
            <LocationIcon />
          </Location>,
        )}
      </Col>
    </MoreLocationsRow>
  </Container>;
};
