import { homeData } from './home';
import { aboutsUsData } from './about-us';
import { whereWorking } from './where-working-main';
import { acrossCountry } from './locations/across-country';
import { krakowFactory } from './locations/factory-krakow';
import { krakowCUW } from './locations/community-service-krakow';
import { officeWarszawa } from './locations/office-warszawa';
import { krakowIt } from './locations/it-centre-krakow';
import { teamData } from './team';
import { recruitmentData } from './recruitment';
import { jobOffersData } from './job-offers-page';
import { jobOffer } from './job-offers';

export const data = {
  ...homeData,
  ...aboutsUsData,
  ...teamData,
  ...recruitmentData,
  ...whereWorking,
  ...acrossCountry,
  ...krakowFactory,
  ...krakowCUW,
  ...officeWarszawa,
  ...krakowIt,
  ...jobOffer,
  ...jobOffersData,
};
