import { media } from 'core/theme';
import * as React from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { PopUp } from './pop-up';
import { BannerWrapperDepartment, InfoAdressDepartment } from './styles';
import { ButtonCareerLocationComponent } from '../ButtonLocation/button-location-career';

const ColorLine = styled.hr`
  border: none;
  background-color: ${props => props.color};
  width: 0.313rem;
  height: 5.625rem;
  margin: 1.688rem 0 0.5rem;

  ${media.down('md')} {
    height: 3.75rem;
    margin-top: 0.5rem;

  }

  ${media.down('sm')} {
    margin: 0;
  }
`;
export const BannerLocation = (props) => {
  return <>
    <Row>
      <Col>
        <BannerWrapperDepartment>
          <Row>

            <Col lg={0.3} md={0.5} xs={1}>
              <ColorLine color={props.color} />
            </Col>
            <Col lg={1} xs={2} md={1.5} xsOrder={2}>
              <PopUp {...props} ></PopUp>
            </Col>
            <Col lg={3.5} xs={7} md={6} xsOrder={1}>
              <InfoAdressDepartment>
                <li>{props.businessName}</li>
                <li>{props.address}</li>
                <li>{props.postcode}</li>
              </InfoAdressDepartment>
            </Col>

            <ButtonCareerLocationComponent locationFilter={props.locationFilter} />
          </Row>

        </BannerWrapperDepartment>

      </Col>
    </Row>
  </>
    ;
};
