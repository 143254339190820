import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import { Col, Row } from 'styled-bootstrap-grid';
import LazyImage from 'view/components/content/LazyImage';
import HappyParentBannerSecond from 'assets/images/employee-programs/happy-parent-baner-second.png';
import { DescriptionImage } from 'view/pages/about-us/components/AboutUsTextSection/styles';
import { WrapperDescriptionHappy, ImageBox } from './styles';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';


export const MainTextHappyParets: FunctionComponent = () => {
  return <>
    <Container>
      <Row><Col sm={ 12 } md={ 12 } lg={ 6 }>
        <WrapperDescriptionHappy>
          <h2><MultilingualText tKey="how-works" area="happy_parents" /></h2>
          <h3><MultilingualText tKey="future-parent" area="happy_parents" /></h3>

          <p><MultilingualText tKey="parents-program" area="happy_parents" /></p>
          <p>
            <MultilingualText tKey="does-the-happy" area="happy_parents" />
          </p>
          <ul>
            <li><MultilingualText tKey="list-item-first" area="happy_parents" /></li>
            <li>
              <MultilingualText tKey="list-item-second" area="happy_parents" />
            </li>
            <li>
              <MultilingualText tKey="list-item-third" area="happy_parents" />

            </li>
          </ul>
          <p><MultilingualText tKey="that-not-all" area="happy_parents" /></p>
        </WrapperDescriptionHappy>
      </Col>

      </Row>
      <Row>
        <Col sm={ 12 } md={ 12 } lg={ 6 }>
          <ImageBox>
            <LazyImage src={ HappyParentBannerSecond } alt="" sccss={ DescriptionImage } width="61rem" />
          </ImageBox>
        </Col>

      </Row>
    </Container>
  </>;
};
