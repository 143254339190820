import styled from 'styled-components';
import { media } from 'core/theme';

export const Breadcrumbs = styled.div`
 padding: 1.438rem 0;
  border-top: 0.063rem solid ${ props => props.theme.colors.lines };
  border-bottom: 0.063rem solid ${ props => props.theme.colors.lines };

  ${ media.down('md') } {
   padding: 1.25rem 0;

  }

  ul {
   margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    letter-spacing: 0.3px;
    color: ${ props => props.theme.colors.lightBlue};

    li {
      padding-right: 0.5rem;
    }

    a {
      color: ${ props => props.theme.colors.lightBlue};
    }
  }
`;