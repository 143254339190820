import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Row } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';

import { languageSelect } from 'features/language/';
import GTM from 'features/services';
import { useBreakpoints } from 'features/hooks/useBreakpoints';
import Container from 'view/components/content/container';
import { RecruitmentProcess } from 'view/pages/recruitment/components/RecruitmentProcess/recruitmentProcess';
import { Banner } from '../Banner/Banner';
import { Filters } from '../Filters/Filters';
import { JobOfferList } from '../JobOfferList/JobOfferList';
import { Paging } from '../Paging/Paging';
import { SearchBox } from '../SearchBox/SearchBox';
import { BannerBox, ContentRow, Main, PagingRow, ProcessBox, FiltersCol } from './styles';
import { useOffers } from './useOffers';

const transformFiltersToQuery = (filters) => {
    return Object.keys(filters)
        .filter(filter => filters[filter].length > 0)
        .map(filter => `${filter}=${filters[filter].join(',')}`)
        .join('&');
}

const transformSearchParams = (params) => {

    const filterTypes = [
        'cities',
        'teams',
        'contractTypes'
    ]

    return Object.fromEntries(Object.keys(params)
        .map(param => [param, params[param]])
        .filter(([, value]) => value)
        .map(([key, value]) => [key, value.split(',').map(string => isNaN(string) ? string : Number(string))])
        .map(([key, value]) => [key, value.length === 1 && !filterTypes.some(value => value === key) ? value[0] : value]));
};

export const limitPageNumbers = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' }
];

export const JobOffersListPage = () => {
    const { lang } = useSelector(languageSelect);
    const history = useHistory();
    const { isLarge } = useBreakpoints();
    const [currentFilters, setFilters] = useState<any>({});
    const [triggerSearchGTMEvent, setTriggerSearchGTMEvent] = useState<boolean>(false);
    const [triggerClearGTMEvent, setTriggerClearGTMEvent] = useState<boolean>(false);
    const [currentLimit, setCurrentLimit] = useState<any>({
        value: 10,
        label: 10
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPhrase, setCurrentPhrase] = useState<any>();
    const [utmSource, setUtmSource] = useState<any>('');
    const [utmMedium, setUtmMedium] = useState<any>('');
    const [utmCampaign, setUtmCampaign] = useState<any>('');
    const [utmTerm, setUtmTerm] = useState<any>('');
    const [utmContent, setUtmContent] = useState<any>('');

    const prepareQueryParams = () => [
        ['limit=', currentLimit.value],
        ['search=', currentPhrase],
        ['page=', currentPage],
        ['utm_source=', utmSource],
        ['utm_medium=', utmMedium],
        ['utm_campaign=', utmCampaign],
        ['utm_term=', utmTerm],
        ['utm_content=', utmContent],
        ['', transformFiltersToQuery(currentFilters)]]
        .filter(([, value]) => value)
        .map(([phrase, value]) => `${phrase}${value}`)
        .join('&');

    let { offers, pageStats } = useOffers(prepareQueryParams());

    useEffect(() => {
        if (triggerSearchGTMEvent) {
            GTM('jobOffersSearchbox', currentPhrase, pageStats.total ? pageStats.total : 0);
            setTriggerSearchGTMEvent(false);
        }
        if (triggerClearGTMEvent) {
            GTM('jobOffersFilters', 'Clear', `number of offers: ${pageStats.total ? pageStats.total : 0}`);
            setTriggerClearGTMEvent(false);
        }

    }, [pageStats])

    useEffect(() => {
        GTM(lang === 'pl' ? 'jobOffersPageLoaded' : 'jobOffersPageLoadedEN')
    }, [lang]);

    useEffect(() => {
        history.location.state = undefined;
    }, [history.location.pathname])

    useEffect(() => {
        const searchParams = transformSearchParams(qs.parse(history.location.search, {
            ignoreQueryPrefix: true
        }));

        const {
            cities = [],
            teams = [],
            contractTypes = [],
            page = 1,
            search = '',
        } = searchParams;

        const limit = !limitPageNumbers.some(element => element.value === Number(searchParams.limit)) ? 10 : searchParams.limit;

        if (!history.location.state) {
            setFilters({
                'cities': [...cities],
                'teams': [...teams],
                'contractTypes': [...contractTypes],
            });
            setCurrentLimit({ value: limit, label: limit });
            setCurrentPage(page);
            setCurrentPhrase(search);

            setUtmSource(searchParams.utm_source);
            setUtmMedium(searchParams.utm_medium);
            setUtmCampaign(searchParams.utm_campaign);
            setUtmTerm(searchParams.utm_term);
            setUtmContent(searchParams.utm_content);
        }

    }, [history.location.search]);

    useEffect(() => {
        history.replace({
            ...history.location,
            search: `?${prepareQueryParams()}`,
            state: {
                reload: false,
            },
        })
    }, [currentFilters, currentPage, currentLimit, currentPhrase]);

    const onLimitChange = (limit) => {
        if (currentLimit !== limit) {
            setCurrentLimit(limit);
            setCurrentPage(1);
            window.scrollTo(0, 0);
        }
        GTM('jobOffersNavigation', `Show on page`, limit.label.toString())
    };

    const onPageChange = (page) => {
        const pageDirection = (currentPage < page) ? 'next' : 'previous';
        if (currentPage !== page) {
            GTM('jobOffersNavigation', pageDirection, `${currentPage} to ${page.toString()}`);
            setCurrentPage(page);
            window.scrollTo(0, 0);
        }
    };
    const onPageNumberChange = (page) => {
        if (currentPage !== page) {
            GTM('jobOffersNavigation', page.toString(), `${currentPage} to ${page.toString()}`);
            setCurrentPage(page);
            window.scrollTo(0, 0);
        }
    };

    const onFilterChange = (filters) => {
        setFilters(filters);
    };

    const onSearchChange = (value) => {
        setCurrentPhrase(value);
        setCurrentPage(1);
        setTriggerSearchGTMEvent(true);
    };


    return <Main>
        <SearchBox value={currentPhrase} onSearch={onSearchChange} />
        <Container>
            <Row>
                {!isLarge && <Col lg={4} md={12} sm={12}>
                    <Filters defaultOptions={currentFilters} onChange={onFilterChange} pageStats={pageStats} onClear={() => setTriggerClearGTMEvent(true)} />
                </Col>}
                <PagingRow alignItems="center">
                    <Col col={12}>
                        <Paging currentPage={currentPage}
                            offersPerPage={currentLimit}
                            onPageChange={onPageChange}
                            onPageNumberChange={onPageNumberChange}
                            onLimitChange={onLimitChange}
                            limitPageNumbers={limitPageNumbers}
                            pageStats={pageStats} />
                    </Col>
                </PagingRow>
                <ContentRow>
                    {isLarge && <FiltersCol lg={4} md={12} sm={12}>
                        <Filters defaultOptions={currentFilters} onChange={onFilterChange} pageStats={pageStats} onClear={() => setTriggerClearGTMEvent(true)} />
                    </FiltersCol>}
                    <Col lg={8} md={12} sm={12} style={{
                        minHeight: '13rem',
                        overflow: 'hidden',
                        padding: 0,
                    }}>
                        <JobOfferList data={offers} />
                    </Col>
                </ContentRow>
                {pageStats.total > 0 &&
                    <PagingRow alignItems="center">
                        <Col col={12}>
                            <Paging currentPage={currentPage}
                                offersPerPage={currentLimit}
                                onPageChange={onPageChange}
                                onPageNumberChange={onPageNumberChange}
                                onLimitChange={onLimitChange}
                                limitPageNumbers={limitPageNumbers}
                                pageStats={pageStats}
                                down />
                        </Col>
                    </PagingRow>}
            </Row>
        </Container>
        <BannerBox>
            <Banner tKey="sendResume" area="jobOffers" />
        </BannerBox>
        <ProcessBox>
            <RecruitmentProcess />
        </ProcessBox>
    </Main >;
};

