import * as React from 'react';
import { useBreakpoints } from 'features/hooks';
import { CultureContainer, Description, Plus, Title } from './styles';
import { FlipSlide } from '../FlipSlide/flipSlide';
import GTM from 'features/services';

export const CulturePhoto = (props) => {
  const { isSmall } = useBreakpoints();
  return <>    { isSmall ?

    <CultureContainer image={props.image} imageMobile={props.imageMobile} isRight={props.isRight}
      onClick={() => { GTM('homeCardModal', `${props.title}`, `${props.section}`) }}>
      <Plus image={props.image} imageMobile={props.imageMobile} />
      <Title image={props.image} imageMobile={props.imageMobile}>{props.title}</Title>
      <Description image={props.image}>{props.description}</Description>
    </CultureContainer> :

    <FlipSlide {...props} />}
  </>;
};
