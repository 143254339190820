import styled, { css } from 'styled-components';
import { media } from 'core/theme/media';

export const TextBox = styled.div`
    padding: 7rem 0;

    ${media.down('md')} {
      padding: 3.75rem 0;
    }
  `,
  LeftHeader = styled.h3`
    font-weight: normal;
    width: 28.125rem;
    ${media.down('md')} {
      line-height: normal;
      width: 91%;
    }
    ${media.down('sm')} {
      width: 19.688rem;
    }
  `,
  Header = styled.h2`
    ${media.down('md')} {
      margin-top: 1.875rem !important;
      margin-bottom: 1.25rem !important;
    }
  `,
  Paragraph = styled.p`
    width: 31.875rem;
    margin: 0;
    ${media.down('md')} {
      width: 100%;
    }
  `,
  SmallHeader = styled.h3`
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
    font-weight: normal;

    ${media.down('md')} {
      margin-top: 1.25rem !important;
      margin-bottom: 1.25rem !important;
    }
  `,
  DescriptionImage = css`
    width: 100%;
    height: 100%;
  `,
  ImageBox = styled.div`
    margin-left: -32rem;
    margin-top: -8.4rem;
    height: auto;
    width: 61rem;

    ${media.down('md')} {
      margin-top: 2.5rem;
      margin-left: 0;
    }
    ${media.down('sm')} {
      margin-left: -15px;
      width: 100vw;
    }
  `,
  Explanation = styled.div`
    padding-top: 1.25rem;
  `;
