import React,{FunctionComponent} from 'react';
import { Main } from '../about-us/styles';
import TopBannerHappyParent from './TopBannerHappyParent';
import { MainTextHappyParets } from './MainTextHappyParets/MainTextHappyParets';
import SumItUpFromHappy from './SumItUpFromHappy';
import { WhyImplementHappy } from './WhyImplementHappy/WhyImplementHappy';
import { VideoHappyParents } from './VideoHappyParents/VideoHappyParents';
import { ReviewsWorkers } from './ReviewsWorkers/ReviewsWorkers';
import { InclusionHappyParents } from './InclusionHappyParents/InclusionHappyParents';
import BannersHappyParentBottom from './BannersHappyParentBottom';

export const HappyParentPage : FunctionComponent= () => {
  return <Main>

    <TopBannerHappyParent />
    <MainTextHappyParets />
    <SumItUpFromHappy />
    <WhyImplementHappy />
    <VideoHappyParents />
    <ReviewsWorkers />
    <InclusionHappyParents />
    <BannersHappyParentBottom />
  </Main>
}
