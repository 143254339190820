import React from 'react';
import { useTranslation } from 'react-i18next';

import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import GTM from 'features/services';
import Briefcase from 'assets/images/job-offers/briefcase.svg';
import Pin from 'assets/images/teams/position-pin.svg';
import { Button, Category, CategoryIcon, Header, Icon, NewOfferRibbon, Offer, Segment, StyledLink } from './styles';
enum Badges {
    NEW = 'new',
}

export const OfferElement = ({ details }) => {
    const { t } = useTranslation("jobOffers");
    const getMultilingualItem = (key) => t(key);

    return (
        <Offer>
            {details.badge === Badges.NEW && <NewOfferRibbon><MultilingualText tKey="newOffer" area="jobOffers" /></NewOfferRibbon>}
            <Segment>
                <CategoryIcon src={Briefcase} />
                <div>
                    <Category><MultilingualText tKey="hiringDepartment" area="jobOffers" /></Category>
                    <Header>{details.team}</Header>
                </div>
            </Segment>
            <Segment>
                <CategoryIcon src={Pin} />
                <div>
                    <Category><MultilingualText tKey="placeOfWork" area="jobOffers" /></Category>
                    <Header>{details.city}</Header>
                </div>
            </Segment>
            <StyledLink onMouseUp={() => { GTM(`jobOffertClickApply`, `${getMultilingualItem('applyBy')} [${details.title} | ${details.team} | ${details.city}]`, `${details.applyLink}`) }} href={details.applyLink}>
                <Button>
                    <MultilingualText tKey="applyBy" area="jobOffers" />
                    <Icon />
                </Button>
            </StyledLink>
        </Offer>);
};
