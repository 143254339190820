import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid'

import FAQComponent from './FAQComponent';
import { FAQList } from './FAQList';

import { Header } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

export const FAQ: FunctionComponent = () => {

  return <Container>
    <Row alignItems="center">
      <Col>
        <Header><MultilingualText tKey="faq.header" area="recruitment" /></Header>
      </Col>
    </Row>
    <Row><Col>
      {FAQList.map((x) => <FAQComponent key={x} id={x} />)}
    </Col></Row>
  </Container>;

};