import styled from 'styled-components';
import { media } from 'core/theme/media';
import { Link } from 'react-router-dom';
import moreAboutUsIcon from 'assets/images/home/frame-c-arrow-right-us.svg';
import moreAboutUsIcon2 from 'assets/images/home/frame-c-arrow-right-us2.svg';

export const Main = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: pre-wrap;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 1000;
    background: white;
  `,
  Logo = styled.img`
    width: 7.563rem;
    height: 5.625rem;
    margin-bottom: 6.25rem;
    margin-top: 9.375rem;
    ${media.down('md')} {
      width: 4.563rem;
      height: 3.438rem;
      margin-top: 1.875rem;
      margin-bottom: 1.875rem;
    }
  `,
  Title = styled.h1`
    margin-bottom: 1.25rem;
    margin-block-start: 0;
    font-weight: 300;
    letter-spacing: normal;
    ${media.down('md')} {
      margin-bottom: 0.625rem;
      font-size: 1.875rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.75px;
    }
  `,
  Description = styled.div`
    width: 28.75rem;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: -0.3px;
    ${media.down('md')} {
      width: 15.938rem;
      font-size: 1.188rem;
      line-height: -0.24px;
    }
  `,
  Button = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    color: ${(props) => props.theme.colors.lightText};
    width: 18.438rem;
    height: 3.75rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    padding: 0.875rem 1.563rem 0.813rem 1.875rem;
    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
    ${media.down('md')} {
      margin-top: 1.875rem;
      width: 14.5rem;
      height: 3.125rem;
      font-size: 1.188rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.24px;
      padding: 0.75rem 0.938rem 0.688rem 1.375rem;
    }
  `,
  Icon = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(${moreAboutUsIcon});
    background-size: 1.25rem 1.25rem;
    ${Button}:hover & {
      background-image: url(${moreAboutUsIcon2});
    }
  `,
  Image = styled.img`
    width: 50vw;
    position: absolute;
    top: 0;
    left: 0;
  `,
  ImageMobile = styled.img`
    ${media.up('md')} {
      display: none;
    }
    margin-top: 1.3rem;
    width: 100vw;
  `;
