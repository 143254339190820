import styled from 'styled-components';
import { media } from 'core/theme/media';

export const TopBanner = styled.div<{ backgroundImage: string }>`
  height: 12.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.darkText};
  display: flex;
  align-items: center;

  ${media.down('sm')} {
    align-items: flex-end;
    min-height: 10.8rem;
    height: auto;
    padding-bottom: 2.375rem;
  }
`,
  Team = styled.p`
    color: ${(props) => props.theme.colors.lightText};
    margin: 0;
    padding-bottom: 1.25rem;
    ${media.down('sm')} {
      color: ${(props) => props.theme.colors.offers.division};
      padding-bottom: 0.625rem;
    }
  `,
  PageTitle = styled.h2`
    color: ${(props) => props.theme.colors.lightText};
    margin: 0;
    font-weight: normal;
    line-height: normal;
    letter-spacing: -0.46px;
    ${media.down('sm')} {
      font-weight: 300;
    }
  `;
