export const jobOffer = {
  jobOffertLoaded: {
    event: 'virtualPageView',
    virtualPageView: '/',
  },
  jobOffertLoadedEN: {
    event: 'virtualPageView',
    virtualPageView: '/en/',
  },
  jobOffertClickApply: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
};
