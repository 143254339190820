import styled, { css } from 'styled-components';

import { IStyledMenuItemProps } from './interfaces';
import { StyledPopup } from 'view/components/content/dropdown/styles';
import { media } from 'core/theme';

import DropdownIcon from 'assets/images/header/dropdown-icon.svg';
import DropdownIconMobile from 'assets/images/header/dropdown-icon-mobile.svg';

const menuItemActiveCss = css`
  & > a,
  & > span,
  & > div > span {
    color: ${(props) => props.theme.colors.blueButtonBackground};
    position: relative;
    ${media.up('sm')} {
      &:after {
        position: absolute;
        bottom: 0;
        left: 1.5945rem;
        right: 1.5945rem;
        height: 0.375rem;
        background: ${(props) => props.theme.colors.blueButtonBackground};
        content: '';
        display: block;
        z-index: 2;
      }
    }
  }
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 3.125rem 0 3.125rem;

    ${media.up('sm')} {
      padding: 0;
      width: auto;
      flex-direction: row;
      height: 100%;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }
  `,
  MenuWrapper = styled.div`
    ${media.up('sm')} {
      margin: 0 -1.5945rem;
    }
  `,
  MenuItem = styled.div<IStyledMenuItemProps>`
    text-transform: uppercase;

    font-weight: 500;
    color: ${(props) => props.theme.colors.darkText};
    box-sizing: border-box;
    font-size: 1.125rem;
    flex: 1 100%;
    border-bottom: 1px solid #c9d2e3;
    &:last-child {
      border-bottom: 0;
    }

    ${media.up('sm')} {
      height: 100%;
      font-size: 1rem;
      flex: 0 auto;
      border-bottom: 0;
    }

    & > a,
    & > span,
    & > div > span {
      @media (min-width: 900px) and (max-width: 1550px) {
        font-size: 0.775rem;
        padding: 0 0 0 1.25rem !important;
      }
      color: inherit;
      text-decoration: none;
      height: 100%;
      display: block;
      box-sizing: border-box;
      cursor: pointer;
      padding: 1.438rem 0 1.375rem 0;

      ${media.up('sm')} {
        padding: 0 1.5945rem;
        line-height: 5rem;
      }
    }

    &:hover {
      ${menuItemActiveCss}
    }
    ${(props) => props.active && menuItemActiveCss}
  `,
  MenuDivider = styled.hr`
    border: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.lines};
    margin: 1rem 0;
  `,
  StyledDropdownIcon = styled.span`
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    background: url('${DropdownIconMobile}') no-repeat center center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    ${media.up('sm')} {
      background-image: url('${DropdownIcon}');
      position: relative;
    }
  `,
  SubMenuItem = styled(MenuItem)`
    ${media.down('sm')} {
      position: relative;
    }

    & > div {
      height: 100%;
    }

    ${StyledDropdownIcon} {
      vertical-align: middle;
      margin-left: 0.438rem;
      transition: transform 0.3s;
    }

    .open ${StyledDropdownIcon} {
      transform: rotate(180deg);
    }

    ${MenuItem} {
      text-transform: none;
      margin-bottom: 0.625rem;
      border-bottom: 0;

      & > a,
      & > span,
      & > div > span {
        padding: 0;
        line-height: 1.75;
        border-bottom: 0;
        &:after {
          display: none;
        }
        font-size: 0.875rem;
        ${media.up('sm')} {
          font-size: inherit;
        }
      }

      &:hover {
        & > a,
        & > span,
        & > div > span {
          border-bottom: 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${StyledPopup} {
      margin-left: -0.9055rem;
      ${media.down('sm')} {
        position: relative;
        box-shadow: none;
        padding: 0;
        margin: 0 0 2.5rem 0;
        ${MenuDivider} {
          display: none;
        }
      }
    }
  `;
