import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid';

import GTM from 'features/services';
import TextComponent from 'view/components/content/TextComponent/TextComponent';
import { languageSelect } from 'features/language/'
import { useBreakpoints } from 'features/hooks/useBreakpoints';
import { ITopBannerComponent } from '../../interface'

import {
  TopBanner,
  PageTitle,
  Button,
  Text,
  OffersNumber,
  ButtonText,
  ArrowIcon
} from './styles';

export const TopBannerComponent: FunctionComponent<ITopBannerComponent> = ({ teamsFilter, numberOfOffers, title, bannerImage }) => {
  const history = useHistory();
  const { isLarge } = useBreakpoints();
  const { lang } = useSelector(languageSelect);
  const topBannerContainerSize = isLarge ? 'large' : 'default'
  const resolveColCustomStyle = () => !isLarge ? { paddingRight: 0 } : { padding: 0 };

  const calcOfferWord = (number: number) => (number === 1 ? ' oferta' : number % 10 > 1 && number % 10 < 5 ? 'oferty' : 'ofert');

  const onOffersClick = () => {
    const link = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10&page=1${teamsFilter}`;
    const offersText = lang === "en" ? `job\noffers` : `${calcOfferWord(+numberOfOffers)}\npracy`;
    history.push(link);
    GTM('teamButtonClick', `${offersText} - banner`, link)
  };

  return <TopBanner backgroundImage={bannerImage}>
    <Container type={topBannerContainerSize} right={true}>
      <Row alignItems="end">
        <Col sm={12} lg={5} md={12} style={resolveColCustomStyle()}>
          <PageTitle><TextComponent>{title}</TextComponent></PageTitle>
        </Col>
        <Col sm={12} lg={7} md={12} style={resolveColCustomStyle()}>
          <Button onMouseUp={onOffersClick}>
            <Text>
              <OffersNumber>{numberOfOffers}</OffersNumber>
              <ButtonText>
                {lang === "en" ? `job\noffers` : `${calcOfferWord(+numberOfOffers)}\npracy`}
              </ButtonText>
            </Text>
            <ArrowIcon />
          </Button>
        </Col>
      </Row>
    </Container>
  </TopBanner>;

};
