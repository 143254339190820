import { useBreakpoints } from 'features/hooks/useBreakpoints';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/';
import Container from 'view/components/content/container';
import { bootstrapSelect } from 'features/bootstrap';
import filterIcon from 'assets/images/icons/filter-tool.svg';
import { FilterSection } from './FilterSection/FilterSection';
import {
  FilterButton,
  FiltersHeaderBox,
  FiltersHeader,
  Action,
  Header,
  HiddenScrollbar,
  Icon,
  Main,
  ModalComp,
  SaveButton,
} from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

const defaultFilters = {
  cities: [],
  teams: [],
  contractTypes: [],
};

export const Filters = ({ defaultOptions = defaultFilters, onChange, pageStats, onClear }) => {
  const { lang } = useSelector(languageSelect);
  const history = useHistory();
  const bootstrap = useSelector(bootstrapSelect);
  const { isLarge } = useBreakpoints();
  const { total } = pageStats;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState(defaultFilters);
  const [numberOfOffers, setNumberOfOffers] = useState(total);
  const { teams = [], cities = [], contractTypes = [] } = bootstrap.data;
  const defaultFilter = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10`;

  const { t } = useTranslation('jobOffers');
  const getMultilingualItem = (key) => t(key);
  const team = getMultilingualItem('team');
  const locations = getMultilingualItem('locations');
  const position = getMultilingualItem('position');

  const onCheck = (id: number, type: string, event, name: string, category: string) => {
    const isElementSelected = event.target.checked;

    let newState = {
      ...selected,
    };

    if (isElementSelected) {
      newState[type] = [...selected[type], id];
    } else {
      newState[type] = selected[type].filter((item) => item !== id);
    }

    newState.teams = newState.teams.sort(function (a, b) {
      return a - b;
    });

    setSelected(newState);
    GTM('jobOffersFilters', `${category ? category : 'mobile filters'} - ${name}`, isElementSelected ? 'checked' : 'unchecked');
    return newState;
  };

  useEffect(() => {
    const options = Object.assign({}, defaultFilters, defaultOptions);
    setSelected(options);
  }, [defaultOptions]);

  useEffect(() => {
    setNumberOfOffers(total);
  }, [total]);

  const clearingFilter = () => {
    history.push(defaultFilter);
    onChange(defaultFilters);
    onClear();
  };

  return (
    <Main>
      {isLarge ? (
        <div>
          <Row>
            <FiltersHeaderBox>
              <FiltersHeader>
                <MultilingualText tKey="filters" area="jobOffers" />
              </FiltersHeader>
              <Action onMouseUp={clearingFilter}>
                <MultilingualText tKey="clearingAction" area="jobOffers" />
              </Action>
            </FiltersHeaderBox>
          </Row>
          <FilterSection
            onClick={(id, type, event, name) => onChange(onCheck(id, type, event, name, locations))}
            type={'cities'}
            defaultSelected={selected.cities}
            options={cities}
            header={<MultilingualText tKey="locations" area="jobOffers" />}
          />
          <FilterSection
            onClick={(id, type, event, name) => onChange(onCheck(id, type, event, name, team))}
            defaultSelected={selected.teams}
            options={teams}
            type={'teams'}
            header={<MultilingualText tKey="team" area="jobOffers" />}
          />
          <FilterSection
            onClick={(id, type, event, name) => onChange(onCheck(id, type, event, name, position))}
            defaultSelected={selected.contractTypes}
            options={contractTypes}
            type={'contractTypes'}
            header={<MultilingualText tKey="position" area="jobOffers" />}
          />
        </div>
      ) : (
        <div>
          <Row>
            <FilterButton onMouseUp={() => setIsModalOpen(true)}>
              <Icon src={filterIcon} />
              <MultilingualText tKey="filter" area="jobOffers" />
            </FilterButton>
          </Row>
          <Row>
            <FiltersHeaderBox>
              <Action
                onClick={() => {
                  history.push(defaultFilter);
                  onChange(defaultFilters);
                }}
              >
                <MultilingualText tKey="clearingActionLong" area="jobOffers" />
              </Action>
            </FiltersHeaderBox>
          </Row>
        </div>
      )}

      {isModalOpen && !isLarge && (
        <ModalComp>
          <Container>
            <HiddenScrollbar />
            <Header>
              <MultilingualText tKey="filter" area="jobOffers" />
            </Header>
            <FilterSection
              onClick={(id, type, event, name) => onCheck(id, type, event, name, locations)}
              type={'cities'}
              defaultSelected={selected.cities}
              options={cities}
              header={<MultilingualText tKey="locations" area="jobOffers" />}
            />
            <FilterSection
              onClick={(id, type, event, name) => onCheck(id, type, event, name, team)}
              defaultSelected={selected.teams}
              options={teams}
              type={'teams'}
              header={<MultilingualText tKey="team" area="jobOffers" />}
            />
            <FilterSection
              onClick={(id, type, event, name) => onCheck(id, type, event, name, position)}
              defaultSelected={selected.contractTypes}
              options={contractTypes}
              type={'contractTypes'}
              header={<MultilingualText tKey="position" area="jobOffers" />}
            />
            <Row>
              <Col md={6} sm={12}>
                <SaveButton
                  onClick={() => {
                    onChange(selected);
                    setIsModalOpen(false);
                  }}
                >
                  <MultilingualText tKey="save" area="jobOffers" />
                </SaveButton>
              </Col>
            </Row>
          </Container>
        </ModalComp>
      )}
    </Main>
  );
};
