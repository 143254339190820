import React, { FunctionComponent } from 'react';
import Container from '../../../components/content/container';
import HappyParentsIcon from '../../../../assets/images/employee-programs/HappyParentsIcon.png';
import { BanerTop, WrapperHeadBannerDesc, IconEmployeeProgramsPage } from './styles';
import { useBreakpoints } from '../../../../features/hooks';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';



export const TopBannerHappyParent: FunctionComponent = () => {
  const { isSmall } = useBreakpoints();
  return <>
    <BanerTop>
      <Container type="large">
        <WrapperHeadBannerDesc>
          <IconEmployeeProgramsPage iconImg={ HappyParentsIcon } />
          <h2>Happy { isSmall && <br />}Parents</h2>
          <p><MultilingualText tKey="sub-title-main-banner" area="happy_parents" /></p>
        </WrapperHeadBannerDesc>
      </Container>
    </BanerTop>
</>;
};
