export const krakowCUW = {
  krakowCUW :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/shared-services-center-krakow',
  },
  krakowCUWClickMap : {
    event: 'backendMicroconversion',
    category: 'Clicks - map',
  },
  krakowCUWClickOffer: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  krakowCUWClickMovieOpen: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  krakowCUWClickMoviePlay: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  krakowCUWClickMovieExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  krakowCUWClickSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  krakowCUWLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
}