import styled from 'styled-components';
import { media } from 'core/theme/media';
import arrow from 'assets/images/home/arrow.svg'
import { Link } from 'react-router-dom';

export const OfficeContainer = styled(Link)<IImage>`
    text-decoration: none;
    display: flex;
    background-image: url("${(props) => props.photo}");
    width: 100%;
    height: 16.875rem;
    margin-bottom: 1.875rem;
    flex-direction: column;
    cursor: pointer;
    background-size: cover;
    ${media.down('sm')} {
      height: 12.875rem;
      background-size: 37rem;
      background-position-x: -13rem;
    }
  `;
export const Gradient = styled.div<IGradient>`
    position: absolute;
    width: 38.75rem;
    height: 16.875rem;
    background-image: ${(props) => props.gradient};
    z-index:1;
    
    ${media.down('sm')} {
      height: 12.875rem;
      width: 100%;
      background-image: ${(props) => props.gradient.replace('38', '58')};
    }
  `;
export const BackgroundHover = styled.div<IHover>`
  position: absolute;
  z-index:2;
  height: 16.875rem;
  width: 29rem;
  transition: background-color 0.3s;
  ${media.up('sm')} {
    ${(props) => props.isShown && `
      background: ${props.rectangle};
    `}
  }
  ${media.down('sm')} {
    display: none;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
      width: 25rem;
    }
`
export const Title = styled.div`
    width: 24.313rem;
    height: 6.5rem;
    font-size: 2.313rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.43px;
    color: ${ props => props.theme.colors.lightText };
    margin-left: 4.375rem;
    margin-top: 2.5rem;
    z-index: 3;
    white-space: pre-wrap;
    ${media.down('sm')} {
      font-size: 1.5rem;
      width: 12rem;
      margin-left: 1.875rem;
      margin-top: 1.25rem;
    }
      @media (min-width: 900px) and (max-width: 1280px) { 
      width: 21rem;
    }
  `;
export const Rectangle = styled.div<IRectangle>`
    position: absolute;
    z-index:3;
    width: 0.625rem;
    height: 16.875rem;
    background-color: ${(props) => props.rectangle};
    ${media.down('sm')} {
      height: 12.875rem;
    }
  
  `;
export const More = styled.div`
    height: 1.438rem;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${ props => props.theme.colors.lightText };
    z-index: 2;
  `;
export const MoreContainer = styled.div`
    display: flex;
    margin-top: 3.375rem;
    margin-left: 4.375rem;
    z-index: 2;
    ${media.down('sm')} {
      margin-left: 1.875rem;
      margin-top: 3rem;
    }
  `;
export const Arrow = styled.div`
    margin-top: 0.33rem;
    margin-left: 0.4rem;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-image: url('${arrow}');
    background-repeat: no-repeat;
  `;