import { useLocation } from 'react-router-dom';
import { ILocation } from 'core/router/location-routes/interface';
import LocationData from 'core/router/location-data';

export const useLocationData = (): ILocation => {
  const location = useLocation();
  const parts = location.pathname.split('/');
  const loc = parts[parts.length - 1];
  let result: ILocation = {} as ILocation;
  result = LocationData.find((x: ILocation) => x.name === loc) || result;
  return result;
};
