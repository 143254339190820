import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import styled from 'styled-components';
import { Col, Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';
import PresentIcon from 'assets/images/employee-programs/icons/present.svg';
import CoinsIcon from 'assets/images/employee-programs/icons/coins.svg';
import PercentageIcon from 'assets/images/employee-programs/icons/percentage.svg';
import ChartGrowthIcon from 'assets/images/employee-programs/icons/chart-growth.svg';
import { useBreakpoints } from '../../../../features/hooks';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';
import { WrapperSummForom, WrapperItemsSumit, ItemSumIt, Icon } from './styles';


export const SumItUpFromHappy: FunctionComponent = () => {
  const { isSmall } = useBreakpoints();
  return <>
    <Container>
      <WrapperSummForom>
        <Row>
          <Col>
            <h2>{ isSmall ? <MultilingualText tKey="sum-it-up-title-mobile" area="happy_parents" /> :<MultilingualText tKey="sum-it-up-title" area="happy_parents" /> } </h2>
          </Col>

          <WrapperItemsSumit>

            <Col lg={ 5 } md={ 6 } sm={ 12 } noGutter={ true }>
              <ItemSumIt>
                <Col col={ isSmall ? 2 : 2.63 }>
                  <Icon src={ PresentIcon } />
                </Col>
                <div>
                  <p>
                    <MultilingualText tKey="present-text" area="happy_parents" />
                  </p>
                </div>
              </ItemSumIt>
            </Col>


            <Col lg={ 5 } md={ 6 } sm={ 12 } noGutter={ true }>
              <ItemSumIt>
                <Col col={ isSmall ? 2 : 2.63 }>
                  <Icon src={ PercentageIcon } />
                </Col>
                <div>
                  <p>
                    <MultilingualText tKey="percentage-text" area="happy_parents" />
                  </p>
                </div>
              </ItemSumIt>
            </Col>
            <Col lg={ 5 } md={ 6 } sm={ 12 } noGutter={ true }>
              <ItemSumIt>
                <Col col={ isSmall ? 2 : 2.63 }>
                  <Icon src={ CoinsIcon } />
                </Col>
                <div>
                  <p>
                    <MultilingualText tKey="coin-text" area="happy_parents" />
                  </p>
                </div>
              </ItemSumIt>
            </Col>
            <Col lg={ 5 } md={ 6 } sm={ 12 } noGutter={ true }>
              <ItemSumIt>
                <Col col={ isSmall ? 2 : 2.63 }>
                  <Icon src={ ChartGrowthIcon } />
                </Col>
                <div>
                  <p>
                    <MultilingualText tKey="chart-growth-text" area="happy_parents" />
                  </p>
                </div>
              </ItemSumIt></Col>
          </WrapperItemsSumit>
        </Row>
      </WrapperSummForom>

    </Container>

  </>;
};
