import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store/store';

const initialState: LanguageState = {
  lang: 'pl',
};

const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    changeLanguage: (state: LanguageState, action) => {
      return { ...state, lang: action.payload };
    },
  },
});

const changeLanguage = languageSlice.actions.changeLanguage;

export default languageSlice.reducer;
export { changeLanguage };

export const languageSelect = (state: RootState) => state.language;
