import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import GTM from 'features/services';
import { bootstrapSelect } from 'features/bootstrap';
import { languageSelect } from 'features/language/'
import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import CompetencesTestBanner from 'view/components/content/CompetencesTestBanner';
import Slider from 'view/components/content/Slider';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';

import TopBanner from './components/TopBanner';
import AboutUsTextSection from './components/AboutUsTextSection';
import PhotoCollage from './components/PhotoCollage';
import AboutUsFeature from './components/Feature';
import { FeaturesList } from './featuresList';

import ESDLogo from 'assets/images/about-us/logos/ESD.png';
import SPSLogo from 'assets/images/about-us/logos/SPS.png';
import KRLogo from 'assets/images/about-us/logos/KR.png';
import KRLogoEN from 'assets/images/about-us/logos/KR-en.png';
import EbnaviLogo from 'assets/images/about-us/logos/ebnavi.png';

import { Main, ContentBox, TextBox, Logos, Logo, UniContainer } from './styles';

export const AboutUsPage: FunctionComponent = () => {
  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);
  const [slides, setSlides] = useState<ISlide[]>([]);
  useEffect(() => {
    if (!bootstrap.data.cms)
      return;
    setSlides(bootstrap.data.cms['who-we-are'].testimonials);
  }, [bootstrap.data.cms]);

  useEffect(() => {
    GTM(lang === 'en' ? 'aboutUsLoadedEN' : 'aboutUsLoaded');
  }, [lang]);

  const logos = [
    {
      id: 'equal-salary-certification',
      name: 'Equal-salary certified',
      link: 'https://www.equalsalary.org/equal-salary-certification/',
      image: ESDLogo
    },
    {
      id: 'sukces-pisany-szminka',
      name: 'Sukces pisany szminką',
      link: 'https://sukcespisanyszminka.pl',
      image: SPSLogo
    },
    {
      id: 'diversity-charter',
      name: lang === 'en' ? 'Diversity Charter' : 'Karta różnorodności',
      link: 'http://odpowiedzialnybiznes.pl/karta-roznorodnosci/',
      image: lang === 'en' ? KRLogoEN : KRLogo
    },
    {
      id: 'employer-branding-star',
      name: 'Employer Branding Star',
      link: 'https://ebnavi.com/ebstars2021/',
      image: EbnaviLogo
    }
  ]

  return <Main>
    <TopBanner />
    <ContentBox>
      <AboutUsTextSection />
      <Container>
        <TextBox>
          <h2><MultilingualText tKey="logosHeader" area="aboutUs" /></h2>
        </TextBox>
        <Logos>
          {logos.map(company =>
            <a key={company.id} href={company.link} target="_blank" rel="noopener noreferrer"
              onMouseUp={() => GTM('aboutUsLogoClick', company.name, company.link)}>
              <Logo src={company.image} />
            </a>)}
        </Logos>
        {FeaturesList.map((x) => <AboutUsFeature feature={x} key={x.key} />)}
      </Container>
      <UniContainer type="large">
        <CompetencesTestBanner borderBottom={false} />
      </UniContainer>


      {slides.length > 0 &&
        <UniContainer type="large">
          <LazyloadBackgroundImage image={slides[0].image}>
            <Slider slides={slides} isQuoteIconVisible={true} />
          </LazyloadBackgroundImage>
        </UniContainer>}
      <PhotoCollage />
    </ContentBox >

  </Main >;

};