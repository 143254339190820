import React from 'react';

import Modal from 'react-modal';
import { Container } from 'styled-bootstrap-grid';
import { ReactComponent as PhoneIconBlue } from 'assets/images/contact/phone-blue.svg';
import {
  ButtonClosedesktop,
  ButtonOpenModal,
  ContentMap,
  FormMap,
  IconInfo,
  IconMap,
  IframeMap,
  InfoAdresUl,
  UlMapSocial,
} from '../styles';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '100%',
    maxWidth: '120rem',
    maxHeight: '75rem',
    height: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(25,25,25,0.89)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 1000,
  },
};


export const PopUp = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Container>
      <ButtonOpenModal onClick={ openModal }><IconMap></IconMap></ButtonOpenModal>
      <Modal
        isOpen={ modalIsOpen }
        onRequestClose={ closeModal }
        style={ customStyles }>
        <ButtonClosedesktop onClick={ closeModal }></ButtonClosedesktop>
        <FormMap>
          <ContentMap>
            <h1>{ props.department }</h1>
            <InfoAdresUl>
              <li>{ props.businessName }</li>
              <li>{ props.addres }</li>
              <li>{props.secondAdress} </li>
              <li>  { props.postcode }</li>
            </InfoAdresUl>
            <UlMapSocial>
              <li><IconInfo><PhoneIconBlue /></IconInfo><a href={`tel:${props.phoneNamber}`}>{ props.phoneNamber }</a></li>
            </UlMapSocial>
          </ContentMap>
          <IframeMap
            src={ props.iframeMap }
          />
        </FormMap>
      </Modal>
    </Container>
  );
};

