import React, { FunctionComponent, useEffect, useRef, useState, MutableRefObject } from 'react';

import { StyledPopup, StyledTrigger } from './styles';
import { IPopupProps, ITriggerProps, IWrapperProps } from './interfaces';
import { GridTheme } from 'core/theme';


/**
 * @param children
 * @constructor
 */
const Wrapper: FunctionComponent<IWrapperProps> = ({ children, onToggle, closeModal }) => {
  const [open, setOpen] = useState(false);

  const ref: MutableRefObject<any> = useRef(null);

  const changeOpen = (value: boolean) => {
    setOpen(value);
    if (onToggle !== undefined) {
      onToggle(value);
    }
  };

  useEffect(() => {
    if(closeModal === false) {
      changeOpen(false);
    }
    const _onWindowClick = (ev) => {
      if (ref !== null && ref.current !== null) {
        if ((!ref.current.contains(ev.target) || (ref.current.contains(ev.target) && ev.target.tagName === 'A')) && open && window.innerWidth > GridTheme.breakpoints.sm) {
          changeOpen(false);
        }
      }
    };

    window.addEventListener('click', _onWindowClick);
    window.addEventListener('touchstart', _onWindowClick);

    return () => {
      window.removeEventListener('click', _onWindowClick);
      window.removeEventListener('touchstart', _onWindowClick);
    };
  });

  const boundChildren = React.Children.map(children, (child: React.ReactElement) => {
    if (child.type === Trigger) {
      child = React.cloneElement(child, {
        setOpen: () => {
          changeOpen(!open);
        },
        open: open,
      });
    } else if (child.type === Popup) {
      child = React.cloneElement(child, {
        open: open,
      });
    }
    return child;
  });

  return <div ref={ref}>
    {boundChildren}
  </div>;
};

/**
 * @param children
 * @param open
 * @constructor
 */
export const Popup: FunctionComponent<IPopupProps> = ({ children, open, hasPadding }) => {
  return <StyledPopup hasPadding={hasPadding} open={open !== undefined ? open : false}>
    {children}
  </StyledPopup>;
};


/**
 * @param props
 * @constructor
 */
const Trigger: FunctionComponent<ITriggerProps> = (props) => {
  const { children, setOpen, open } = props;
  return <StyledTrigger className={open !== undefined && open ? 'open' : ''} onClick={() => {
    if (setOpen !== undefined) {
      setOpen();
    }
  }}>
    {children}
  </StyledTrigger>;
};

export const Dropdown = { Wrapper, Popup, Trigger };