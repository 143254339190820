import * as React from 'react';
import {FunctionComponent, useEffect} from 'react';
import { Route, Switch , Redirect,useLocation} from 'react-router';
import { getRoutersToRender } from './';
import { IRoute } from './router';

import NotFound from '../../view/pages/404';
import ScrollToTop from './scroll-up-routes';


export const RenderRoutes: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const pathnameLength = pathname.length;

    if (pathnameLength > 1 && pathname.endsWith('/')) {
      const newPathname = pathname.substring(0, pathnameLength - 1);
      window.location.replace(newPathname);
    }
  }, [location]);

  return <>
    <ScrollToTop />
    <Switch>
      {getRoutersToRender().map((route: IRoute, idx: number) => {
        return <Route  key={idx} exact={route.exact} strict={route.strict} path={route.path} component={route.component} />;
      })}
      <Route render={(e) => {
        return <NotFound />;
      }} />
    </Switch>
  </>;
};
