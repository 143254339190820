import styled from 'styled-components';
import { media, Theme } from 'core/theme';
import ArrowDownIcon from 'assets/images/whereWorking/arrow-down.svg';

export const WrapperHeadWhereWorking = styled.div`
  background: ${Theme.colors.darkText};
  height: 19.125rem;
  display: flex;
  flex-direction: column-reverse;

  ${ media.up('md') } {
   
  height: 12.5rem;

  }
`;
export const HeaderContainerTitle = styled.div`
  h1 {
    letter-spacing: -0.24px;
    color: ${ props => props.theme.colors.lightText };

    ${ media.up('md') } {
      margin-top: 0;
      margin-bottom: 4.625rem;
      letter-spacing: -0.41px;
    }
  }
`;
export const ContainerButtonScroll = styled.div`
  color: ${ props => props.theme.colors.lightText };

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    list-style: none;

    a {
      letter-spacing: -0.11px;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: 300;

      &:active, &:visited, &:link {
        color: ${ props => props.theme.colors.lightText };
      }
    }
  }

  ${ media.up('md') } {
    ul {
      justify-content: space-evenly;

      a {
        letter-spacing: -0.19px;
        align-items: center;
        font-size: 1.5rem;
        padding-top: 1.188rem;
        flex-direction: row;
      }
    }
  }
`;

export const ArrowDown = styled.svg`
  background: url("${ ArrowDownIcon }") no-repeat ;
  background-size: 1.125rem;
  margin-top:0.625rem ;
 width: 1.125rem;
  height: 1.125rem;
  ${ media.up('md') } {
  margin-top: 0;
  margin-left: 0.625rem;
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  }
`;