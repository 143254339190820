import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import postscribe from 'postscribe';

import Header from 'view/components/structure/header';
import Footer from 'view/components/structure/footer';
import Main from 'view/components/structure/main';

import { Wrapper } from './styles';

export const BaseLayout: FunctionComponent = ({ children }) => {
  useEffect(() => {
    postscribe('#base-wrapper', `<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript" charset="UTF-8"
          data-domain-script="f4377f72-ac8c-415a-92ea-628f2760f33a" ></script>
          <script type="text/javascript">
                  function OptanonWrapper()
                  {
                      if(!getCookie("OptanonAlertBoxClosed")) {

                          pushCustomDataLayer('Popup loads - cookies', '(not defined)', '(not defined)')

                          var settingsButton = document.getElementById("onetrust-pc-btn-handler");
                          var acceptCookies = document.getElementById("onetrust-accept-btn-handler");
                          var savePreference = document.getElementsByClassName("onetrust-close-btn-handler")[0];

                          settingsButton.addEventListener("click", function (event) {
                              pushCustomDataLayer('Popup clicks - cookies', settingsButton.innerText, '(not defined)')
                          }, false);
                          acceptCookies.addEventListener("click", function (event) {
                              pushCustomDataLayer('Popup clicks - cookies', acceptCookies.innerText, '(not defined)')
                              setTimeout(function (){
                                   window.location.reload(true);
                              }, 200);

                          }, false);
                          savePreference.addEventListener("click", function (event) {
                              pushCustomDataLayer('Popup clicks - cookies', savePreference.innerText, '(not defined)')
                              setTimeout(function (){
                                   window.location.reload(true);
                              }, 200);
                          }, false);
                      }

                      var footerSettingsButton = document.getElementsByClassName("ot-sdk-show-settings")[0];
                      footerSettingsButton.addEventListener("click", function (event) {
                          pushCustomDataLayer('Popup clicks - cookies', footerSettingsButton.innerText, '(not defined)')
                      }, false);
                  }

                  function getCookie(name) {
                      var value = "; " + document.cookie;
                      var parts = value.split("; " + name + "=");
                      if (parts.length == 2)
                          return parts.pop().split(";").shift();
                  }
                  function pushCustomDataLayer(category, action, label) {
                      window.dataLayer.push({
                          'event': 'backendMicroconversion',
                          'category': category,
                          'action': action,
                          'label': label
                      });
                  }
              </script>
    `)
  }, [])
  return <Wrapper id="base-wrapper">
    <Header />
    <Main children={children} />
    <Footer />
  </Wrapper>;
};
