import React, {FunctionComponent} from "react";
import Modal from 'react-modal';
import {Col} from 'styled-bootstrap-grid'
import PopUpBanner from 'assets/images/smart-work/pop-up-banner.jpg'
import MultilingualText from "../../../../components/content/MultilingualText/MultilingualText";
import {
    NumberListInfo,
    WrapperItemListInfo,
    ContainerPopUPSmartWork,
    SeeMorePopup,
    TitleModalSmartWork
} from "./styles";
import {ButtonClosedSmartInfo} from "./styles";
import styled from "styled-components";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        width: '100%',
        maxWidth: '120rem',
        maxHeight: '75rem',
        height: '100%',
    },
    overlay: {
        backgroundColor: 'rgba(25,25,25,0.89)',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 1000,
    },
};
export const PopUpBannerIMG = styled.img`
  width: 100%;
`

export const SmartWorkPopUpInfo: FunctionComponent = (props) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    return <>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}>
            <ButtonClosedSmartInfo onClick={closeModal}></ButtonClosedSmartInfo>
            <ContainerPopUPSmartWork>
                <Col lg={4} md={4} sm={12}>
                    <TitleModalSmartWork><MultilingualText tKey="modal-title-samrt-work-list"
                                                           area="smartWork"/></TitleModalSmartWork>

                    <PopUpBannerIMG src={PopUpBanner} />
                </Col>
                <Col lg={8} md={8} sm={12}>
                    <h3><MultilingualText tKey="modal-sub-title-1" area="smartWork"/></h3>
                    <p>
                        <MultilingualText tKey="modal-desc-1" area="smartWork"/>
                    </p>
                    <p><strong>#SMARTWORK </strong><MultilingualText tKey="modal-desc-2" area="smartWork"/></p>
                    <div>
                        <h3><MultilingualText tKey="modal-sub-title-2" area="smartWork"/></h3>
                        <WrapperItemListInfo>
                            <NumberListInfo>01</NumberListInfo><p><MultilingualText tKey="modal-list-item-1"
                                                                                    area="smartWork"/></p>
                        </WrapperItemListInfo>
                        <WrapperItemListInfo>
                            <NumberListInfo>02</NumberListInfo><p><MultilingualText tKey="modal-list-item-2"
                                                                                    area="smartWork"/></p>
                        </WrapperItemListInfo>
                        <WrapperItemListInfo>
                            <NumberListInfo>03</NumberListInfo><p><MultilingualText tKey="modal-list-item-3"
                                                                                    area="smartWork"/></p>
                        </WrapperItemListInfo>
                        <WrapperItemListInfo>
                            <NumberListInfo>04</NumberListInfo><p><MultilingualText tKey="modal-list-item-4"
                                                                                    area="smartWork"/></p>
                        </WrapperItemListInfo>
                        <WrapperItemListInfo>
                            <NumberListInfo>05</NumberListInfo><p><MultilingualText tKey="modal-list-item-5"
                                                                                    area="smartWork"/></p>
                        </WrapperItemListInfo>
                    </div>

                </Col>
            </ContainerPopUPSmartWork>
        </Modal>

        <SeeMorePopup onClick={openModal}><MultilingualText tKey="check-rules" area="smartWork"/></SeeMorePopup>
    </>
}