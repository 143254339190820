import styled from 'styled-components';
import { media } from 'core/theme/media';

export const Header = styled.h2`
  padding: 0;
  letter-spacing: -0.46px;
  max-width: 100%;
  margin-top: 152px;
  margin-bottom: 32px;

  ${media.down('md')} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
