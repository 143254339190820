import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { pageAPI } from './';
import { RootState } from 'core/store/store';

const initialState: PageState = {
  loading: false,
  error: null,
  pages: [],
};

export const fetchPageByName = createAsyncThunk('page/fetchByName', async (name: string) => {
  return await pageAPI.fetchByName(name, 'pl');
});

const page = createSlice({
  name: 'page',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPageByName.fulfilled.toString()]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.pages.push({
        name: action.meta.arg,
        data: action.payload.data,
      });
    },
    [fetchPageByName.pending.toString()]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchPageByName.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default page.reducer;

export const pageSelect = (state: RootState, pageName: string) => state.page.pages.find((page: IPage) => page.name === pageName);
export const pagePendingSelect = (state: RootState) => state.page.loading;
export const pageRejectedErrorSelect = (state: RootState) => state.page.error;
