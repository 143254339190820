import styled from 'styled-components';
import { media } from 'core/theme';
import ArrowTop from 'assets/images/whereWorking/arrow-top.svg';
import { IShowUp } from '../interfaces';

export const ScrollButtonIcon = styled.div<IShowUp>`

  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 11111;
  cursor: pointer;
  opacity: 1;
  
  ${media.up('md')} {
    right: 1vw;
  }
  
  width: 4rem;
  height: 4rem;
  background: url(${ArrowTop}) no-repeat;
  background-size: 4rem;
  display: ${(props) => props.show ? 'flex' : 'none'}
`