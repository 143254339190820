import React from 'react';
import GTM from 'features/services';

import LazyBackgroundImage from 'view/components/content/LazyBackgroundImage';
import { Description, Title } from '../CulturePhoto/styles';
import { FlipBox, FlipBoxBack, FlipBoxFront, FlipBoxInner } from './styles';


export const FlipSlide = ({ image, imageMobile, title, description, fullDescription, section }) => {

  return (
    <FlipBox onMouseEnter={() => GTM('homeCardTurnedOver', `${title} (${section})`, '(not defined)')}>
      <FlipBoxInner>
        {image === undefined ?
          <FlipBoxFront image={image}>
            <Title image={image} imageMobile={imageMobile}>{title}</Title>
            <Description image={image}>{description}</Description>
          </FlipBoxFront> :
          <LazyBackgroundImage image={image}>
            <FlipBoxFront image={image}>
              <Title image={image} imageMobile={imageMobile}>{title}</Title>
              <Description image={image}>{description}</Description>
            </FlipBoxFront>
          </LazyBackgroundImage>
        }
        <FlipBoxBack>
          <h3>{title}</h3>
          <p>{fullDescription}</p>
        </FlipBoxBack>
      </FlipBoxInner>
    </FlipBox>

  );
}


