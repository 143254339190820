import styled from 'styled-components';
import { media } from 'core/theme';

interface IButtonProps {
  notDisplayed: boolean;
}

export const Main = styled.div`
    z-index: 1000;
  `,
  ModalButton = styled.img<IButtonProps>`
    width: 6.875rem;
    height: auto;
    z-index: 1001;
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;

    ${media.down('md')} {
      width: 3.75rem;
      height: 3.75rem;
      margin-top: 2.688rem;
    }

    ${(props) =>
      props.notDisplayed &&
      `
  display: none;
  `}
  `;
