import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container/container';
import { Col, Row } from 'styled-bootstrap-grid';
import LazyImage from 'view/components/content/LazyImage';
import SmartWorkBannerSecond from 'assets/images/smart-work/smart-work-baner-second.jpg';
import SmartWorkBannerSecondMobile from 'assets/images/smart-work/smart-work-baner-second-mobile.jpg';
import { DescriptionImage } from 'view/pages/about-us/components/AboutUsTextSection/styles';
import { WrapperDescriptionHappy, ImageBox } from './styles';
import MultilingualText from '../../../components/content/MultilingualText/MultilingualText';
import {useBreakpoints} from "../../../../features/hooks";


export const MainTextSmartWork: FunctionComponent = () => {
  const { isSmall } = useBreakpoints();
  return <>
    <Container>
      <Row><Col sm={ 12 } md={ 12 } lg={ 6 }>
        <WrapperDescriptionHappy>
          <h2><MultilingualText tKey="des-title" area="smartWork" /></h2>
          <p><MultilingualText tKey="desk-1" area="smartWork" /></p>
          <ul>
            <li><MultilingualText tKey="list-item-first" area="smartWork" /></li>
            <li>
              <MultilingualText tKey="list-item-second" area="smartWork" />
            </li>
            <li>
              <MultilingualText tKey="list-item-third" area="smartWork" />
            </li>
          </ul>
          <p><MultilingualText tKey="desk-2" area="smartWork" /></p>
          <p><MultilingualText tKey="desk-3" area="smartWork" /></p>
          <p><MultilingualText tKey="desk-4" area="smartWork" /></p>
          <p><MultilingualText tKey="desk-5" area="smartWork" /></p>
        </WrapperDescriptionHappy>
      </Col>

      </Row>
      <Row>
        <Col sm={ 12 } md={ 12 } lg={ 6 }>
          <ImageBox>
            {isSmall ?
                <LazyImage src={ SmartWorkBannerSecondMobile } alt="" sccss={ DescriptionImage } width="61rem" />
              :
                <LazyImage src={ SmartWorkBannerSecond } alt="" sccss={ DescriptionImage } width="61rem" />
            }
          </ImageBox>
        </Col>

      </Row>
    </Container>
  </>;
};
