import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

import { JobOfferList } from '../../../job-offers/list/JobOfferList/JobOfferList';
import { useOffers } from '../../../job-offers/list/JobOffersListPage/useOffers';
import { IOffersComponent } from '../../interface'
import { ActionText, Header, OffersCol, Icon, SeeMore, StyledContainer } from './styles';

export const OffersComponent: FunctionComponent<IOffersComponent>
  = ({ query, locationFilter, teamsFilter }) => {
    const { lang } = useSelector(languageSelect);
    const { offers } = useOffers(`${query}`);
    const link = `${lang === 'en' ? '/en' : ''}/job-offers?limit=10&page=1${locationFilter || ''}${teamsFilter || ''}`

    const { t } = useTranslation("teams");
    const getMultilingualItem = (key) => t(key);

    return <StyledContainer>
      <Row>
        <Col>
          <Header><MultilingualText tKey="offersHeader" area="teams" /></Header>
        </Col>
      </Row>
      <Row>
        <OffersCol col={12} >
          <JobOfferList data={offers} threeInRow />
        </OffersCol>
      </Row>
      <Row>
        <Col lgOffset={8} lg={4} md={12} sm={12}>
          <SeeMore to={link} onMouseUp={() => GTM('teamSeeMoreOffers', `${getMultilingualItem("offersSeeMore")} (${getMultilingualItem("offersHeader")})`, link)}>
            <ActionText>{getMultilingualItem("offersSeeMore")}</ActionText>
            <Icon />
          </SeeMore>
        </Col>
      </Row>
    </StyledContainer>
  };
