import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { getOnlyMenuRoutes } from 'core/router';
import { IRoute } from 'core/router/router';

import GTM from 'features/services';
import { languageSelect } from 'features/language';
import { StyledMenu, StyledMenuItemLink } from './styles';
import { MenuItem } from './menuItem';
import { SubMenu } from './subMenu';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'

import { ReactComponent as HyperlinkIcon } from 'assets/images/icons/hyperlink.svg';

export const Menu: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);
  const { t } = useTranslation("common");

  const getMultilingualItem = (key) => t(key);

  const getPath = (route, subRoute = {} as IRoute) => {
    const isEnglish = lang === 'en';
    const currentRoute = isEnglish ? route.path.find(x => x.startsWith('/en/')) : route.path.find(x => !x.startsWith('/en/'));
    let result = currentRoute;
    if (subRoute.path)
      result += subRoute.path[0]
    return result
  }
  const routes = getOnlyMenuRoutes();

  const onTestClick = () => {
    const link = `${lang === 'en' ? '/en/' : '/'}test`
    window.location.href = link;
    GTM('menuItemFooter', getMultilingualItem('test'), link);
  }

  return <StyledMenu>
    {routes.map((route: IRoute, idx: number) => {
      return route.children && route.children.length > 0 ?
        <SubMenu key={idx} title={route.menu.title}>
          {route.children.filter(route => route !== 'divider').map((subRoute: IRoute, subIdx) => {
            return <MenuItem key={subIdx} path={getPath(route, subRoute)} itemText={getMultilingualItem(subRoute.menu.title)}>
              <span><MultilingualText tKey={subRoute.menu.title} area="common" /></span>
            </MenuItem>;
          })}
        </SubMenu> :
        <MenuItem key={idx} path={getPath(route)} itemText={getMultilingualItem(route.menu.title)}>
          <span> <MultilingualText tKey={route.menu.title} area="common" /> </span>
        </MenuItem>;
    })}
    <MenuItem onClickFunc={onTestClick} itemText={getMultilingualItem('test')}>
      <span><MultilingualText tKey="test" area="common" /></span>
    </MenuItem>
    <MenuItem path={`${lang === 'en' ? '/en' : ''}/contact`} itemText={getMultilingualItem('contact')}>
      <span><MultilingualText tKey="contact" area="common" /></span>
    </MenuItem>
    {/* <MenuItem path={'/'}>
      <span><HyperlinkIcon/> Philip Morris International</span>
    </MenuItem> */}
    <StyledMenuItemLink target="_blank" href="https://www.pmi.com/careers/overview" onMouseUp={() => GTM('footerLink', 'Link', 'https://www.pmi.com/careers/overview')}><HyperlinkIcon />Philip Morris International</StyledMenuItemLink>
  </StyledMenu>;
};
