import { ILocationList } from './interface';
import { Theme } from 'core/theme';

export const LocationList: ILocationList[] = [
  {
    id: 'krakow-factory',
    tKey: 'krakowFactory',
    name:'Fabryka w Krakowie',
    nameEng:'Production Facility in Krakow',
    color: `${Theme.colors.location.yellow}`,
  },
  {
    id: 'warsaw-office',
    tKey: `warsaw`,
    color: `${Theme.colors.location.turquoise}`,
    name:'Biuro w Warszawie',
    nameEng:'Warsaw Office',
  },
  {
    id: 'it-center-krakow',
    tKey: 'krakowIt',
    color: `${Theme.colors.location.blue}`,
    name:'IT Hub w Krakowie',
    nameEng:'IT Hub in Krakow',
  },
  {
    id: 'shared-services-center-krakow',
    tKey: 'krakowCUW',
    color: `${Theme.colors.location.blue}`,
    name:'Zintegrowane Usługi Biznesowe w Krakowie',
    nameEng:'Integrated Business Services Center in Krakow',
  },
  {
    id: 'sales-poland',
    tKey: 'other',
    color: `${Theme.colors.location.blue}`,
    name:'Sieć sprzedaży w całej Polsce',
    nameEng:'Sales Network across Poland',
  },
];
