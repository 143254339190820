import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';
import DIcon from 'assets/images/icons/arrow-right.svg';
import DIconHover from 'assets/images/icons/arrow-right-blue-hover.svg';

const divisionIcon = styled.div`
  height: 2.125rem;
  width: 2.125rem;
  background-image: url(${DIcon});
  background-size: contain;
  background-repeat: no-repeat;

  ${media.down('md')} {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const HeaderSeeMore = styled.h2`
  line-height: normal;
  padding-bottom: 2.375rem;

  ${media.up('md')} {
    width: 24rem;
  }
`,
  Division = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 0.063rem solid ${(props) => props.theme.colors.lines};
    cursor: pointer;

    :hover ${divisionIcon} {
      background-image: url(${DIconHover});
    }
  `,
  DivisionName = styled.h3`
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.28px;
    color: #202430;
    padding-top: 1.313rem;
    padding-bottom: 1.688rem;

    ${media.down('md')} {
      padding-top: 0.75rem;
      padding-bottom: 0.938rem;
    }
    ${media.down('sm')} {
      width: 13rem;
    }
  `,
  DivisionIcon = divisionIcon,
  SeeMore = styled.h3`
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.28px;
    color: ${(props) => props.theme.colors.lightBlue};
    padding-top: 1.313rem;
    padding-bottom: 1.688rem;
  `,
  ArrowIcon = styled.img<{ isRotated: boolean }>`
    width: 2.125rem;
    transform: rotate(0deg);
    transition: all 0.3s;
    ${(props) =>
      !props.isRotated &&
      `
      transform: rotate(180deg);
  `}

    ${media.down('md')} {
      width: 1.5rem;
    }
  `,
  MoreDivisionRow = styled(Row)`
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;

    ${media.down('md')} {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem;
    }
  `;
