import React, {FunctionComponent} from "react";
import {TopBannerSmartWork} from "./TopBannerSmartWork/TopBannerSmartWork";
import {MainTextSmartWork} from "./MainTextSmartWork/MainTextSmartWork";
import {VideoSmartWork} from "./VideoSmartWork/VideoSmartWork";
import {ReviewsWorkers} from "./ReviewsWorkers/ReviewsWorkers";
import {BannersSmartWorkBottom} from "./BannersSmartWorkBottom/BannersSmartWorkBottom";
import {SmartWorkProcess} from "./SmartWorkProcess/SmartWorkProcess";
import {InclusionHappyParents} from "../happy-parents/InclusionHappyParents/InclusionHappyParents";

export const SmartWork: FunctionComponent = () => {

    return<>
    <TopBannerSmartWork/>
       <MainTextSmartWork />
        <VideoSmartWork/>
        <SmartWorkProcess />
        <ReviewsWorkers />
        <InclusionHappyParents />
        <BannersSmartWorkBottom />
        </>
}