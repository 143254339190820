import TagManager from 'react-gtm-module'
import { data } from './data';

export const GTMDataManager: (id: string, action?: string, label?: string, virtualPageView?: string) => void = (id, action, label, virtualPageView) => {
  const tagData = data[id];
  const tagDataWithProps = { ...tagData };
  if (action) {
    if (typeof action === 'string')
      action = action.replace(/<[^>]+>/g, '').replace(/\n/g, ' ').replace(/&nbsp;/g, ' ');
    tagDataWithProps.action = action;
  }
  if (label) {
    if (typeof label === 'string')
      label = label.replace(/<[^>]+>/g, '').replace(/\n/g, ' ').replace(/&nbsp;/g, ' ');
    tagDataWithProps.label = label;
  }
  if (virtualPageView)
    tagDataWithProps.virtualPageView = virtualPageView;
  if (!tagDataWithProps.nointeract && !tagDataWithProps.virtualPageView)
    tagDataWithProps.nointeract = false;
  TagManager.dataLayer({ dataLayer: tagDataWithProps });
}