import React, { FunctionComponent, useState, useRef, MutableRefObject } from "react";
import Slider from "react-slick";
import {
  Text,
  Rectangle,
  Slide,
  Main,
  ArrowNext,
  ArrowPrev

} from './styles'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
export const SliderHome: FunctionComponent = () => {

  const items = [
    <MultilingualText tKey="quote1" area="home" />,
    <MultilingualText tKey="quote2" area="home" />,
    <MultilingualText tKey="quote3" area="home" />
  ]
  const slider: MutableRefObject<any> = useRef<Slider>(null);
  const slidesCount = 3;
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: false,
    activeSlide: activeSlide,
    infinite: false,
    speed: 800,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    focusOnSelect: false,
    afterChange: (current) => setActiveSlide(current),
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev activeSlide={activeSlide} />
    // centerMode: true,
  };
  const onSwipeHandler = (direction) => {
    if (direction === "left" && activeSlide < slidesCount)
      setActiveSlide(prevState => ++prevState)
    if (direction === "left" && activeSlide + 1 >= slidesCount)
      setActiveSlide(0)
    if (direction === "right" && activeSlide === 0)
      setActiveSlide(slidesCount - 1)
    if (direction === "right" && activeSlide > 0)
      setActiveSlide(prevState => --prevState)

  }
  return (
    <Main>
      <Slider {...settings} ref={slider} onSwipe={onSwipeHandler}>
        <Slide slideOpacity={activeSlide === 0 ? false : true}>
          <Rectangle />
          <Text>
            {items[0]}
          </Text>
        </Slide>
        <Slide slideOpacity={activeSlide === 1 ? false : true}>
          <Rectangle />
          <Text>
            {items[1]}
          </Text>
        </Slide>
        <Slide slideOpacity={activeSlide === 2 ? false : true}>
          <Rectangle />
          <Text>
            {items[2]}
          </Text>
        </Slide>
      </Slider>
    </Main>
  );

};