import * as React from 'react';
import styled from 'styled-components';
import { media } from 'core/theme/media';
import arrowRight from 'assets/images/icons/arrow-right-white.svg';
import arrowRightHover from 'assets/images/icons/arrow-right-white-hover.svg';

const iconComp = styled.div`
width: 1.25rem;
height: 1.25rem;
background-size: cover;
background-position: center;
background-image: url(${arrowRight})`;

export const Offer = styled.div`
  width: 100%;
  border: 0.0625rem solid ${(props) => props.theme.colors.lines};
  padding: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  ${media.down('md')}{
    padding: 2.188rem 1.25rem 1.25rem 1.25rem;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1.875rem;
  }`,
  Category = styled.p`
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.offers.division};
  margin: 0;
  padding-bottom: 0.313rem;
  
  ${media.down('md')}{
    font-size: 0.875rem;
  }
  `,
  Header = styled.p`
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  padding-bottom: 1.875rem;

  ${media.down('md')}{
    padding-bottom: 1.25rem;
    font-size: 1.188rem;
  }
  `,
  Button = styled.button`
  width: 100%;
  height: 5rem;
  cursor: pointer;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.blueButtonBackground};
  border: none;
  outline: none;
  padding: 0 2.5rem 0 2.5rem;
  margin-top: 0.625rem;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
  color:  ${(props) => props.theme.colors.lightText};

  &:hover{
    background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
  ${iconComp}{
    background-image: url(${arrowRightHover});
    transition-duration: 0.2s;
  }}

  ${media.down('md')}{
    height: 3.125rem;
    font-size: 1.188rem;
    margin-top: 0;
  }
  `,
  Icon = iconComp,
  CategoryIcon = styled.img`
  width: 1.875rem;
  margin-right: 1.938rem;
  padding-top: 0.5rem;

  ${media.down('md')}{
    margin-right: 1.313rem;
  }
  `,
  Segment = styled.div`
  display: flex;
  align-items: start;
  `,
  Triangle = styled.div`
  background-color: white;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left top;
  transform: rotate(45deg);
  width: 40px;
`,
  NewOfferRibbon = styled(({ className, children }) => (<div className={className}><Triangle />{children}</div>))`
  color: white;
  font-size: 0.875rem;
  letter-spacing: -0.58px;
  font-weight: 500;
  background-color: #ef7d00;
  padding: 0.1rem 0.37rem 0.1rem 2rem;
  position: absolute;
  right: 0;
  top: 0;
  text-transform: uppercase;
  `,
  StyledLink = styled.a`
  text-decoration: none;
  `
