import React from 'react';

import Modal from 'react-modal';
import {
  ButtonClosedesktop,
  ButtonOpenModal,
  ContentMap,
  FormMap,
  IconInfo,
  IconMap,
  IframeMap,
  InfoAdresUl,
  UlMapSocial,
} from 'view/pages/contact/styles';
import { ReactComponent as PhoneIconBlue } from 'assets/images/contact/phone-blue.svg';
import { ReactComponent as MailIconBlue } from 'assets/images/contact/mail-blue.svg';
import styled from 'styled-components';
import { media } from 'core/theme';
import GTM from 'features/services';
import { useLocationData } from 'features/hooks/useLocationData';


const customStyles = {
  content: {
    padding: 0,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    overflow: 'auto',
    width: '100vw',
    height: '100%',
    maxWidth: '120rem',
    maxHeight: '75rem',
    border: 0,

  },
  overlay: {
    backgroundColor: 'rgba(25,25,25,0.89)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 1000,
  },
};

const ButtonOpenModalDepartment = styled(ButtonOpenModal)`
  ${media.up('lg')} {
    margin-top: 1.688rem;
    margin-bottom: 0.5rem;

  }

  ${media.down('md')} {
    margin-top: 0.5rem;
  }
`

export const PopUp = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { key } = useLocationData();
  function openModal() {
    setIsOpen(true);
    GTM(`${key}ClickMap`, 'open map', `${props.department} ${props.address}`);
  }

  function closeModal() {
    setIsOpen(false);
    GTM(`${key}ClickMap`, 'close map', `${props.department} ${props.address}`);
  }

  return (<>
    <ButtonOpenModalDepartment onMouseUp={openModal}>
      <IconMap /></ButtonOpenModalDepartment>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <ButtonClosedesktop onMouseUp={closeModal}>

      </ButtonClosedesktop>
      <FormMap>
        <ContentMap>
          <h1>{props.department}</h1>
          <InfoAdresUl>
            <li>{props.businessName}</li>
            <li>{props.address}</li>
            <li> {props.postcode}</li>
          </InfoAdresUl>
          <UlMapSocial>
            <li><IconInfo><PhoneIconBlue /></IconInfo>{props.phonamber}</li>
            <li><IconInfo><MailIconBlue /></IconInfo>{props.mail}</li>
          </UlMapSocial>
        </ContentMap>
        <IframeMap
          src={props.iframeMap}
        />
      </FormMap>
    </Modal>
  </>
  );
};

