import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import {
  Box,
  StyledButton,
  Icon
} from './styles';
import { IButtonProps } from './interfaces';
import { useBreakpoints } from 'features/hooks/useBreakpoints';
import { LanguageHighOrderComponent } from '../LanguageHighOrderComponent';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'


const Button: FunctionComponent<IButtonProps> = ({ children, type = 'default', link, disableMobile = false, onClick }) => {
  return <Link className={(disableMobile ? 'disabled' : '') + ' ' + type} to={link} onMouseUp={onClick}>
    <StyledButton disableMobile={disableMobile} buttonType={type}>{children}</StyledButton>
  </Link>;
};

const OfferButton: FunctionComponent = () => {
  const { t } = useTranslation("common");
  const getMultilingualItem = (key) => t(key);
  const { lang } = useSelector(languageSelect);
  const link = `${lang === 'en' ? '/en' : ''}/job-offers`;

  return <Button link={link} type={'blue'}
    onClick={() => GTM('menuItemHeader', getMultilingualItem("jobOffers"), `${window.location.host}${link}`)}>
    <Icon type={"offer"} />
    <span>{getMultilingualItem("jobOffers")}</span>
  </Button>;
};

const LanguageButton: FunctionComponent<{ lang: string, onLanguageChange: Function }> = ({ lang, onLanguageChange }) => {
  const { isSmall } = useBreakpoints();
  const onLangClick = () => {
    onLanguageChange();
    GTM('menuItemHeader', lang === 'en' ? 'PL' : 'EN', window.location.href);
  }
  return isSmall ? <></> : <StyledButton disableMobile={true} onClick={onLangClick}>{lang === 'pl' ? 'en' : 'pl'}</StyledButton>
};

const TestButton: FunctionComponent = () => {
  const { t } = useTranslation("common");
  const getMultilingualItem = (key) => t(key);
  const { lang } = useSelector(languageSelect);
  const onTestClick = () => {
    const link = `${lang === 'en' ? '/en/' : '/'}test`;
    window.location.href = link;
    GTM('menuItemHeader', getMultilingualItem('test'), link);
  }
  return <Button onClick={onTestClick} link=''>
    <Icon type={"test"} />
    <span><MultilingualText tKey="test" area="common" /></span>
  </Button>
}

export const ButtonsBox: FunctionComponent = () => {
  return <Box>
    <TestButton />
    <OfferButton />
    <LanguageHighOrderComponent renderComponent={(lang, onLanguageChange) =>
      <LanguageButton lang={lang} onLanguageChange={onLanguageChange} />} />
  </Box>;
};
