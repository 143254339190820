import React, {FunctionComponent, useRef} from 'react';
import HTMLFlipBook from "react-pageflip";
import {historyItemsEN, historyItemsPL} from './data-img-history'
import {useBreakpoints} from "features/hooks";
import {ContainerHistory, WrapperBook, PageHistoryWraper, ImgHistory, ButtonLeft, ButtonRight} from './styles';
import {useSelector} from "react-redux";
import {languageSelect} from "features/language";



export const HistoryPmiPage: FunctionComponent = () => {
    const {isLarge, isMedium} = useBreakpoints();
    const book = useRef();
    const {lang} = useSelector(languageSelect);

    return <>
        <ContainerHistory>
            <WrapperBook>
                {isLarge && <ButtonLeft onClick={() =>
                    // @ts-ignore
                    book.current.pageFlip().flipPrev()}/>}
                {/*
                // @ts-ignore*/}
                {isLarge ? <HTMLFlipBook
                    width={848}
                    height={650}
                    autoSize={true}

                    ref={book}
                    drawShadow={false}
                    className={'historybook'} clickEventForward>
                    {lang === 'en' ?
                        historyItemsEN.map(item => <PageHistoryWraper>
                            <ImgHistory src={item.url} alt={item.nameAlt} id={item.id}/>
                        </PageHistoryWraper>)
                        :
                        historyItemsPL.map(item => <PageHistoryWraper>
                            <ImgHistory src={item.url} alt={item.nameAlt} id={item.id}/>
                        </PageHistoryWraper>)}

                </HTMLFlipBook> : <div>
                    {lang === 'en' ?
                        historyItemsEN.map(item => <PageHistoryWraper>
                            <ImgHistory src={item.url} alt={item.nameAlt} id={item.id}/>
                        </PageHistoryWraper>)
                        :
                        historyItemsPL.map(item => <PageHistoryWraper>
                            <ImgHistory src={item.url} alt={item.nameAlt} id={item.id}/>
                        </PageHistoryWraper>)
                    }
                </div>}
                {isLarge &&  <ButtonRight onClick={() =>
                    // @ts-ignore
                    book.current.pageFlip().flipNext()}/> }
            </WrapperBook>

        </ContainerHistory>
    </>
}