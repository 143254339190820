import styled from "styled-components";
import {media} from "../../../core/theme";
import BackgroudImgHistory from "../../../assets/images/history/background-history.svg";
import ArrowLeftBook from "../../../assets/images/icons/arrow-left-book.svg";

export const ImgHistory = styled.img`

  max-width: 848px;
  max-height: 650px;
  width: 100%;

  ${media.down('md')} {
    margin: 5px 0;
  }
`
export const ContainerHistory = styled.div`
  background: #eaeef4 url(${BackgroudImgHistory}) no-repeat;

  ${media.up('lg')} {

    background-position-x: center;
    background-position-y: -45px;
  }

  ${media.down('md')} {
    padding: 0 15px;
  }

`
export const WrapperBook = styled.div`
  margin: auto;
  position: relative;
  max-width: 848px;

  ${media.up('md')} {
    max-width: 1108px;
    padding: 120px 20px 70px;
  }

  ${media.up('sxl')} {
    max-width: 1780px;
    width: 100%;
    padding: 220px 40px 170px;

  }

  ${media.down('md')} {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const PageHistoryWraper = styled.div`
  text-align: center;
`
export const ButtonLeft = styled.div`
  width: 19px;
  height: 60px;
  background-image: url(${ArrowLeftBook});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: rotate(180deg);
  z-index: 111;
  ${media.up('sxl')} {
    left: 0px;
  }
`
export const ButtonRight = styled(ButtonLeft)`
  left: unset;
  right: 0;
  transform: rotate(0);
  ${media.up('sxl')} {
    right: 0px;
  }


`