export const jobOffersData = {
  jobOffersPageLoaded: {
    event: 'virtualPageView',
    virtualPageView: '/job-offers',
  },
  jobOffersPageLoadedEN: {
    event: 'virtualPageView',
    virtualPageView: '/en/job-offers',
  },
  jobOffersFilters: {
    event: 'backendMicroconversion',
    category: 'Clicks - search filters',
  },
  jobOffersSearchbox: {
    event: 'backendMicroconversion',
    category: 'Clicks - search by text',
  },
  jobOffersNavigation: {
    event: 'backendMicroconversion',
    category: 'Clicks - navigation',
  },
  jobOffersButtonClick: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
};
