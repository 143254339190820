import axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';

import GTM from 'features/services';
import { languageSelect } from 'features/language/'
import { useBreakpoints } from 'features/hooks/useBreakpoints';
import Container from 'view/components/content/container';
import LazyImage from 'view/components/content/LazyImage';

import image from 'assets/images/job-offers/image.jpg';
import { JoinUsBanner } from 'view/pages/recruitment/components/JoinUsBanner/joinUsBanner';
import quoteIcon from 'assets/images/home/quote.svg';
import TopBanner from '../TopBanner';
import { OfferElement } from '../OfferElement/OfferElement';
import { JobOfferDetailsPageParagraph } from './JobOfferDetailsPageParagraph'
import {
    MainRow,
    JobDescription,
    ImageBox,
    Image,
    Text,
    Quote,
    Name,
    Position,
    JoinUsBannerBox,
} from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { useLocation } from 'react-router-dom';

interface JobOfferDetails {
    division?: string;
    title?: string;
    description: string;
    applyLink?: string;
    team?: string;
    city: string;
}

export const JobOffersDetailsPage: FunctionComponent = () => {
    const { lang } = useSelector(languageSelect);
    const { isLarge } = useBreakpoints();
    const location = useLocation();
    const queryParams: any = useParams();
    let history = useHistory();
    const [details, setDetails] = useState<JobOfferDetails | undefined>(undefined);
    const [offerId, setOfferId] = useState<string | null>(null);

    useEffect(() => {
        setOfferId(queryParams.slug);
    }, [queryParams]);

    useEffect(() => {
        GTM('jobOffertLoaded', undefined, undefined, `${location.pathname}`)
    }, [])

    useEffect(() => {
        const { REACT_APP_API_FULL_PATH } = process.env;
        const source = axios.CancelToken.source();

        if (offerId) {
            axios.get(`${REACT_APP_API_FULL_PATH}/job-offer?slug=${offerId}`, { cancelToken: source.token })
                .then(response => {
                    setDetails({ ...response.data.data });
                })
                .catch(() => {
                    history.push('/404/');
                });
        }
        return () => source.cancel('Operation cancelled');
    }, [offerId, lang]);

    return (<div>
        { details && <>
            <TopBanner title={details.title} team={details.team} />
            <Container>
                <MainRow>
                    <Row>
                        {!isLarge && <Col><OfferElement details={details} /></Col>}
                        <Col lg={6} md={12} sm={12}>
                            <JobDescription>
                                {
                                    details.description && details.description.split('\n').map((desc, index) =>
                                        <JobOfferDetailsPageParagraph key={index} text={desc} lineNo={index} />)
                                }
                            </JobDescription>
                        </Col>
                        <Col lg={5} lgOffset={1} md={12} sm={12}>
                            {isLarge && <OfferElement details={details} />}
                            <Row>
                                <ImageBox>
                                    <Text>
                                        <img src={quoteIcon} alt='' />
                                        <Quote><MultilingualText tKey="quote" area="jobOffers" /></Quote>
                                        <Name>Andrzej</Name>
                                        <Position>Customer Care</Position>
                                    </Text>
                                    <LazyImage src={image} alt='' sccss={Image} height="860px" />
                                </ImageBox>
                            </Row>
                        </Col>
                    </Row>
                </MainRow>
            </Container>
            <JoinUsBannerBox>
                <JoinUsBanner tKey="banner" area="jobOffers" position={details.title} link={`${details.applyLink}`}
                    gtmPosition={`[${details.title} | ${details.team} | ${details.city}]`} />
            </JoinUsBannerBox>
        </>}
    </div>);
};
