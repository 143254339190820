import styled from 'styled-components';
import { media } from 'core/theme/media';
import { Row } from 'styled-bootstrap-grid';

export const BenefitRow = styled(Row)`
    margin-bottom: 3.5rem;

    ${media.down('sm')} {
      margin-bottom: 1.5rem;
    }
  `,
  Icon = styled.img`
    height: 3.75rem;
    ${media.down('sm')} {
      height: 2.375rem;
    }
  `,
  Name = styled.p`
    width: 16rem;
    padding-left: 1.3rem;
    line-height: 1.44;
    max-width: 100%;
    ${media.down('sm')} {
      width: 100%;
      padding-left: 0;
      white-space: break-spaces;
    }
  `;
