import React, { FunctionComponent } from "react";
import { Col, Row } from 'styled-bootstrap-grid';

import { useBreakpoints } from 'features/hooks';
import TextComponent from 'view/components/content/TextComponent/TextComponent';
import Quote from 'assets/images/shared/quote.png';

import {
  ContentContainer, Header, MainImage, ContentBox, MainImageCol, ImageCol, QuoteImg, QuoteText, SmallText, Paging,
  PagingComponentBox, CustomPagingComponent, Text, TextCol
} from './styles';

export const SlideComponent: FunctionComponent<ISlideComponent> = (props: ISlideComponent) => {
  const { isSmall } = useBreakpoints();

  const { slide, sliderRef, slidesCount, setActiveSlide, activeSlide, isQuoteIconVisible, onSlideChangeGTM } = props;

  const {
    image,
    header,
    personalData,
    quote,
    mail
  } = slide;

  const renderDots = () => {
    const table: number[] = []
    for (let i = 0; i < slidesCount; i++)
      table.push(i);

    const onPagingClick = (x) => {
      if (x !== undefined && activeSlide !== undefined) onSlideChangeGTM(x + 1, activeSlide + 1);
      setActiveSlide(x);
      sliderRef.current.slickGoTo(x);
    };

    return table.map((x) => <PagingComponentBox key={`slide-no-${x}`}
      onMouseUp={() => onPagingClick(x)}>
      <CustomPagingComponent
        active={activeSlide === x} /></PagingComponentBox>)
  }

  if (isSmall) return (
    <ContentContainer>
      <Row >
        <Col col={12}>
          <Header><TextComponent>{header}</TextComponent></Header>
        </Col>
      </Row>
      <Row alignItems="end">
        <MainImageCol col={7}>
          <MainImage backgroundImage={image} />
        </MainImageCol>
        <ImageCol col={5}>
          {isQuoteIconVisible ? <QuoteImg src={Quote} alt="" /> : <QuoteText><TextComponent>{personalData}</TextComponent></QuoteText>}
        </ImageCol>
      </Row>
      <Col col={12}>
        <ContentBox>
          <Text>
            <Row>
              {isQuoteIconVisible && <QuoteText><TextComponent>{quote}</TextComponent></QuoteText>}
            </Row>
            <Row><SmallText><TextComponent>{isQuoteIconVisible ? personalData : quote}</TextComponent></SmallText></Row>
            <a href={`mailto:${mail}`}>{mail}</a>
          </Text>
          <Row>
            <Paging>
              {renderDots()}
            </Paging>
          </Row>
        </ContentBox>
      </Col>

    </ContentContainer>
  );

  return (
    <ContentContainer >
      <Row>
        <Col col={12}>
          <Header><TextComponent>{header}</TextComponent></Header>
        </Col>

      </Row>
      <Row>
        <Col col={4}>
          <MainImage backgroundImage={image} />
        </Col>
        <TextCol col={7} offset={1}>
          <ContentBox>
            <div>
              {isQuoteIconVisible ? <QuoteImg src={Quote} alt="" /> : ''}
              <Text>
                <QuoteText><TextComponent>{isQuoteIconVisible ? quote : personalData}</TextComponent></QuoteText>
                <SmallText><TextComponent>{isQuoteIconVisible ? personalData : quote}</TextComponent></SmallText>
                <a href={`mailto:${mail}`}>{mail}</a>
              </Text>
            </div>
            <Paging>
              {renderDots()}
            </Paging>
          </ContentBox>
        </TextCol>
      </Row>
    </ContentContainer>
  );
};