import styled from 'styled-components';
import MainBannerhappyPhone from 'assets/images/employee-programs/equal-salary-main-baner-phone.png';
import { media } from 'core/theme';
import MainBannerhappy from 'assets/images/employee-programs/equal-salary-main-baner.png';

export const BanerTop = styled.div`
  position: relative;
  height: 430px;
  background-color: #eb6653;
  background-image: url(${ MainBannerhappyPhone });
  background-position: center top;
  background-size: 375px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column-reverse;

  ${ media.up('md') } {
    height: 440px;
    max-height: 700px;
    background-color: #eb6653;
    background-image: url(${ MainBannerhappy });
    background-size: 50%;
    justify-content: center;
    background-position: right;
  }

  ${ media.up('sxl') } {
    height: 700px;
    background-size: auto;
  }
`;
export const WrapperHeadBannerDesc = styled.div`
  position: relative;
  color: #ffffff;
  margin-bottom: 30px;

  p {
    font-size: 14px;
    line-height: 1.57;
    max-width: 260px;
    letter-spacing: normal;
    margin-top: 10px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.27;
    letter-spacing: -0.75px;
    margin-bottom: 10px;
  }
  
  ${ media.up('lg') } {
    margin-bottom: 100px;
    p {
      margin: 30px 0 0;
      font-size: 24px;
      line-height: 1.36;
      letter-spacing: -0.6px;
      max-width: 470px;
    }
  }

  ${ media.up('md') } {
    h2 {
      font-size: 50px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.62px;
    }
  }
`;
export const IconEmployeeProgramsPage = styled.div<{ iconImg: string }>`
  background-image: url(${ props => props.iconImg });
  background-repeat: no-repeat;
  background-size: contain;
  width: 82px;
  height: 32px;
  position: absolute;
  right: 0;
  top: 8px;

  ${ media.up('md') } {
    position: relative;
    width: 218px;
    height: 80px;
    margin-bottom: 45px;
  }
`;
