import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { useLocation } from 'react-router-dom';

import { useMultilingualText, useTopImage } from 'features/hooks';
import { bootstrapSelect } from 'features/bootstrap';
import { useLocationData } from 'features/hooks/useLocationData';
import { Banner } from 'view/pages/job-offers/list/Banner/Banner';
import Container from 'view/components/content/container';
import { MainDescriptioDepartment } from './components/MainDescription/main-description';
import { LightboxExample } from './components/GallerySection/gallery-location';
import { TeamsWorking } from './components/TeamsWorking/teams-working';
import { WorkingLoks } from './components/WorkLooks/work-looks';
import { MoreLocation } from './components/MoreLocations/MoreLocation';
import { SliderLocationQuote } from './components/SliderLocationQuote/SliderLocationQuote';
import Offers from 'view/pages/teams/components/Offers';
import { BannerLocation } from './components/PopUpBanner/baner-location';
import { ContainerMap, DepartmentTitle, TitleOfWorkingTeams, WrapperHeadLocalization, BannerBox } from './styles';
import GTM from 'features/services';

export const LocationComponent: FunctionComponent = () => {
  const { key, bannerImageDesktop, bannerImagePhone, addressLocation } = useLocationData();
  const location = useLocation();
  const [filter, setFilter] = useState<string>('');
  const [counter, setCounter] = useState<number>(0);
  const backgroundDesktop = useTopImage(bannerImageDesktop);
  const backgroundMobile = useTopImage(bannerImagePhone);

  const department = useMultilingualText(`addressLocation.department`, `locations_${key}`);

  const address = useMultilingualText(`addressLocation.address`, `locations_${key}`);
  const businessName = useMultilingualText(`addressLocation.businessName`, `locations_${key}`);
  const postcode = useMultilingualText(`addressLocation.postcode`, `locations_${key}`);
  const mail = useMultilingualText(`addressLocation.mail`, `locations_${key}`);
  const title = useMultilingualText(`title`, `locations_${key}`);
  const titleOfTeams = useMultilingualText(`titleOfTeams`, `locations_${key}`);

  const bootstrap = useSelector(bootstrapSelect);

  useEffect(() => {
    if (!bootstrap.data.locations)
      return;
    const currentLocation = bootstrap.data.locations.find(loc => loc.key === key);

    let cityFilter = '';
    if (currentLocation.params.cities)
      cityFilter += `&cities=${currentLocation.params.cities}`;
    if (currentLocation.params.teams)
      cityFilter += `&teams=${currentLocation.params.teams}`;
    setFilter(cityFilter);
    setCounter(currentLocation.offersNumber);

    GTM(`${key}`, undefined, undefined, location.pathname);
  }, [bootstrap.data.locations, key]);

  return <>
    <WrapperHeadLocalization BackgroundPhone={backgroundMobile} BackgroundDesktop={backgroundDesktop}>
      <Container>
        <Row>
          <Col>
            <DepartmentTitle >
              <h1>{title}</h1>
            </DepartmentTitle>
          </Col>
        </Row>
      </Container>
    </WrapperHeadLocalization>
    <ContainerMap type={'large'} right>
      <Row>
        <Col lg={12}>
          <BannerLocation key={key} color={addressLocation.color} address={address}
            businessName={businessName} department={department}
            iframeMap={addressLocation.iframe}
            postcode={postcode}
            phonamber={addressLocation.phoneNumber}
            mail={mail}
            locationFilter={filter}
          />

        </Col>

      </Row>
    </ContainerMap>
    <MainDescriptioDepartment />
    <LightboxExample />
    <Container>
      <Row>
        <Col >
          <TitleOfWorkingTeams>{titleOfTeams}</TitleOfWorkingTeams>
        </Col>
      </Row>
    </Container>
    <TeamsWorking />
    <WorkingLoks />
    {counter === 0 ?
      <BannerBox>
        <Banner tKey="noOffersBannerText" area="whereWork" />
      </BannerBox> :
      <Offers query={`${filter}&limit=6`} locationFilter={filter} />}
    <SliderLocationQuote />
    <MoreLocation />
  </>;
};
