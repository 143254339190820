export const ContainerVariants = {
  default: {
    padding: 0,
    maxWidth: {
      // lg: 1170,
      lg: 960,
      xxl: 1170,
      xl: 1170,
    },
  },
  big: {
    padding: 0,
    maxWidth: {
      lg: 1325,
      xxl: 1325,
      xl: 1325,
    },
    margin: 77.5,
  },
  large: {
    padding: 0,
    maxWidth: {
      lg: 1480,
      xxl: 1480,
      xl: 1480,
    },
    margin: 155,
  },
};

export const GridTheme = {
  gridColumns: 12,
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 1240,
    sxl:1640,
  },
  row: {
    padding: 15,
  },
  col: {
    padding: 15,
  },
  container: ContainerVariants.default,
};
