export const acrossCountry = {
  other :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/sales-poland',
  },
  otherClickMap : {
    event: 'backendMicroconversion',
    category: 'Clicks - map',
},
  otherClickOffer: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  otherClickMovieOpen: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  otherClickMoviePlay: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  otherClickMovieExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  otherClickSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  otherLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
}