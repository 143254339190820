import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';

import Container from '../container';
import { languageSelect } from 'features/language'
import { Breadcrumbs } from './styles';
import { CurrentPage } from './CurrentPageInterface';



export const BreadcrumbsComponent: FunctionComponent<CurrentPage> = ({ nameOfPage }) => {
  const { lang } = useSelector(languageSelect);
  return <Breadcrumbs>
    <Container>
      <Row>
        <Col>
          <ul>
            <li><Link to={`${lang === 'en' ? '/en' : ''}/`}>Strona Główna</Link></li>
            <li>•</li>
            <li>{nameOfPage}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </Breadcrumbs>;
};