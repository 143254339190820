import styled from 'styled-components';
import { media } from 'core/theme/media';

export const GeneralInfo = styled.div`
    margin: 9.37rem 0 11.125rem 0;

    ${media.down('md')} {
      margin: 3.75rem 0 4.125rem 0;
    }
  `,
  Sentence = styled.h3`
    width: 99%;
    max-width: 29rem;
    font-weight: normal;

    ${media.down('md')} {
      padding-bottom: 1.75rem;
    }
    ${media.down('sm')} {
      line-height: normal;
      padding-bottom: 1.75rem;
    }
  `,
  Location = styled.div`
    display: flex;
    align-items: center;
    min-height: 6.4rem;

    ${media.down('md')} {
      padding: 2.313rem 0;
    }
  `,
  Icon = styled.img`
    height: 2.563rem;
    padding-right: 1.25rem;
    ${media.down('md')} {
      height: 2.063rem;
      padding-right: 0.938rem;
    }
  `,
  SmallText = styled.p`
    color: ${(props) => props.theme.colors.lightBlue};
    line-height: normal;
    letter-spacing: -0.2px;
    height: 1.438rem;
  `,
  LocationName = styled.h3`
    line-height: normal;
    font-weight: normal;
    height: 2.063rem;
  `,
  Paragraph = styled.p`
    padding-top: 2.438rem;
    width: 90%;
    line-height: 1.56rem;
    min-width: 16.3rem;

    ${media.down('md')} {
      padding-top: 0;
    }
  `,
  Item = styled.li`
    list-style: none;
    padding-bottom: 0.6rem;
  `,
  SubdivisionItem = styled.div`
    border-top: 0.063rem solid ${(props) => props.theme.colors.lines};
    width: 100%;
    white-space: break-spaces;
  `,
  Subdivision = styled.h3`
    line-height: 1.33;
    letter-spacing: -0.28px;
    color: #202430;
    font-weight: normal;
    padding-top: 1.313rem;
    padding-bottom: 1.688rem;

    ${media.down('sm')} {
      padding-top: 1rem;
      padding-bottom: 0.875rem;
      width: 17rem;
      line-height: normal;
    }
  `,
  List = styled.ul`
    margin: 0.2rem 0;
    list-style: none;
    padding-inline-start: 0;
    margin-top: -0.5rem;
  `,
  ListItem = styled.li`
    line-height: 2;
    color: #232733;
    font-size: 0.938rem;

    &:before {
      content: '•';
      padding-right: 0.688rem;
      color: ${(props) => props.theme.colors.blueButtonBackground};
      font-size: 0.938rem;
    }
  `,
  Division = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 0.063rem solid ${(props) => props.theme.colors.lines};
    cursor: pointer;
    margin-top: 1rem;
  `,
  SeeMore = styled.h3`
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.28px;
    color: ${(props) => props.theme.colors.lightBlue};
    padding-top: 1.313rem;
    padding-bottom: 1.688rem;
  `,
  ArrowIcon = styled.img<{ isRotated: boolean }>`
    width: 2.125rem;
    transform: rotate(0deg);
    transition: all 0.3s;
    ${(props) =>
      !props.isRotated &&
      `
    transform: rotate(180deg);
`}

    ${media.down('md')} {
      width: 1.5rem;
    }
  `;
