import styled, { css } from 'styled-components';
import { Container } from 'styled-bootstrap-grid';

import { media } from 'core/theme';

import TogglerOpen from 'assets/images/header/toggler-open.svg';
import TogglerClose from 'assets/images/header/toggler-close.svg';

import { IHeaderProps } from './interfaces';

export const Navbar = styled.nav`
    position: relative;
    padding: 1.188rem 0 1.25rem 0;
    order: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (min-width: 1800px) {
      order: 1;
      height: 5rem;
      padding: 0;
      display: block;
      &:after {
        content: '';
        clear: both;
        display: table;
      }
    }
  `,
  Collapse = styled.div`
    height: auto;
    flex-direction: column;
    display: none;
    flex-basis: 100%;
    flex-grow: 1;

    @media (min-width: 1800px) {
      display: block;
      flex-direction: inherit;
      height: 100%;
      flex-basis: auto;
      ${Container} {
        height: 100%;
      }
    }
  `,
  Logo = styled.div`
    z-index: 5;
    display: flex;
    align-items: center;
    margin-left: 1.875rem;

    a {
      font-size: 0;
    }

    svg {
      height: 2.563rem;
      width: auto;
    }

    @media (min-width: 1800px) {
      float: left;
      height: 100%;
      svg {
        height: auto;
        width: 14vw;
        max-width: 193px;
      }
    }
  `,
  MobileWrapper = styled.div`
    display: block;
    order: 2;
    padding: 3.938rem 0 1.88rem 0;

    @media (min-width: 1800px) {
      display: none;
    }
  `,
  StyledButton = styled.button`
    display: block;
    @media (min-width: 1800px) {
      display: none;
    }
    border: 0;
    background: #fff;
    outline: 0;
    cursor: pointer;
    padding: 0 1.625rem;

    span {
      width: 2rem;
      height: 1.5rem;
      background: url('${TogglerOpen}') no-repeat center center;
      display: inline-block;
      background-size: 1.75rem 1.375rem;
    }
  `,
  Header = styled.header<IHeaderProps>`
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 999;

    ${(props) =>
      props.open === true &&
      css`
        bottom: 0;

        ${StyledButton} {
          span {
            background-image: url('${TogglerClose}');
            background-size: 1.25rem 1.25rem;
          }
        }

        ${Collapse} {
          display: flex;
        }
      `}

    @media (min-width: 1800px) {
      bottom: auto;
      overflow: inherit;
    }
  `;
