export const aboutsUsData = {
  aboutUsLoaded: {
    event: 'virtualPageView',
    virtualPageView: '/about-us',
  },
  aboutUsLoadedEN: {
    event: 'virtualPageView',
    virtualPageView: '/en/about-us',
  },
  aboutUsVideoEnters: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  aboutUsVideoPlays: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  aboutUsVideoExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  aboutUsLogoClick: {
    event: 'backendMicroconversion',
    category: 'Clicks - image',
  },
  aboutUsSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
};
