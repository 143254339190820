import React from 'react';
import Container from 'view/components/content/container';
import styled from 'styled-components';

const GrayLine = styled.hr`
  background-color: #c9d2e3;
  margin: 0;
  border: none;
  height: 1px;
`;
export const Line = () => {
  return <Container>
    <GrayLine />
  </Container>;
};