import styled from 'styled-components';
import Container from 'view/components/content/container';
import { media } from 'core/theme/media';

export const Main = styled.div`
    overflow-x: hidden;
    white-space: pre-wrap;
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  `,
  ContentBox = styled.div`
    width: 100%;
  `,
  TextBox = styled.div`
    width: 100%;
    margin-bottom: 3.75rem;
    text-align: center;
  `,
  Logos = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 7rem;

    ${media.down('md')} {
      padding-bottom: 3.75rem;
    }
  `,
  Logo = styled.img`
    height: 10rem;
    width: auto;

    ${media.down('md')} {
      height: 7rem;
    }
    ${media.down('sm')} {
      height: 3.5rem;
    }
  `,
  UniContainer = styled(Container)`
    ${media.down('md')} {
      padding: 0;
      margin: 0;
      width: 100vw;
    }
  `;
