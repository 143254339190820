export const homeData = {
  homeLoaded: {
    event: 'virtualPageView',
    virtualPageView: '/',
  },
  homeLoadedEN: {
    event: 'virtualPageView',
    virtualPageView: '/en/',
  },
  headerLogo: {
    event: 'backendMicroconversion',
    category: 'Clicks - header',
  },
  menuItemFooter: {
    event: 'backendMicroconversion',
    category: 'Clicks - footer',
  },
  menuItemHeader: {
    event: 'backendMicroconversion',
    category: 'Clicks - header',
  },
  footerLogo: {
    event: 'backendMicroconversion',
    category: 'Clicks - footer',
    action: 'Logo',
  },
  footerLink: {
    event: 'backendMicroconversion',
    category: 'Clicks - footer',
  },
  homeSearchBox: {
    event: 'backendMicroconversion',
    category: 'Clicks - search menu',
  },
  homeOffersBlock: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  homeCardTurnedOver: {
    event: 'backendMicroconversion',
    category: 'Hovers - card turned over',
  },
  homeCardModal: {
    event: 'backendMicroconversion',
    category: 'Modal - card popup',
  },
  homeLocationLink: {
    event: 'backendMicroconversion',
    category: 'Clicks - block',
  },
  homeAboutUsButton: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
};
