import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import {
  DetailsLinkEmployeePrograms, IconEmployeePrograms,
  ItemContentBaner
} from 'view/pages/home/components/EmployeePrograms/styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import EqualSalaryIcon from 'assets/images/employee-programs/equal-salary-icon.png';
import SuksesPisanyImg from 'assets/images/employee-programs/sukses-pisany-logo.png';
import KartaRoznorodnosciImg from 'assets/images/employee-programs/karta-roznorodnosci-logo.png';
import { Col } from 'styled-bootstrap-grid';
import { RowBannersHappyParent, WrraperBunnerEqualSalary, BannerImportantInitiatives, LogoInsideBanner } from './styles';
import { useSelector } from 'react-redux';
import { languageSelect } from '../../../../features/language';



export const BannersHappyParentBottom: FunctionComponent = () => {
  const { lang } = useSelector(languageSelect);

  return <>
    <Container>
      <RowBannersHappyParent>
        <Col lg={ 5.7 } md={ 6 } sm={ 12 } noGutter={ true }>

          <h3><MultilingualText
            tKey="check" area="happy_parents" /></h3>
          <WrraperBunnerEqualSalary>
            <ItemContentBaner color={ '#eb6653' }>
              <p className="title"><MultilingualText
                tKey="equalSalary" area="happy_parents" /></p>
              <p className="description"><MultilingualText
                tKey="equalSalaryDesc" area="happy_parents" /></p>
              <DetailsLinkEmployeePrograms to={`${lang === 'en' ? '/en' : ''}/how-we-work/equal-salary`}><MultilingualText
                tKey="detailsButton" area="home" /></DetailsLinkEmployeePrograms>
              <IconEmployeePrograms iconImg={ EqualSalaryIcon } />
            </ItemContentBaner>
          </WrraperBunnerEqualSalary>
        </Col>
        <Col lg={ 5.7 } md={ 6 } sm={ 12 } noGutter={ true }>

          <h3><MultilingualText
            tKey="other-important" area="happy_parents" /></h3>
          <BannerImportantInitiatives>
            <LogoInsideBanner src={ SuksesPisanyImg } />
            <LogoInsideBanner src={ KartaRoznorodnosciImg } />
          </BannerImportantInitiatives>
        </Col>

      </RowBannersHappyParent>

    </Container>
  </>;
};
