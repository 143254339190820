import React, { FunctionComponent } from 'react';
import { Main } from '../about-us/styles';
import { TopBannerEqualSalary } from './TopBannerEqualSalary/TopBannerEqualSalary';
import { MainTextEqualSalary } from './MainTextEqualSalary/MainTextEqualSalary';
import { ReviewsWorkersSalary } from './ReviewsWorkersSalary/ReviewsWorkersSalary';
import { BannersEqualSalaryBottom } from './BannersEqualSalaryBottom/BannersEqualSalaryBottom';
import { InclusionHappyParents } from '../happy-parents/InclusionHappyParents/InclusionHappyParents';

export const EqualSalaryPage: FunctionComponent = () => {
  return <Main>
    <TopBannerEqualSalary />
    <MainTextEqualSalary />
    <ReviewsWorkersSalary />
    <InclusionHappyParents />
    <BannersEqualSalaryBottom />
  </Main>;
};
