import styled from 'styled-components';
import { media } from '../../../../core/theme';

export const WrapperSummForom = styled.div`
          margin-top: 60px;

          ${ media.up('md') } {
            margin-top: 150px;
          }

          h2 {
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.3px;
          }
  `,
  WrapperItemsSumit = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;

    ${ media.up('md') } {
      margin-top: 75px;
      max-width: 1100px;
    }
  `,
  ItemSumIt = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 10px 0;

    p {
      max-width: 370px;
      margin: 0;
    }

    ${ media.up('md') } {
      align-items: center;
      margin: 25px 0;
    }

  `,

  Icon = styled.img`
    height: 35px;


    ${ media.up('md') } {
      height: 57px;

    }
  `;
