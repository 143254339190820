import styled from 'styled-components';
import { ContainerVariants } from 'core/theme';
import { media } from 'core/theme/media';
import { ReactComponent as CarouselIcon } from 'assets/images/icons/carousel-arrow-right.svg';

export const Main = styled.div`
    width: 100%;
    padding-left: calc(50vw - calc(${ContainerVariants.large.maxWidth.lg}px * 0.5));
    margin-bottom: 5rem;
    &:focus {
      border: none;
      outline: none;
    }

    .dots {
      display: none !important;
    }
    .slick-list {
      width: 100%;
      margin-left: auto;
      @media (max-width: 1700px) {
        width: 95%;
      }
    }
    .slick-slide,
    .slick-slide * {
      outline: none !important;
    }
    .slick-disabled {
      display: none !important;
    }
    .slick-arrow {
      z-index: 100 !important;
    }

    ${media.down('md')} {
      margin-bottom: 1.25rem;
    }
  `,
  NextArrow = styled(CarouselIcon)`
    height: 3.5rem;
    position: absolute;
    right: 4rem;
  `,
  PrevArrow = styled(CarouselIcon)`
    height: 3.5rem;
    position: absolute;
    left: 4rem;
    transform: rotate(180deg);
    @media (min-width: 1620px) {
      left: -2rem;
    }
  `;
