import styled from 'styled-components';
import { media } from 'core/theme/media';

export const Header = styled.h2`
  padding-top: 4.375rem;
  padding-bottom: 3.625rem;
  text-align: center;
  margin: auto;

  ${media.up('lg')} {
    font-size: 2.5rem;
  }
  ${media.down('md')} {
    padding-top: 2.5rem;
    padding-bottom: 2.375rem;
  }
  ${media.down('sm')} {
    width: 13.625rem;
  }
`;
