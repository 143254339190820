import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Col } from 'styled-bootstrap-grid';

import { media } from 'core/theme';
import Container from 'view/components/content/container';
import ArrowRightBlack from 'assets/images/whereWorking/arrow-right-black.svg';
import ArrowRightBlue from 'assets/images/whereWorking/arrow-right-blue-hover.svg';
import ArrowRightWhite from 'assets/images/whereWorking/arrow-right-white.svg';
import ArrowRightWhiteHover from 'assets/images/whereWorking/arrow-right-white-hover.svg';

export const ContainerOffiec = styled(Container)`
  margin-bottom: 3.75rem;

  ${media.up('lg')} {
    margin-bottom: 9.375rem;
  }
`;

export const ImgOffiec = css`
 max-width: 45.313rem;;
 width: 100%;
  ${media.up('lg')} {
 width: 45.313rem;;
 float: right;
 }
`;
export const OfficeTitle = styled.h3`
  font-weight: 400;
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
  width: 100%;
  max-width: 15.125rem;
  line-height: normal;
  ${media.up('md')} {

    max-width: 100%;
    margin: 0 0 1.875rem;
    line-height: 1.46;
    letter-spacing: -0.19px;
  }
`;
export const OfficeDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;

  ${media.up('md')} {
    line-height: 1.56;
    font-size: 1rem;
    letter-spacing: normal;
  }
`;

export const ButtonCareer = styled.button`
  width: 10.313rem;
  height: 3.75rem;
  padding-left: 1.25rem;
  padding-right: 0.938rem;
  background-color: ${props => props.theme.colors.blueButtonBackground};
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: "";
    background: url("${ArrowRightWhite}") no-repeat;
    background-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;

    ${media.up('md')} {
      background-size: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  :hover {
    background-color: ${props => props.theme.colors.blueButtonBackgroundHover};

    &:after {
      background: url("${ArrowRightWhiteHover}") no-repeat;
      background-size: 1.25rem;
      
      ${media.up('md')} {
        background-size: 1.5rem;
      }
    }
  }

  :focus {
    outline: unset;
  }
`;

export const DetailsLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2.5rem;
  font-size: 1rem;
  text-decoration: none;

  &:after {
    content: "";
    background: url("${ArrowRightBlack}") no-repeat;
    margin-left: 0.547rem;
    background-size: 0.9375rem;
    width: 1rem;
    height: 1rem;
  }

  &:active, &:visited, &:link {
    color: ${props => props.theme.colors.darkText};
  }

  &:hover {
    &:after {
      background: url("${ArrowRightBlue}") no-repeat
    }
  }
`;

export const CareerActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.875rem;

  ${media.up('md')} {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
`;

export const TextInButton = styled.h3`
  color: ${props => props.theme.colors.lightText};
  width: 3.063rem;
  line-height: 1.25;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: start;
  font-family: ${(props) => props.theme.font};
  text-transform: uppercase;
  ${media.up('md')} {
    line-height: 1.08;
  }
`;
export const CountInButton = styled.h3`
  color: ${props => props.theme.colors.lightText};
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  font-family: ${(props) => props.theme.font};
`;
export const DepartmentDescription = styled.p`
  font-size: 1.188rem;
  letter-spacing: normal;
  margin: 0 0 3.75rem;
  

  ${media.up('md')} {
    margin: 0 0 9.375rem;
    font-size: 1.5rem;
    line-height: 1.46;
  }
`;
export const DepartmentTitle = styled.h1`
  letter-spacing: -0.19px;
  line-height: normal;
  margin-top: 3.75rem;
  margin-bottom: 1.25rem;
  font-weight: 400;

  ${media.up('md')} {
    font-weight: 300;
    margin-top: 9.375rem;
    margin-bottom: 1.875rem;
    letter-spacing: -0.41px;
  }

`;
export const ColOfficeDescription = styled(Col)`
white-space: break-spaces;
  ${media.up('lg')} {
    padding-right: 2vw;
  }
`