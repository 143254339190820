import styled, { css } from 'styled-components';
import { media } from 'core/theme/media';
import { Col } from 'styled-bootstrap-grid';
import SearchIcon from 'assets/images/home/search-property.svg';

import DropdownIcon from 'assets/images/home/drop-icon-copy.svg';
import DropdownIconMobile from 'assets/images/header/dropdown-icon-mobile.svg';
import Questionnaire from 'assets/images/home/questionnaire.svg';
import PinCheck from 'assets/images/home/pin-check.svg';
import Container from 'view/components/content/container';
import { Link } from 'react-router-dom';
export const Search = styled(Link)`
    text-decoration: none;
    height: 6.25rem;
    width: 6.25rem;

    cursor: pointer;
    ${media.down('sm')} {
      height: 3.75rem;
      width: 100%;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
  `,
  SearchIconShow = styled.div`
    background: ${(props) => props.theme.colors.blueButtonBackground};
    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
    height: 6.25rem;
    width: 6.25rem;
    background-image: url('${SearchIcon}');
    background-repeat: no-repeat;
    background-position: center center;
    ${media.down('sm')} {
      display: none;
    }
  `,
  StyledDropdownIcon = styled.span`
    width: 0.813rem;
    height: 0.438rem;
    display: inline-block;
    vertical-align: middle;
    background: url('${DropdownIconMobile}') no-repeat center center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3.125rem;
    margin: auto;

    ${media.up('sm')} {
      background-image: url('${DropdownIcon}');
      position: absolute;
    }
    ${media.down('sm')} {
      right: 1.25rem;
      background: url('${DropdownIcon}') no-repeat center center;
    }
  `,
  InputTrigger = styled.div<IInputTrigger>`
    display: flex;
    padding: 2.5rem 3.125rem;
    height: 6.25rem;
    background: white;
    margin-left: -15px;
    ${media.down('sm')} {
      padding: 1.25rem 1.375rem;
      height: 3.75rem;
      border: solid ${(props) => props.theme.colors.linesLight} 0.063rem;
      ${(props) =>
        props.open &&
        css`
          border-bottom: solid ${props.theme.colors.linesLight} 0;
        `}
    }
  `,
  StyledSpecIcon = styled.span`
    background: url('${Questionnaire}') no-repeat center center;
    background-size: 1.25rem 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1.875rem;
  `,
  StyledLocIcon = styled.span`
    background: url('${PinCheck}') no-repeat center center;
    background-size: 1.25rem 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1.875rem;
  `,
  InputText = styled.div`
    font-size: 0.938rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    align-items: center;
    display: inline-flex;
    ${media.down('sm')} {
      font-size: 0.875rem;
    }
  `,
  InputContainer = styled(Col)`
    margin-left: 15px;
    padding-right: 0px;
    z-index: 10;
    ${media.down('sm')} {
      margin-left: 0;
      padding-right: 0;
    }
  `,
  InputContainer2 = styled(Col)`
    padding-right: 0;
    z-index: 10;
    ${media.down('sm')} {
      margin-left: 0;
      padding-right: 0;
    }
  `,
  InputContainer3 = styled(Col)`
    padding-left: 0;
    margin-right: -15px;
    ${media.down('sm')} {
      margin-left: 0;
      padding-right: 0;
    }
  `,
  SearchText = styled.div`
    background: ${(props) => props.theme.colors.blueButtonBackground};
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    color: ${(props) => props.theme.colors.lightText};
    font-size: 1.25rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  `,
  SpecContainer = styled(Container)`
    width: calc(100% + 3.38rem);
    margin: -3.38rem -3.38rem -2.47rem -3.38rem;
    /* //margin: -55px -55px -40px -55px; */
    background: white;
    padding: 3.38rem 0 2.47rem 2.65rem;
    ${media.down('sm')} {
      z-index: 10;
    }
  `,
  ColInput = styled(Col)`
    font-size: 0.938rem;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 1.063rem;
    ${media.down('sm')} {
      display: flex;
      align-items: center;
    }
  `,
  SpecContainer2 = styled(Col)`
    width: calc(100% + 3.38rem);
    margin: -3.38rem 1.62rem -2.47rem -3.38rem;
    background: white;
    padding: 3.38rem 0rem 2.47rem 2.65rem;
    ${media.down('sm')} {
      width: calc(100% + 3.38rem);
      z-index: 10;
    }
  `,
  InputLabel = styled.label`
    display: flex;
    position: relative;
    padding-left: 2.188rem;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    ${media.up('sm')} {
      padding-left: 1.688rem;
    }
  `,
  Checkmark = styled.span`
    position: absolute;
    top: -0.15rem;
    left: 0;
    width: 1.563rem;
    height: 1.563rem;
    ${media.up('sm')} {
      top: 0.17rem;
      width: 1.125rem;
      height: 1.125rem;
    }
    background-color: white;
    /* margin-top: 0.1rem; */
    border: solid 1px #7991c0;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
    &:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      ${media.down('sm')} {
        left: 10px;
        top: 4px;
        width: 5px;
        height: 12px;
      }
    }
  `,
  Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    cursor: pointer;
    &:checked + ${Checkmark} {
      background-color: #2196f3;
    }
    &:checked + ${Checkmark}:after {
      display: block;
    }
  `,
  CustomInput = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 1.563rem;
    height: 1.563rem;
    background-color: #eee;
    ${Input}:checked & {
      background-color: #2196f3;
    }
  `,
  CursorPointerSpan = styled.span`
    cursor: pointer;
    /* height: 1.25rem; */
    ${media.down('sm')} {
      font-size: 0.875rem;
    }
  `,
  MobileInputTitle = styled.div`
    display: flex;
    justify-content: space-between;
  `,
  MobileInputTitleText = styled.h2`
    padding: 0;
    margin: 0;
    font-size: 1.188rem;
    line-height: normal;
    font-weight: normal;
    margin-bottom: 1.875rem;
  `,
  MobileInputTitleClose = styled.h2`
    padding: 0;
    margin: 0;
    font-size: 1.188rem;
    line-height: normal;
    font-weight: normal;
    cursor: pointer;
    color: ${(props) => props.theme.colors.blueButtonBackground};
  `,
  ButtonSave = styled.div<{ cities?: boolean }>`
    position: absolute;
    text-transform: uppercase;
    @media (max-height: 680px) {
      ${(props) =>
        !props.cities &&
        `
      position: static;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    `}
    }
    bottom: 0;
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    width: 100%;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.188rem;
    color: ${(props) => props.theme.colors.lightText};
    font-weight: 600;
    align-self: flex-end;
  `,
  Circle = styled.div`
    margin-left: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.875rem;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    background-color: #eaeef4;
  `;
