import styled from 'styled-components';
import Ctrl from 'assets/images/home/ctrl-right.svg';
export const Text = styled.div`
  font-size: 1.188rem;
  color: ${ props => props.theme.colors.lightText };
  height: 8.5rem;
  width: 14.25rem !important;
  line-height: normal;
  letter-spacing: -0.24px;
  font-weight: normal;
  &:focus {
    outline: 0;
  }
`,
Slide = styled.div<ISlideOpacity>`
  display: inline-flex !important;
  &:focus {
    outline: 0;
  }
  ${(props) =>
    (props.slideOpacity) &&
    `
      opacity: 0.1;
    `}
`,
Rectangle = styled.div`
  width: 0.063rem;
  height: 8.2rem;
  background-color: ${ props => props.theme.colors.linesLight };
  margin-right: 1.875rem;
  margin-left: 1.875rem;
  margin-top: 0.4rem;

`,
Main = styled.div`
  width: 100vw;
`,
ArrowNext = styled.div`
  width: 0.625rem;
  height: 2rem;
  right: 2rem;
  &::before {
    content:  url("${Ctrl}");
  }
`,
ArrowPrev = styled.div<ISlideHome>`
  ${(props) =>
    (props.activeSlide === 0) &&
    `
      display: none !important;
    `}
  transform: rotate(180deg);
  width: 0.625rem;
  height: 2rem;
  left: 0.8rem;
  z-index: 10;
  top: 42%;
  &::before {
    content:  url("${Ctrl}");
  }
`

