import styled from 'styled-components';
import Container from 'view/components/content/container';
import { media } from 'core/theme/media';

export const Main = styled.div`
    overflow-x: hidden;
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  `,
  Line = styled.hr`
    width: 100vw;
    height: 0.063rem;
    background-color: #c9d2e3;
  `,
  ImageContainer = styled(Container)`
    border: none;
    height: 37.5rem;
    ${media.down('md')} {
      width: 100vw;
      padding: 0;
      height: 15.625rem;
      margin: 0;
    }
  `,
  Image = styled.img<{ backgroundImage: string }>`
    background-image: url(${(props) => props.backgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;

    ${media.down('md')} {
      width: 100vw;
    }
  `,
  SliderContainer = styled(Container)`
    ${media.down('md')} {
      padding: 0;
      margin: 0;
    }
  `,
  BannerBox = styled.div`
    margin: 3rem 0;
  `;
