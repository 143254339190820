import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import GTM from 'features/services';
import { MenuItem as StyledMenuItem } from '../menu/styles';
import { IMenuItemProps } from '../menu/interfaces';

export const MenuItem: FunctionComponent<IMenuItemProps> = ({ link, children, itemText }) => {

  const match = useRouteMatch(link);
  const active = match !== null && match.isExact;

  return <StyledMenuItem active={active}>
    <Link to={link} onMouseUp={() => GTM('menuItemHeader', itemText, `${window.location.host}${link}`)}>
      {children}
    </Link>
  </StyledMenuItem>;
};