import axios from 'axios';

const onRequestSuccess = (config) => {
   const currentUrl = config.url;
  const isEnglish = window.location.pathname.includes('/en');
  if (!isEnglish) return config;

  const alreadyHasParams = currentUrl.includes('?');
  const updatedUrl = alreadyHasParams ? `${config.url}&locale=en_US` : `${config.url}?locale=en_US`;

  config.url = updatedUrl;
  return config;
};

const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(onRequestSuccess);
};

export { setupAxiosInterceptors };