import styled, { createGlobalStyle } from 'styled-components';
import { media } from 'core/theme';

export const Main = styled.div`
    height: 100%;
    width: 100%;
    min-width: 16rem;
  `,
  FiltersHeaderBox = styled.div`
    width: 100%;
    padding-left: 15px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  FiltersHeader = styled.h2`
    font-size: 1.6rem;
  `,
  Action = styled.span`
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.blueButtonBackground};

    &:hover {
      color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
    ${media.down('md')} {
      padding-top: 0.5rem;
    }
  `,
  Header = styled.h3`
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-bottom: 1.875rem;
  `,
  FilterButton = styled.button`
    width: 100%;
    height: 3.125rem;
    border: 1px solid ${(props) => props.theme.colors.lines};
    background-color: ${(props) => props.theme.colors.lightText};
    color: ${(props) => props.theme.colors.darkText};
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.875rem;
  `,
  Icon = styled.img`
    width: 1rem;
    margin-right: 0.55rem;
  `,
  HiddenScrollbar = createGlobalStyle`
    body {
        overflow: hidden;
    }
  `,
  ModalComp = styled.div`
    width: 100vw;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: #fff;
    overflow: auto;
    padding: 1.875rem 0;
  `,
  Close = styled.div`
    font-size: 0.875rem;
    text-align: right;
    color: ${(props) => props.theme.colors.blueButtonBackground};
    right: 1.875rem;
    top: 2.125rem;
    position: fixed;
    transition-duration: 0.3s;
    cursor: pointer;
  `,
  SaveButton = styled.button`
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    width: 100%;
    height: 3.75rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-top: 0.75rem;
    font-family: Poppins;
    font-size: 1.188rem;
    font-weight: 600;
    letter-spacing: -0.24px;
    text-align: center;
    color: ${(props) => props.theme.colors.lightText};
    text-transform: uppercase;

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
    }
  `;
