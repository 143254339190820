import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme';
import DIcon from 'assets/images/icons/arrow-right.svg';
import DIconHover from 'assets/images/icons/arrow-right-blue-hover.svg';
import { IColorMoreLocation } from 'view/pages/locationPages/interface';

export const MoreLocationsRow = styled(Row)`
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;

    ${media.down('md')} {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem;
    }
  `;
export const LocationIcon = styled.div`
  height: 2.125rem;
  width: 2.125rem;
  background-image: url(${ DIcon });
  background-size: contain;

  ${ media.down('md') } {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const Location = styled.div<IColorMoreLocation>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    border-top: 0.188rem solid ${ (props) => props.color };
    cursor: pointer;
      &:hover ${ LocationIcon } {
      background-image: url(${ DIconHover });
    }
    
`;