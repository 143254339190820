import React, { FunctionComponent } from 'react';
import { Col } from 'styled-bootstrap-grid'

import { useMultilingualText } from 'features/hooks';
import { useBreakpoints } from 'features/hooks/useBreakpoints';
import { IBenefitComponentProps } from '../../interface';
import { BenefitRow, Icon, Name } from './styles';

export const Benefit: FunctionComponent<IBenefitComponentProps> = ({ image, id }) => {
  const { isSmall } = useBreakpoints();
  const BenefitName = useMultilingualText(`benefits.${id}_name`, 'teams');

  return <Col lg={4} md={6} sm={12}>
    <BenefitRow>
      <Col col={isSmall ? 2 : 3}>
        <Icon src={image} />
      </Col>
      <Col col={9}>
        <Name>{BenefitName}</Name>
      </Col>
    </BenefitRow>
  </Col>;
};
