import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { Theme } from 'core/theme';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import { languageSelect } from 'features/language/';
import { bootstrapSelect } from 'features/bootstrap';
import CompetencesTestBanner from "view/components/content/CompetencesTestBanner";
import LazyBackgroundImage from 'view/components/content/LazyBackgroundImage';
import { useBreakpoints, useMultilingualText, useTopImage } from 'features/hooks';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import InputsHome from './components/InputsHome';
import Slider from 'view/components/content/Slider';
import LazyloadBackgroundImage from 'view/components/content/LazyBackgroundImage';
import JobOffers from './components/JobOffers/index';
import ModalFull from './components/ModalFull';
import Office from './components/Office';
import CulturePhoto from './components/CulturePhoto/index';
import SliderHome from './components/SliderHome';

import CulturePhoto3Mobile from 'assets/images/home/culturePhoto3Mobile.jpg';
import CulturePhoto1 from 'assets/images/home/culturePhoto1.jpg';
import CulturePhoto2 from 'assets/images/home/CulturePhoto2.jpg';
import CulturePhoto4 from 'assets/images/home/culturePhoto4.jpg';
import mapMobile from 'assets/images/home/mapa.svg';
import mapMobileEng from 'assets/images/home/mapa-eng-phone.svg';
import BackgroundMap from 'assets/images/home/eu-map-mapa-mask.svg';
import BackgroundMapEng from 'assets/images/home/eu-map-mapa-mask-en.svg';
import HomeBackground from 'assets/images/home/home-background-img.jpg';
import Photo1 from 'assets/images/home/photo1.jpg';
import Photo2 from 'assets/images/home/photo2.jpg';
import Photo3 from 'assets/images/home/photo3.jpg';
import Photo4 from 'assets/images/home/photo4.jpg';
import Photo5 from 'assets/images/home/photo5.jpg';
import {
  Andrzej,
  BlackTextRow,
  ContainerBigLeft,
  ContainerFull,
  ContainerHead,
  HeaderCol,
  ConteinerDescription,
  CultureContent1,
  CultureContentMobile,
  CultureTitle1,
  DescriptionTitle,
  GradientImage,
  ItemGroup,
  ItemText,
  LeftColNormalCulture,
  LeftColNormalHidden,
  Logo,
  Main,
  MakeHistory,
  MapDescription,
  MapMobile,
  MapTitle,
  MoreAboutLocal,
  MoreAboutLocalIcon,
  MoreAboutUs,
  MoreAboutUsIcon,
  OfficeBox,
  Quote,
  RectangleSeparator,
  RightColNormalCulture,
  RowMarginCulture,
  StickFullWidthMobile,
  StickPhoto,
  StickText,
  StickText2,
  TextNextToLogo,
  Title,
  Title2,
  WrapperHead,
  WrapperHeadColor,
  WrapperHeadImage,
  WrapperMapBackground,
  WrapperSlider,
} from './styles';
import { EmployeePrograms } from './components/EmployeePrograms/employeePrograms';
import Container from 'view/components/content/container/container';
import {HistoryPmiBanner} from "./components/HistoryPmiBanner/HistoryPmiBanner";
import {MissionIsPossibleBanner} from "./components/MissionIsPossibleBanner/MissionIsPossibleBanner";

const culture1Id = 1;
const culture2Id = 2;
const culture3Id = 3;
const culture4Id = 4;
const culture5Id = 5;
const culture6Id = 6;

export const WrapperInput = styled.div `
  margin-bottom: 70px;
`
export const HomePage: FunctionComponent = () => {
  const { isSmall } = useBreakpoints();
  const homeActionText = useMultilingualText(`${isSmall ? 'actionButtonShort' : 'actionButton'}`, 'home');
  const [activeModal, setActiveModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [slides, setSlides] = useState<ISlide[]>([]);
  const topImage = useTopImage(HomeBackground);
  const openCulture = (id) => {
    setActiveModal(true);
    setActiveSlide(id);
  };
  const bootstrap = useSelector(bootstrapSelect);
  const { lang } = useSelector(languageSelect);
  useEffect(() => {
    if (!bootstrap.data.cms)
      return;
    setSlides(bootstrap.data.cms.homepage.testimonials);
  }, [bootstrap.data.cms]);


  const { t } = useTranslation("home");
  const getMultilingualItem = (key) => t(key);

  useEffect(() => {
    GTM(lang === 'pl' ? 'homeLoaded' : 'homeLoadedEN');
  }, [lang]);


  const baseLocationLink = `${lang === 'en' ? '/en' : ''}/working-places/`;
  const generalInformationLink = `${baseLocationLink}general-information`;
  const warsawOfficeLink = `${baseLocationLink}warsaw-office`;
  const krakowFactoryLink = `${baseLocationLink}krakow-factory`;
  const sharedServicesKrakowLink = `${baseLocationLink}shared-services-center-krakow`;
  const ITKrakowLink = `${baseLocationLink}it-center-krakow`;
  const SalesPolandLink = `${baseLocationLink}sales-poland`;
  const aboutUsLink = `${lang === 'en' ? '/en' : ''}/about-us`;

  return <ThemeProvider theme={Theme}>
    <Main>
      <ModalFull activeModal={activeModal} setActiveModal={setActiveModal} activeSlide={activeSlide}
        setActiveSlide={setActiveSlide} />
      <WrapperHeadColor>
        <WrapperHeadImage backgroundPhoto={topImage}>

          <WrapperHead>
            <GradientImage />

            <ContainerBigLeft>
              <ContainerHead>
                <Row >
                  <HeaderCol lg={9} md={8} sm={12} >
                    <ConteinerDescription>
                      <Title><MultilingualText tKey="title" area="home" /></Title>
                      <Title2><MultilingualText tKey="title2" area="home" /></Title2>
                      <DescriptionTitle> <MultilingualText tKey="description" area="home" /></DescriptionTitle>
                    </ConteinerDescription>
                  </HeaderCol>
                  <Col lg={3} md={4} sm={12}>
                    <MakeHistory />
                  </Col>
                </Row>

                <WrapperInput>
                  <Row><InputsHome /></Row>
                </WrapperInput>

                </ContainerHead>
                </ContainerBigLeft>

                <Container type="large">
                  <EmployeePrograms />
                </Container>
                <ContainerBigLeft>
                  <ContainerHead>
                <BlackTextRow>
                  <Col lg={4} md={4} hiddenSmDown>
                    <Logo />
                  </Col>
                  <Col sm={12} lg={8} md={8}>
                    <TextNextToLogo><MultilingualText tKey={isSmall ? `textManiDescriptionMobile` : `textManiDescription`} area="home" />
                    </TextNextToLogo>
                  </Col>
                </BlackTextRow>
                <Row>
                  <Col lg={4} md={4}></Col>
                  <Col lg={8} md={8}>
                    <MoreAboutUs to={aboutUsLink}
                      onMouseUp={() => GTM('homeAboutUsButton', `${getMultilingualItem('moreAbout')} >`, aboutUsLink)}>
                      <MultilingualText
                        tKey="moreAbout" area="home" />
                      <MoreAboutUsIcon></MoreAboutUsIcon>
                    </MoreAboutUs></Col>
                </Row>
                <Row>{!isSmall ?
                  <React.Fragment>
                    <Col sm={12} lg={4}
                      md={4}><ItemGroup><RectangleSeparator /><ItemText><MultilingualText tKey="quote1"
                        area="home" /></ItemText></ItemGroup></Col>
                    <Col hiddenSmDown lg={4}
                      md={4}><ItemGroup><RectangleSeparator /><ItemText><MultilingualText tKey="quote2"
                        area="home" /></ItemText></ItemGroup>
                    </Col>
                    <Col hiddenSmDown lg={4}
                      md={4}><ItemGroup><RectangleSeparator /><ItemText><MultilingualText tKey="quote3"
                        area="home" /></ItemText></ItemGroup></Col>
                  </React.Fragment>
                  : ''}
                </Row>
              </ContainerHead>

              {isSmall && <SliderHome />}
            </ContainerBigLeft>

          </WrapperHead>
        </WrapperHeadImage>
      </WrapperHeadColor>
      <WrapperMapBackground backgroundBanner={`${lang === 'en' ? `${BackgroundMapEng}` : `${BackgroundMap}`}`} />
      <ContainerHead>
        <Row>
          <Col xs={12} lg={4} md={4}>
            <MapTitle><MultilingualText tKey="mapTitle" area="home" /></MapTitle>
            <MapMobile backgroundBanner={`${lang === 'en' ? `${mapMobileEng}` : `${mapMobile}`}`} />
            <MapDescription><MultilingualText tKey="mapDescription" area="home" /></MapDescription>
            <MoreAboutLocal
              to={generalInformationLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('moreAboutLocal'), generalInformationLink)}>
              {getMultilingualItem('moreAboutLocal')}
              <MoreAboutLocalIcon />
            </MoreAboutLocal>
          </Col>
          <OfficeBox sm={12} lg={8} md={8}>
            <Office
              to={warsawOfficeLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('warsawOffice.title'), warsawOfficeLink)}
              tKey="warsawOffice"
              photo={Photo1}
              gradient={'linear-gradient(to left, rgba(29, 33, 36, 0), #212327 82%);'}
              title2={homeActionText}
              rectangle={'#1cb2b4'}
            />
            <Office
              to={krakowFactoryLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('krakowFactory.title'), krakowFactoryLink)}
              tKey="krakowFactory"
              photo={Photo2}
              gradient={'linear-gradient(to left,rgba(29,33,36,0),#212327 82%);'}
              title2={homeActionText}
              rectangle={'#fdc92f'}
            />
            <Office
              to={sharedServicesKrakowLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('sharedServicesCenterKrakow.title'), sharedServicesKrakowLink)}
              tKey="sharedServicesCenterKrakow"
              photo={Photo3}
              gradient={'linear-gradient(to left, rgba(27, 25, 23, 0), #1c1a19 82%);'}
              title2={homeActionText}
              rectangle={'#1c9fd5'}
            />
            <Office
              to={ITKrakowLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('itCenterKrakow.title'), ITKrakowLink)}
              tKey="itCenterKrakow"
              photo={Photo4}
              gradient={'linear-gradient(to left, rgba(25, 25, 25, 0), #191919 82%);'}
              title2={homeActionText}
              rectangle={'#1c9fd5'}
            />
            <Office
              to={SalesPolandLink}
              onMouseUp={() => GTM('homeLocationLink', getMultilingualItem('salesPoland.title'), SalesPolandLink)}
              tKey="salesPoland"
              photo={Photo5}
              gradient={'linear-gradient(to left, rgba(25, 25, 25, 0), #191919 82%);'}
              title2={homeActionText}
              rectangle={'#1cb2b4'}
            />
          </OfficeBox>
        </Row>
      </ContainerHead>
      <ContainerHead>
        <Row>
          <Col xs={12} lg={4} md={4}>
            <CultureTitle1><MultilingualText tKey="cultureTitle" area="home" /></CultureTitle1>
            <CultureContent1><MultilingualText tKey="cultureContent" area="home" /></CultureContent1>
            {isSmall &&
              <CultureContentMobile><MultilingualText tKey="cultureContentMobile" area="home" /></CultureContentMobile>}
          </Col>
          <LeftColNormalCulture xs={6} lg={4} md={4} onClick={() => openCulture(culture1Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              image={CulturePhoto1}
              title={getMultilingualItem(`cultureTitle${culture1Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture1Id}`} area="home" />}
              fullDescription={<MultilingualText tKey={`cultureContent${culture1Id}Show`} area="home" />}
              imageMobile={CulturePhoto1}
            />
          </LeftColNormalCulture>
          <RightColNormalCulture xs={6} lg={4} md={4} onClick={() => openCulture(culture2Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              title={getMultilingualItem(`cultureTitle${culture2Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture2Id}`} area="home" />}
              titleMobile={<MultilingualText tKey={`cultureTitle${culture2Id}`} area="home" />}
              fullDescription={<MultilingualText tKey={`cultureContent${culture2Id}Show`} area="home" />}
              isRight
            />
          </RightColNormalCulture>
        </Row>
        <Row style={{ marginTop: '1.875rem' }}>
          <LeftColNormalCulture xs={6} lg={4} md={4} onClick={() => openCulture(culture3Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              image={CulturePhoto2}
              title={getMultilingualItem(`cultureTitle${culture3Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture3Id}`} area="home" />}
              fullDescription={<MultilingualText tKey={`cultureContent${culture3Id}Show`} area="home" />}
            />
          </LeftColNormalCulture>
          <RightColNormalCulture xs={6} lg={4} md={4} onClick={() => openCulture(culture4Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              title={getMultilingualItem(`cultureTitle${culture4Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture4Id}`} area="home" />}
              imageMobile={CulturePhoto4}
              fullDescription={<MultilingualText tKey={`cultureContent${culture4Id}Show`} area="home" />}
              isRight
            />
          </RightColNormalCulture>
          <Col xs={6} lg={4} className='displayNone1280'>
            <StickPhoto>
              <Quote />
              <StickText><MultilingualText tKey="stickText" area="home" /></StickText>
              <Andrzej>Adam</Andrzej>
              <StickText2><MultilingualText tKey="quoteAuthorPosition" area="home" /></StickText2>
            </StickPhoto>
          </Col>
          <LeftColNormalCulture className='displayNone1280UP' md={4} onClick={() => openCulture(culture5Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              title={getMultilingualItem(`cultureTitle${culture5Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture5Id}`} area="home" />}
              imageMobile={CulturePhoto2}
              fullDescription={<MultilingualText tKey={`cultureContent${culture5Id}Show`} area="home" />}
            />
          </LeftColNormalCulture>
        </Row>
        <RowMarginCulture>
          <LeftColNormalHidden xs={6} lg={4} onClick={() => openCulture(culture5Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              title={getMultilingualItem(`cultureTitle${culture5Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture5Id}`} area="home" />}
              imageMobile={CulturePhoto2}
              fullDescription={<MultilingualText tKey={`cultureContent${culture5Id}Show`} area="home" />}
            />
          </LeftColNormalHidden>
          <RightColNormalCulture xs={6} lg={4} md={4} onClick={() => openCulture(culture6Id)}>
            <CulturePhoto
              section={getMultilingualItem('cultureTitle')}
              image={CulturePhoto4}
              title={getMultilingualItem(`cultureTitle${culture6Id}`)}
              description={<MultilingualText tKey={`cultureContent${culture6Id}`} area="home" />}
              fullDescription={<MultilingualText tKey={`cultureContent${culture6Id}Show`} area="home" />}
              isRight
            />
          </RightColNormalCulture>
          <Col className='displayNone1280UP' md={4}>
            <StickPhoto>
              <Quote />
              <StickText><MultilingualText tKey="stickText" area="home" /></StickText>
              <Andrzej>Adam</Andrzej>
              <StickText2>Menadżer Sprzedaży Retail</StickText2>
            </StickPhoto>
          </Col>
        </RowMarginCulture>
      </ContainerHead>
      <LazyBackgroundImage image={CulturePhoto3Mobile}>
        <StickFullWidthMobile image={CulturePhoto3Mobile}>
          <Quote />
          <StickText><MultilingualText tKey="stickText" area="home" />{`${isSmall ? ' ' : '\n'}`}</StickText>
          <Andrzej>Adam</Andrzej>
          <StickText2>Consumer Experience</StickText2>
        </StickFullWidthMobile>
      </LazyBackgroundImage>
      <ContainerHead>
        <Row>
          <JobOffers />
        </Row>
      </ContainerHead>

      {slides.length > 0 &&
        <WrapperSlider>
          <ContainerFull type="large">
            <LazyloadBackgroundImage image={slides[0].image}>
              <Slider borderBottom={false} slides={slides} isQuoteIconVisible={true} />
            </LazyloadBackgroundImage>
          </ContainerFull>
        </WrapperSlider>}

      <CompetencesTestBanner borderTop={false} borderBottom={false} />

      <ContainerHead>
      </ContainerHead>
      {/* <HappeningBanner />  */}
    </Main>
  </ThemeProvider>;
};
