import styled from "styled-components";
import {DetailsLink} from "../../whereWorking/components/styles";
import {Row} from "styled-bootstrap-grid";
import {media} from "../../../../core/theme";

export const WrapperWorkProgram = styled.div`
  strong {
    color: #0074C2;
    text-decoration: unset;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    margin: 33px 0;
  }
`
export const DetailsLinkWorkProgram = styled(DetailsLink)`
  margin-left: 0;
`

export const RowWorkProgram = styled(Row)`
  margin-bottom: 128px;

  ${media.down('sm')} {
    margin-bottom: 24px;
  }
`

export const WorkProgramImg = styled.img`
  ${media.down('sm')} {
    display: none;
  }
`
export const WorkProgramImgMobile = styled.img`
  width: 100%;

  ${media.up('sm')} {
    display: none;
  }
`