export const Theme = {
  font: `'Poppins', sans-serif`,
  fontSize: '16px',

  colors: {
    lightText: '#ffffff',
    darkText: '#191919',
    lines: '#c9d2e3',
    linesLight: '#d8d8d8',
    blueButtonBackground: '#0074c2',
    blueButtonBackgroundHover: '#0884d7',
    lightBlue: '#7991c0',
    links: '#224498',
    offers: {
      recommended: '#7991c0',
      new: '#ef7d00',
      division: '#8f9197',
    },
    location: {
      blue: '#1c9fd5',
      turquoise: '#1cb2b4',
      yellow: '#fdc92f',
    },
  },

  signature: {
    color: '#0074c2',
    fontSize: '1.875rem',
    font: 'HeyAliceMono',
  },

  desktop: {
    h1: {
      fontSize: '3.125rem',
      letterSpacing: '-0.62px',
      fontWeight: '300',
      lineHeight: 'normal',
    },
    h2: {
      fontSize: '2.313rem',
      letterSpacing: '-0.5px',
      fontWeight: '300',
      lineHeight: '1.35',
    },
    h3: {
      fontSize: '1.5rem',
      letterSpacing: '-0.3px',
      fontWeight: '300',
      lineHeight: '1.46',
    },
  },
  mobile: {
    h1: {
      fontSize: '1.875rem',
      letterSpacing: '-0.37px',
      fontWeight: '300',
      lineHeight: '1.27',
    },
    h2: {
      fontSize: '1.5rem',
      letterSpacing: '-0.32px',
      fontWeight: '300',
      lineHeight: 'normal',
    },
    h3: {
      fontSize: '1.188rem',
      letterSpacing: '-0.24px',
      fontWeight: '300',
      lineHeight: '1.84',
    },
  },

  footerLinkText: {
    fontSize: '0.75rem',
    letterSpacing: '-0.15px',
    color: '#ffffff',
  },
};

export type BaseTheme = typeof Theme;
