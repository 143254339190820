import styled from 'styled-components';

import { Col } from 'styled-bootstrap-grid';
import { ButtonCareer, CountInButton, TextInButton } from 'view/pages/whereWorking/components/styles';
import { media } from 'core/theme';

export const WrapperTextInButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
export const CountInButtonLocation = styled(CountInButton)`
  font-size: 1.875rem;

  ${ media.up('md') } {
    font-size: 2.875rem;
  }
`;
export const TextInButtonLocation = styled(TextInButton)`
  font-size: 0.875rem;
  line-height: 1.07;
  margin-left: 0.938rem;

  ${ media.up('md') } {
    font-size: 1rem;
    margin-left: 1.25rem;
    line-height: 1.25;
  }
`;
export const ColButtonLocation = styled(Col)`
  padding-right: 0;

  ${ media.up('lg') } {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
`;

export const ButtonCareerLocation = styled(ButtonCareer)`
  width: 100%;
  height: 5rem;
  padding-right: 1.875rem;
  padding-left: 2.188rem;

  &:after {
    background-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  :hover {
    &:after {
      background-size: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ${ media.up('lg') } {
    &:after {
      background-size: 3rem;
      width: 3rem;
      height: 3rem;

    }

    :hover {
      &:after {
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
      }
    }

    height: 10rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

`;