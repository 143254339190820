import styled, { css } from 'styled-components';
import { Col, Row } from 'styled-bootstrap-grid';
import { media } from 'core/theme/media';
import { ContainerVariants } from 'core/theme';
import arrow from 'assets/images/icons/arrow-right-white.svg';
import arrowHover from 'assets/images/icons/arrow-right-white-hover.svg';

const arrowIcon = styled.div`
  background-image: url(${arrow});
  background-repeat: no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  background-size: 100% 100%;
  background-position: center;
  margin-left: 1.103rem;

  ${media.down('md')} {
    margin-left: 0.456rem;
  }
`;

export const Banner = styled.div<IBannerTest>`
    border: solid 1px ${(props) => props.theme.colors.lines};
    ${(props) => props.borderTop === false && `border-top: 0;`}
    ${(props) => props.borderBottom === false && `border-bottom: 0;`}
  }
  `,
  Header = styled.h2`
    margin-bottom: 2rem !important;

    ${media.down('md')} {
      margin-bottom: 2.25rem !important;
      width: 88%;
    }
  `,
  Text = styled.p`
    width: 25.125rem;
    ${media.down('md')} {
      width: 80%;
    }
    ${media.down('sm')} {
      width: 96%;
    }
  `,
  Image = css`
    width: 100%;
    height: 100%;
  `,
  ImageBox = styled.div`
    width: calc(
      100% +
        calc(calc(100vw - ${ContainerVariants.default.maxWidth.xl}px - calc(100vw - ${ContainerVariants.large.maxWidth.xl}px)) * 0.5 - 15px)
    );
    height: auto;

    @media (max-width: 1511px) {
      width: calc(88% + calc(calc(100vw - ${ContainerVariants.default.maxWidth.xl}px - calc(50vw - 740px)) * 0.5));
    }
  `,
  PMIButton = styled.button`
    width: 13.688rem;
    height: 3.813rem;
    background-color: ${(props) => props.theme.colors.blueButtonBackground};
    transition: all 0.3s;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5.25rem;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.lightText};
    letter-spacing: -0.24px;

    &:hover {
      background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
      ${arrowIcon} {
        background-image: url(${arrowHover});
      }
    }

    &:focus {
      outline: none;
    }

    ${media.down('sm')} {
      width: 10.75rem;
      height: 3.563rem;
      margin-top: 3.25rem;
      font-size: 1.188rem;
      font-family: Poppins;
    }
  `,
  ArrowIcon = arrowIcon,
  ContnetRow = styled(Row)`
    ${media.only('md')} {
      margin: 0;
    }
  `,
  TextCol = styled(Col)`
    padding-top: 4.88rem;
    padding-bottom: 4.88rem;
    padding-right: 1rem;
  `,
  ImageCol = styled(Col)`
    padding: 0;
    font-size: 0;
    display: block;

    ${media.down('md')} {
      display: none;
    }
  `;
