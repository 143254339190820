import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GTM from 'features/services';
import Container from 'view/components/content/container';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import TextComponent from 'view/components/content/TextComponent/TextComponent';
import { ITeamDescription } from '../../interface';
import { useBreakpoints } from 'features/hooks/useBreakpoints';

import PositionPin from 'assets/images/teams/position-pin.svg';
import ArrowDownIcon from 'assets/images/recruitment/question-arrow.svg';
import {
  GeneralInfo, Sentence, Location, Icon, SmallText, LocationName, Paragraph, Item, SubdivisionItem, Subdivision, List, ListItem,
  Division, SeeMore, ArrowIcon
} from './styles';

export const TeamDescription: FunctionComponent<ITeamDescription> = ({ sentence, locations, paragraph, divisions }) => {
  const location = useLocation();
  const { isLarge } = useBreakpoints();
  const [isListShort, setIsListShort] = useState(true);
  const filterDivisions = () => divisions.slice(0, isListShort ? 3 : divisions.length);
  let aaa = paragraph.split(/(?=[1-9]\.)/);

  const { t } = useTranslation("teams");
  const getMultilingualItem = (key) => t(key);

  const onCollapseExpandClick = () => {
    GTM((isListShort ? 'teamExpand' : 'teamCollapse'), `${getMultilingualItem(isListShort ? 'expand' : 'collapse')}`, '(not defined)');
    setIsListShort(!isListShort);
  }

  return <Container>
    <GeneralInfo>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Sentence><TextComponent>{sentence}</TextComponent></Sentence>
        </Col>
        {isLarge && <Col lg={6} md={12} sm={12}>
          <Location>
            <Icon src={PositionPin} />
            <div>
              <SmallText><MultilingualText tKey="locationCategory" area="teams" /></SmallText>
              <LocationName>{locations}</LocationName>
            </div>
          </Location>
        </Col>}
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Paragraph>{aaa.map((item) => <Item><TextComponent>{item}</TextComponent></Item>)}</Paragraph>
        </Col>
        {!isLarge && <Col lg={6} md={12} sm={12}>
          <Location>
            <Icon src={PositionPin} />
            <div>
              <SmallText><MultilingualText tKey="locationCategory" area="teams" /></SmallText>
              <LocationName><TextComponent>{locations}</TextComponent></LocationName>
            </div>
          </Location>
        </Col>}
        <Col lg={6} md={12} sm={12}>
          {filterDivisions().map((division, index) =>
            <SubdivisionItem key={index}>
              <Subdivision><TextComponent>{division.name}</TextComponent></Subdivision>
              <List>
                {division.list && division.list.map((y, index) =>
                  <ListItem key={index}>{y.item}</ListItem>
                )}

              </List>
            </SubdivisionItem>)}
          {divisions.length > 3 &&
            <Division onMouseUp={onCollapseExpandClick}>
              {isListShort &&
                <SeeMore >
                  <MultilingualText tKey="expand" area="teams" />
                </SeeMore>}
              {!isListShort &&
                <SeeMore >
                  <MultilingualText tKey="collapse" area="teams" />
                </SeeMore>}
              <ArrowIcon src={ArrowDownIcon} isRotated={isListShort} />
            </Division>
          }
        </Col>
      </Row>
    </GeneralInfo>
  </Container>
};
