import React, { useState } from 'react';
import { Col, Row } from 'styled-bootstrap-grid';
import { useSelector } from 'react-redux';

import { languageSelect } from 'features/language/';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText'
import {
    Main,
    MainContainer,
    MainRow,
    SearchContainer,
    SearchIconBox,
    SearchInput,
    SearchTitle,
    SVGIcon,
    SearchBoxCol
} from './styles';


export const SearchBox = ({ value, onSearch }) => {
    const { lang } = useSelector(languageSelect);

    const [timer, setTimer] = useState<any>(undefined);
    const [inputValue, setinputValue] = useState(value);

    const debounce = (func, delay) => {
        if (timer) {
            clearInterval(timer);
        }
        setTimer(setTimeout(func, delay));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            debounce(onSearch.bind(null, inputValue), 160);
            setinputValue('');
        }
    };

    return <Main>
        <MainContainer>
            <MainRow alignItems="center" style={{ height: '100%' }}>
                <Col lg={4} md={12} sm={12}>
                    <Row>
                        <SearchTitle><MultilingualText tKey="jobOffers" area="common" /></SearchTitle>
                    </Row>
                </Col>
                <SearchBoxCol lg={8} md={12} sm={12}>
                    <SearchContainer>
                        <SearchInput defaultValue={value} placeholder={lang === 'en' ? 'What are you looking for?' : 'Wpisz czego szukasz'}
                            onKeyDown={handleKeyDown} value={inputValue} onChange={(e) => setinputValue(e.target.value)} />
                        <SearchIconBox onClick={() => {
                            debounce(onSearch.bind(null, inputValue), 160);
                            setinputValue('');
                        }}>
                            <SVGIcon />
                        </SearchIconBox>
                    </SearchContainer>
                </SearchBoxCol>
            </MainRow>
        </MainContainer>
    </Main>
};
