import * as React from 'react';
import { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid';
import NewsImgContent from 'assets/images/news/secong-img-news.png';
import NewsImgLarge from 'assets/images/news/news-large.png';
import NewsImgMain from 'assets/images/news/news-img.png';
import { BlockContent, BlockContentLastElement, ImgNews, SecondImgSmale } from './styles';
import { ShortDescription } from './components/short-description';
import { ShereSocialBlock } from './components/share-social-block';
import { ShereSocialBlockBottom } from './components/share-social-block-bottom';
import { BreadcrumbsComponent } from '../../components/content/Breadcrumbs/breadcrums';

export const NewsPage: FunctionComponent = () => {
  const linkToShareLinkedin = 'https://www.linkedin.com/company/insidepmi';
  const linkToShareTwitter = 'https://twitter.com/insidepmi ';
  const linkToShareFacebook = 'https://www.facebook.com/InsidePMI/';

  return<>
  <BreadcrumbsComponent nameOfPage={ 'Presents at Chief Executive' } />
  <Container>
    <ShortDescription data={ '13 marca 2020' }
                      title={ 'Presents at Chief Executives for Corporate Purpose (CECP) 2020 CEO Investor Forum' }
                      content={ 'Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas\n' +
                      '        malesuada elit lectus felis, malesuada ultricies.' }
                      MainImg={ NewsImgMain }
    />

    <Row>
      <ShereSocialBlock hrefLinkedIn={ linkToShareLinkedin } hrefFacebook={ linkToShareFacebook }
                        hrefTwitter={ linkToShareTwitter } />
      <Col lg={ 6 }>
        <BlockContent><p>Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque
          dui, non felis. Maecenas
          malesuada elit lectus felis, malesuada ultricies. Curabitur et ligula. Ut molestie a, ultricies porta urna.
          Vestibulum commodo volutpat a, convallis ac, laoreet enim. Phasellus fermentum in, dolor. Pellentesque
          facilisis. Nulla imperdiet sit amet magna. Vestibulum dapibus, mauris nec malesuada fames ac turpis velit,
          rhoncus eu, luctus et interdum adipiscing wisi. Aliquam erat ac ipsum.</p>

          <p>Integer aliquam purus. Quisque lorem tortor fringilla sed, vestibulum id, eleifend justo vel bibendum
            sapien
            massa ac turpis faucibus orci luctus non, consectetuer lobortis quis, varius in, purus. Integer ultrices
            posuere cubilia Curae, Nulla ipsum dolor lacus, suscipit adipiscing. Cum sociis natoque penatibus et
            ultrices
            volutpat.</p>

          <p>Nullam wisi ultricies a, gravida vitae, dapibus risus ante sodales lectus blandit eu, tempor diam pede
            cursus
            vitae, ultricies eu, faucibus quis, porttitor eros cursus lectus, pellentesque eget, bibendum a, gravida
            ullamcorper quam. Nullam viverra consectetuer. Quisque cursus et, porttitor risus. Aliquam sem. In
            hendrerit
            nulla quam nunc, accumsan congue. Lorem ipsum primis in nibh vel risus. Sed vel lectus. Ut sagittis, ipsum
            dolor quam.Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis.
            Maecenas malesuada elit lectus felis, malesuada ultricies. </p></BlockContent> </Col>
    </Row>
    <Row>
      <Col lg={ 5 }>
        <BlockContent>
          <SecondImgSmale src={ NewsImgContent } />
        </BlockContent>
      </Col>
      <Col lg={ 6 }>
        <BlockContent>
          <p>Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas
            malesuada elit lectus felis, malesuada ultricies. Curabitur et ligula. Ut molestie a, ultricies porta
            urna.
            Vestibulum commodo volutpat a, convallis ac, laoreet enim. Phasellus fermentum in, dolor. Pellentesque
            facilisis. Nulla imperdiet sit amet magna. Vestibulum dapibus, mauris nec malesuada fames ac turpis velit,
            rhoncus eu, luctus et interdum adipiscing wisi. Aliquam erat ac ipsum.</p>

          <p>Integer aliquam purus. Quisque lorem tortor fringilla sed, vestibulum id, eleifend justo vel bibendum
            sapien
            massa ac turpis faucibus orci luctus non, consectetuer lobortis quis, varius in, purus. Integer ultrices
            posuere cubilia Curae, Nulla ipsum dolor lacus.</p>
        </BlockContent>
      </Col>
    </Row>
    <Row>
      <Col lgOffset={ 3 } lg={ 6 }>
        <BlockContent>
          <p>Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas
            malesuada elit lectus felis, malesuada ultricies. Curabitur et ligula. Ut molestie a, ultricies porta
            urna.
            Vestibulum commodo volutpat a, convallis ac, laoreet enim. Phasellus fermentum in, dolor. Pellentesque
            facilisis. Nulla imperdiet sit amet magna. Vestibulum dapibu</p>
        </BlockContent>
      </Col>
    </Row>
    <Row>
      <Col>
        <BlockContent>
          <ImgNews src={ NewsImgLarge } />
        </BlockContent>
      </Col>
    </Row>
    <Row>
      <Col lgOffset={ 3 } lg={ 6 }>
        <BlockContentLastElement>
          <p>Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque dui, non felis. Maecenas
            malesuada elit lectus felis, malesuada ultricies. Curabitur et ligula. Ut molestie a, ultricies porta
            urna. Vestibulum commodo volutpat a, convallis ac, laoreet enim. Phasellus fermentum in, dolor.
            Pellentesque facilisis. Nulla imperdiet sit amet magna. Vestibulum dapibus, mauris nec malesuada fames ac
            turpis velit, rhoncus eu, luctus et interdum adipiscing wisi. Aliquam erat ac ipsum.</p>

          <p>Integer aliquam purus. Quisque lorem tortor fringilla sed, vestibulum id, eleifend justo vel bibendum
            sapien massa ac turpis faucibus orci luctus non, consectetuer lobortis quis, varius in, purus. Integer
            ultrices posuere cubilia Curae, Nulla ipsum dolor lacus, suscipit adipiscing. Cum sociis natoque penatibus
            et ultrices volutpat.</p>

          <p>Nullam wisi ultricies a, gravida vitae, dapibus risus ante sodales lectus blandit eu, tempor diam pede
            cursus vitae, ultricies eu, faucibus quis, porttitor eros cursus lectus, pellentesque eget, bibendum a,
            gravida ullamcorper quam. Nullam viverra consectetuer. Quisque cursus et, porttitor risus. Aliquam sem. In
            hendrerit nulla quam nunc, accumsan congue. Lorem ipsum primis in nibh vel risus. Sed vel lectus. Ut
            sagittis, ipsum dolor quam.Lorem ipsum dolor sit amet enim. Etiam ullamcorper. Suspendisse a pellentesque
            dui, non felis. Maecenas malesuada elit lectus felis, malesuada ultricies. </p>
        </BlockContentLastElement>
      </Col>
    </Row>
    <Row>
      <ShereSocialBlockBottom hrefLinkedIn={ linkToShareLinkedin } hrefFacebook={ linkToShareFacebook }
                              hrefTwitter={ linkToShareTwitter }> </ShereSocialBlockBottom>
    </Row>
  </Container>
    </>;
};
