import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { media } from 'core/theme';

export const MainRow = styled(Row)<{ offerPage?: boolean }>`
    margin: 0;
    padding: 0;
    ${(props) =>
      props.offerPage &&
      `    
    ${media.up('md')} {
      width: calc(100% + 15px);
    }
    `}
    > * {
      margin: calc(1.875rem / 2) 0; // lack of margin collapsing because of use of flexbox with styled-bootstrap-grid
      ${media.down('md')} {
        margin: calc(0.625rem / 2) 0;
      }
    }
  `,
  MainCol = styled(Col)<{ isWide?: boolean }>`
    ${(props) =>
      props.isWide &&
      `
  ${media.down('md')} {
    padding: 0;
  }  
  `}
  `,
  NoDataBox = styled.div`
    width: 100%;
    height: 14.375rem;
    border: solid 1px ${(props) => props.theme.colors.lines};
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.down('sm')} {
      height: 11.25rem;
    }
  `,
  Text = styled.p`
    color: ${(props) => props.theme.colors.lightBlue};
    width: 11rem;
    text-align: center;
    ${media.down('sm')} {
      font-size: 0.875rem;
      width: 10rem;
    }
  `;
