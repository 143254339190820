import { useBreakpoints } from 'features/hooks/useBreakpoints';
import React from 'react';
import { PMISelect } from './PMISelect';
import {
    ArrowIcon,
    DropdownBox,
    Main,
    MainRow,
    Number,
    Numbers,
    OffersNumber,
    OffersNumberBox,
    Pages,
    SelectBox
} from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';

export const Paging = ({ currentPage, offersPerPage, limitPageNumbers, onPageChange, onLimitChange, pageStats, down = false, onPageNumberChange }) => {

    const { from, to, total } = pageStats;
    const getNumberOfPages = Math.ceil(total / offersPerPage.value);
    const generatePageNumbers = (numberOfPages) => numberOfPages > 0 ? Array.from(Array(numberOfPages).keys()).map(x => x + 1) : [];

    const renderPages = () => {
        // do not change the order of conditions, they are related
        const pagesCountToShow = 5
        if (currentPage <= 2 || getNumberOfPages <= pagesCountToShow)
            return pageNumbers.slice(0, getNumberOfPages < pagesCountToShow ? getNumberOfPages : pagesCountToShow);
        if (currentPage + 2 <= getNumberOfPages)
            return [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2]
        return pageNumbers.slice(pageNumbers.length - pagesCountToShow, pageNumbers.length)
    }
    const { isLarge } = useBreakpoints();
    const pageNumbers: number[] = generatePageNumbers(getNumberOfPages);

    return (
      <MainRow>
        <Main line={!down && isLarge} down={down}>
          <OffersNumberBox displayProp={down}>
            <OffersNumber displayProp={!down}>
              {total > 0 ? (
                <>
                  <MultilingualText tKey="offers" area="jobOffers" /> {from}-{to} <MultilingualText tKey="of" area="jobOffers" /> {total}
                </>
              ) : (
                <></>
              )}
            </OffersNumber>
          </OffersNumberBox>
          {(isLarge || down) && pageNumbers.length > 1 && (
            <Pages>
              <ArrowIcon left onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : currentPage)} />
              <Numbers>
                {renderPages().map((number, idx) => (
                  <Number
                    aria-label={`strona ${number}`}
                    key={`number-${idx}`}
                    active={currentPage === number}
                    onClick={() => {
                      onPageNumberChange(number);
                    }}
                  >
                    {number}
                  </Number>
                ))}
              </Numbers>
              <ArrowIcon onClick={() => onPageChange(currentPage < getNumberOfPages ? currentPage + 1 : currentPage)} />
            </Pages>
          )}

          {isLarge && (
            <DropdownBox>
              <MultilingualText tKey="showPage" area="jobOffers" />
              <SelectBox>
                <PMISelect onChange={onLimitChange} value={offersPerPage} options={limitPageNumbers} />
              </SelectBox>
            </DropdownBox>
          )}
        </Main>
      </MainRow>
    );
};

