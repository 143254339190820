import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { useOnScreen, useBreakpoints } from 'features/hooks';
import styled from 'styled-components';

import { Placeholder } from './styles';

const LazyPicture = styled.img<{ sccss: string }>`
    ${props => props.sccss}
`;

export const LazyImage: FunctionComponent<{ alt: string, src: string, height?: string, width?: string, sccss?: any }> =
  ({ alt, src, sccss, height, width }) => {

    const refPlaceholder = useRef<HTMLDivElement>(null);
    const [shownOnce, setShownOnce] = useState(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { isLarge } = useBreakpoints();
    const isVisible = useOnScreen(refPlaceholder, isLarge ? '-300px' : '-30px');


    useEffect(() => {
      if (!isVisible || shownOnce) return;
      setShownOnce(true);
      const imageToLoad = new Image();
      imageToLoad.src = src;
      imageToLoad.onload = () => {
        setIsLoaded(true);
      };
    }, [isVisible])


    return isLoaded ? <LazyPicture sccss={sccss} alt={alt} src={src} /> :
      <Placeholder ref={refPlaceholder}
        height={height ? height : '100%'}
        width={width ? width : '100%'} />
  };