import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import EqualSalaryIcon from 'assets/images/employee-programs/equal-salary-main-logo.png';
import { BanerTop, WrapperHeadBannerDesc, IconEmployeeProgramsPage } from './styles';
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { Row } from 'styled-bootstrap-grid';

export const TopBannerEqualSalary: FunctionComponent = () => {
  return <>
    <BanerTop>
      <Container type="large">

          <WrapperHeadBannerDesc>
            <IconEmployeeProgramsPage iconImg={ EqualSalaryIcon } />
            <h2><MultilingualText tKey="equal-salary-title" area="equal_salary" /></h2>
            <p><MultilingualText tKey="equal-salary-subtitle" area="equal_salary" /></p>
          </WrapperHeadBannerDesc>


      </Container>
    </BanerTop>
</>;
};
