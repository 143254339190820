import { useLocationData } from 'features/hooks/useLocationData';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Row } from 'styled-bootstrap-grid';

import Container from 'view/components/content/container';
import './lightbox.css';
import { BlackRectangle, ColGallery } from './styles';

export const LightboxExample = () => {
  const { gallery } = useLocationData();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = gallery.allImgGallery;

  return (
    <Container type={ 'large' }>
      <Row>

        <ColGallery>
          <img alt='First' src={ gallery.firstImg } onClick={ () => {
            setIsOpen(true);
            setPhotoIndex(0);
          } } />
          <img alt='Second' src={ gallery.secondImg } onClick={ () => {
            setIsOpen(true);
            setPhotoIndex(1);
          } } />
          <BlackRectangle onClick={ () => {
            setIsOpen(true);
          } }>
            <p>+{ images.length }</p>
          </BlackRectangle>

        </ColGallery>

        { isOpen && (
          <Lightbox
            imageTitle={ `${ [photoIndex + 1] } / ${ images.length }` }
            enableZoom={ false }
            mainSrc={ images[photoIndex] }
            nextSrc={ images[(photoIndex + 1) % images.length] }
            prevSrc={ images[(photoIndex + images.length - 1) % images.length] }
            onCloseRequest={ () => setIsOpen(false) }
            onMovePrevRequest={ () =>
              setPhotoIndex(
                (photoIndex + images.length - 1) % images.length,
              )
            }
            onMoveNextRequest={ () =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        ) }
      </Row>
    </Container>
  );
};
