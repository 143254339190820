import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import App from './App';
import { store } from './core/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import englishText from 'translations/en';
import polishText from 'translations/pl';
import { setupAxiosInterceptors } from 'core/axios/axios-interceptor'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pl',
  keySeparator: ".",
  resources: {
    en: englishText,
    pl: polishText
  },
});

setupAxiosInterceptors();
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Helmet>
        <title>Philip Morris in Poland</title>
      </Helmet>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
