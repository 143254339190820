import React, { FunctionComponent } from 'react';
import Container from 'view/components/content/container';
import { Col, Row } from 'styled-bootstrap-grid'
import MultilingualText from 'view/components/content/MultilingualText/MultilingualText';
import { RecruitmentStages } from './recruitmentStages';
import RecruitmentStage from './RecruitmentStage';
import { Header } from './styles';

export const RecruitmentProcess: FunctionComponent = () => {
  return <Container>
    <Col col={12}>
      <Row>
        <Header><MultilingualText tKey="recruitmentProcess.header" area="recruitment" /></Header>
      </Row>
      {RecruitmentStages.map((x, idx) =>
        <RecruitmentStage key={x.key} id={x.key} stage={x} isLast={RecruitmentStages.length - 1 === idx} />
      )}
    </Col>
  </Container>;

};
