export const krakowFactory = {
  krakowFactory :{
    event: 'virtualPageView',
    virtualPageView: '/working-places/krakow-factory',
  },
  krakowFactoryClickMap : {
    event: 'backendMicroconversion',
    category: 'Clicks - map',
  },
  krakowFactoryClickOffer: {
    event: 'backendMicroconversion',
    category: 'Clicks - button',
  },
  krakowFactoryClickMovieOpen: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie enters',
  },
  krakowFactoryClickMoviePlay: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie plays',
  },
  krakowFactoryClickMovieExits: {
    event: 'backendMicroconversion',
    category: 'Clicks - movie exits',
  },
  krakowFactoryClickSlider: {
    event: 'backendMicroconversion',
    category: 'Clicks - slider',
  },
  krakowFactoryLinkToAnother: {
    event: 'backendMicroconversion',
    category: 'Clicks - links',
  },
}