import styled from 'styled-components';
import { media } from '../../../../core/theme';
import { MainTextEqualSalary } from './MainTextEqualSalary';

export const ImageBox = styled.div`

  height: auto;
  width: 845px;
  margin-top: -53.2rem;
  margin-left: 43rem;

  ${ media.down('md') } {
    margin-top: 50px;
    margin-left: 0;
  }

  ${ media.down('sm') } {
    margin-left: -30px;
    width: 100vw;
  }
`;
export const WrapperDescriptionSalary = styled.div`
  margin-top: 40px;
  max-width: 570px;

  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  li::before {
    content: '\\2022';
    color: ${ (props) => props.theme.colors.blueButtonBackground };
    font-weight: bold;
    display: inline-block;
    width: 2.3rem;
    margin-left: -2.3rem;
  }

  h2 {
    letter-spacing: -0.3px;
    margin-bottom: 20px;
    margin-top: 40px;
    line-height: normal;
    font-weight: normal;
  }

  h3 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  p {
    margin: 20px 0;
  }

  ${ media.up('md') } {
    margin-top: 150px;

    h2 {
      letter-spacing: -0.46px;
      font-weight: 300;
      margin-top: 100px;
      margin-bottom: 50px;
    }
    h3 {
      margin: 50px 0;
    }
    p {
      margin: 30px 0;
    }
    ul {
      margin-bottom: 55px;
    }

  }
`;

export const ColorLine = styled.div`
  width: 10px;
  height: auto;
  background-color: #1c9fd5;
  margin-right: 20px;

  ${ media.up('lg') } {
    margin-right: 40px;

  }

`;
export const WrapperColorLineText = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 446px;
  margin: 20px 0;
  h3 {
    margin: 0;
  }
  ${ media.up('lg') } {
    margin: 50px 0;
  }
    
`;
