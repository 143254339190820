import styled from 'styled-components';
import { media } from 'core/theme/media';
export const Main = styled.div<ISliderMain>`
  border: solid 1px ${(props) => props.theme.colors.lines};
  &:focus {
    border: none;
    outline: none;
  }

  .dots {
    display: none !important;
  }

  .slick-slide,
  .slick-slide * {
    outline: none !important;
  }

  /* .slick-next {
    ${media.down('sm')} {
      display: none !important;
    }
  } */
  ${media.down('sm')} {
    ${(props) => props.borderTop === false && `border-top: 0;`}
    ${(props) => props.borderBottom === false && `border-bottom: 0;`}
  }
  ${media.down('md')} {
    width: 100vw;
  }
`;
