import styled from 'styled-components';
import { media } from '../../../../../core/theme';
import { DetailsLink } from '../../../whereWorking/components/styles';
import ArrowRightWhite from '../../../../../assets/images/whereWorking/arrow-right-white.svg';
import ArrowRightWhiteHover from '../../../../../assets/images/whereWorking/arrow-right-white-hover.svg';

export const ContainerBannerHome = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;

  ${ media.up('md') } {
    flex-direction: row;
  }
`;
export const WrapperItemBaner = styled.div <{ backgroudImg: string }>`
  background-image: url(${ props => props.backgroudImg });
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  max-width: 355px;
  min-height: 160px;
  &:last-child {
    align-items: flex-start;
  }

  ${ media.up('lg') } {
    max-width: 740px;
    height: 601px;
  }

`;
export const ItemContentBaner = styled.div<{ color: string }>`
  background: ${ props => props.color };
  color: white !important;
  width: 100%;
  height: 100%;
  padding: 18px 20px 25px;
  position: relative;

  a {
    color: white !important;
  }

  .title {
    font-size: 14px;
    line-height: 1.57;
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .description {
    max-width: 266px;
    font-size: 24px;
    letter-spacing: -0.3px;
    margin-top: 0;
    margin-bottom: 4px;
  }

  ${ media.up('lg') } {
    max-width: 585px;
    max-height: 330px;
    padding: 50px 50px 49px 60px;

    .title {
      line-height: 1.56;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .description {
      font-size: 37px;
      line-height: 1.35;
      letter-spacing: -0.46px;
      margin: 0;
      max-width: 446px;
      min-height: 100px;
    }
  }

`;
export const IconEmployeePrograms = styled.div<{ iconImg: string }>`
  background-image: url(${ props => props.iconImg });
  background-repeat: no-repeat;
  background-size: contain;
  width: 84px;
  height: 30px;
  position: absolute;
  right: 20px;
  bottom: 26px;
  background-position-x: right;

  ${ media.up('lg') } {
    width: 100px;
    height: 50px;
    right: 50px;
    bottom: 50px;
  }
`;
export const DetailsLinkEmployeePrograms = styled(DetailsLink)`

  &:after {

    background: url("${ ArrowRightWhite }") no-repeat;
    background-size: 1rem;
  }

  &:hover {
    &:after {
      background: url("${ ArrowRightWhiteHover }") no-repeat;
      background-size: 1rem;

    }
  }

  &:active, &:visited, &:link {
    color: ${ props => props.theme.colors.lightText };
    margin-left: 0;
  }

  ${ media.up('lg') } {
    margin-top: 23px;
  }
`;
