import styled from 'styled-components';
import { Row } from 'styled-bootstrap-grid';
import { IVideoProps } from './interfaces.d';
import { media } from 'core/theme/media';

export const ContentRow = styled(Row)`
    margin-bottom: 4.125rem;
    ${media.up('lg')} {
      margin-bottom: 6.25rem;
    }
  `,
  Title = styled.h2`
    margin-bottom: 1.875rem !important;

    ${media.down('md')} {
      margin-bottom: 1.813rem !important;
      white-space: normal;
    }
  `,
  Description = styled.div`
    font-size: 1rem;
    padding-bottom: 2rem;

    ${media.down('md')} {
      margin-bottom: 1.813rem;
      padding-bottom: 0;
    }
  `,
  LocationBox = styled.div`
    display: flex;
  `,
  LocationDecoration = styled.div<IVideoProps>`
    width: 0.313rem;
    height: auto;
    background-color: ${(props) => props.theme.colors.location[props.decorationColor]};
    margin-right: 1.1rem;
  `,
  Video = styled.div<IVideoProps>`
    width: 100%;
    height: 20.063rem;
    background-size: cover;
    background-image: url('${(props) => props.backgroundImage}');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border-left: 0.938rem solid ${(props) => props.theme.colors.location[props.decorationColor]};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.875rem;

    ${(props) =>
      !props.isLarge &&
      `
      height: 11.063rem;
    `}
  `,
  WatchItBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  PlayButton = styled.img`
    transition: 0.3s;
    height: 5rem;
    width: auto;
    ${media.down('md')} {
      height: 2.5rem;
    }
    ${Video}:hover & {
      opacity: 0.3;
    }
  `,
  ActionText = styled.div`
    color: ${(props) => props.theme.colors.lightText};
    margin-top: 1rem;
    transition: 0.3s;
    ${Video}:hover & {
      opacity: 0.3;
    }
  `;
