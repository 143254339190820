import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { bootstrapAPI } from './';
import { BootstrapState } from './interfaces';

import { RootState } from 'core/store/store';

const initialState: BootstrapState = {
  loading: false,
  error: null,
  data: {},
};

export const fetchBootstrap = createAsyncThunk('bootstrap/fetch', async (lang: string) => {
  return await bootstrapAPI.fetch(lang);
});

const bootstrap = createSlice({
  name: 'bootstrap',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBootstrap.fulfilled.toString()]: (state, action) => {
      state.data = action.payload.data.data;
      state.loading = false;
      state.error = null;
    },
    [fetchBootstrap.pending.toString()]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBootstrap.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default bootstrap.reducer;

export const bootstrapSelect = (state: RootState) => state.bootstrap;
