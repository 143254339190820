import styled from 'styled-components';
import bannerDesktop from 'assets/images/contact/contact-background-img.jpg';
import bannerPhone from 'assets/images/contact/banner-phone-contakt.jpg';
import MapImg from 'assets/images/contact/mini-map.png';
import AddIcon from 'assets/images/contact/c-add.svg';
import AddIconBlue from 'assets/images/contact/c-add2.svg'
import PhoneBlue from 'assets/images/contact/phone-blue.svg';
import CloseButtonIconDesktop from 'assets/images/contact/close-button.svg';
import CloseButtonIconPhone from 'assets/images/contact/close-phone.svg';
import { media, Theme } from 'core/theme';

export const WrapperHead = styled.div`

  background-image: url(${bannerDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  height: 43.75rem;
  display: flex;
  flex-direction: column-reverse;
 
 a {
     text-decoration: unset;
     color: inherit;
 }

  ${media.down('md')} {
  border-top: 0.313rem solid ${Theme.colors.location.blue};
   background-image: url(${bannerPhone});
    height: 27.5rem;
  }

`;
export const Icon = styled.svg`
  padding-right: 1.875rem;
  width: 3.5rem;
  height: 1.5rem;
  object-fit: contain;

  ${media.down('md')} {
    width: 2.3rem;
    height: 1rem;
    padding-right: 1.25rem;
  }
`;
export const Title = styled.h1`
  color: ${props => props.theme.colors.lightText};
  font-size: 3.125rem;

  font-weight: 300;

  ${media.down('md')} {
    
    font-size: 1.875rem;
  }
`;
export const ConteinerDescription = styled.div`
  color: ${props => props.theme.colors.lightText};
`;


export const IconInfo = styled.svg`
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 1.5rem;
  object-fit: contain;
  padding-right: 0.938rem;
`;

export const InfoAdresUl = styled.ul`
  list-style-type: none;
  white-space: pre-line;
  margin: 0;

  ${media.up('lg')} {
    padding: 0 0 0 20px;
  }
   
  ${media.up('sxl')} {
    padding: 0;
  }

  li {
    font-size: 1.5rem;
  }

  ${media.down('md')} {
    padding: 0;

    li {
      font-size: 0.8rem;
    }
  }

  a {
    text-decoration: unset;
    color: inherit;
  }
`;
export const ContactInfoUl = styled(InfoAdresUl)`
  text-align: end;

  li {
    margin-bottom: 0.875rem;
  }

  ${media.down('md')} {
    text-align: unset;
    padding-top: 0.625rem;
  }

`;
export const ButtonClosedesktop = styled.div`
  background-image: url("${CloseButtonIconDesktop}");
  width: 6.875rem;
  height: 6.875rem;
  object-fit: contain;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  
  ${media.down('md')} {
    margin-top: 2.688rem;
    width: 3.75rem;
    height: 3.75rem;
    background-image: url("${CloseButtonIconPhone}");
  }
`;
export const IconMap = styled.svg`
  background-image: url("${AddIcon}");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: contain;

  ${media.down('md')} {
    background-size: 1.5rem;
  }
`;
export const ButtonOpenModal = styled.div`
  cursor: pointer;
  width: 5.625rem;
  height: 5.625rem;
  background-image: url("${MapImg}");
  border-radius: 10px;
  transition: 0.3s;
  ${media.down('md')} {
    background-size: 3.75rem;
    width: 3.75rem;
    height: 3.75rem;
  }
  &:hover ${IconMap} {
    background-image: url("${AddIconBlue}");
  }

`;


export const PhoneIconBlue = styled.svg`
  background-image: url("${PhoneBlue}");
  width: 2.4rem;
  height: 1.5rem;
  background-size: 1.5rem;
  object-fit: contain;
  background-repeat: no-repeat;
  padding-right: 0.875rem;

  ${media.down('md')} {
    width: 2rem;
    height: 1rem;
    background-size: 1rem;
    padding-right: 0.813rem;
  }
`;

export const IframeMap = styled.iframe`
  max-width: 65.313rem;
  width: 100%;
  height: 99vh;
  object-fit: contain;
  border: none;

  ${media.down('md')} {
    height: 78vh;
  }
`;
export const ContentMap = styled.div`
 ${media.up('lg')} {
  padding-right: 2rem;
  padding-left: 4rem;
  padding-top: 2rem;
 }

${media.up('sxl')} {

 padding-right: 9.438rem;
  padding-top: 12.5rem;
}

  ${media.down('md')} {

    padding-left: 1.875rem;
    padding-top: 1.875rem;
  }

`;
export const FormMap = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${media.down('md')} {
    flex-direction: column-reverse;
  }
`;
export const UlMapSocial = styled(InfoAdresUl)`
  margin-top: 1.875rem;
`;

export const BannerWrapper = styled.div`
  margin-top: 6.25rem;
  padding-bottom: 6.25rem;
  border-bottom: 0.063rem solid ${props => props.theme.colors.linesLight};
  ${media.down('md')} {
    margin-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

export const TitleDepartment = styled.h2`
  margin-bottom: 3.125rem;
  margin-top: 0;

  ${media.down('md')} {
    margin-bottom: 1.5rem;
  }
`;
