import styled from 'styled-components';
import { IArrow } from 'view/pages/recruitment/interfaces';

export const QuestionBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 0.063rem solid ${(props) => props.theme.colors.lines};
    cursor: pointer;
  `,
  Question = styled.h3`
    width: 89%;
    font-weight: 400;
    padding: 1.563rem 0;
  `,
  Arrow = styled.img<IArrow>`
    transition: transform 0.3s;
    ${(props) =>
      props.up &&
      `
transform: rotate(180deg);
`}
  `,
  Answer = styled.p`
    white-space: break-spaces;
    width: 90%;
    max-width: 61.25rem;
    padding-bottom: 2.5rem;
  `;
