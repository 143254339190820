import styled from 'styled-components';
import MakeHistoryIcon from 'assets/images/home/make-history.svg'
import CloseButtonIconDesktop from 'assets/images/contact/close-button.svg';
import CloseButtonIconPhone from 'assets/images/contact/close-phone.svg';
import PhoneIconWhite from 'assets/images/contact/phone-svg.svg';
import MailIconWhite from 'assets/images/contact/mail-icon.svg';
import LinkedinIconWhite from 'assets/images/contact/logo-linkedin.svg';
import LogoImage from 'assets/images/home/logo.svg';
import { media } from 'core/theme';
import CulturePhoto3 from 'assets/images/home/culturePhoto3.jpg';
import CulturePhotoMd from 'assets/images/home/culturePhoto3Mobile.jpg';
import quote from 'assets/images/home/quote.svg';
import moreAboutLocalIcon from 'assets/images/home/frame-c-arrow-right.png';
import moreAboutUsIcon from 'assets/images/home/frame-c-arrow-right-us.svg';
import moreAboutUsIcon2 from 'assets/images/home/frame-c-arrow-right-us2.svg';
import arrowBlue from 'assets/images/home/frame-c-arrow-right-blue.svg';

import Container from 'view/components/content/container';

import { Col, Row } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
export const WrapperHeadColor = styled.div`
  height: 140.4rem;
  background-color: ${props => props.theme.colors.darkText};
  overflow: hidden;
  ${media.down('sm')} {
    height: auto;
  }
  @media (max-width: 370px) {
    height: 80rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    height: 93.35rem;
  
  }
`;
export const WrapperHead = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 119.75rem;
  display: flex;
  flex-direction: column;
  ${media.down('sm')} {
    height: 27.5rem;
  }

`;
export const GradientImage = styled.div`
  position: absolute;
  top: 36.625rem;
  width: 100%;
  height: 80.625rem;
  background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0), ${props => props.theme.colors.darkText});
  ${media.down('sm')} {
    top: -7rem;
    height: 39rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 62%, ${props => props.theme.colors.darkText});
  }
  @media (min-width: 900px) and (max-width: 1400px) { 
    top: 17.625rem;
  }
  @media (min-width: 1280px) and (max-width: 1400px) { 
    top: 13.625rem;
  }
  
  
`
export const WrapperHeadImage = styled.div<{ backgroundPhoto: string }>`
  background-image: url(${(props) => props.backgroundPhoto});
  background-repeat: no-repeat;
  background-size: cover;
  height: 86.25rem;
  ${media.down('sm')} {
    height: 102.25rem;
    background-size: 40rem;
    background-position: -8rem 0rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    background-size: 89rem;
    background-position: -9rem 0rem;
  }
  @media (min-width: 1280px) and (max-width: 1400px) {
    background-size: 92rem;
    height: 54rem;
  }
`;
export const MakeHistory = styled.div`
  background-image: url(${MakeHistoryIcon});
  background-size: cover;
  margin-top: 16.625rem;
  height: 2rem;
  width: 18.5rem;
  background-repeat: no-repeat;
  position: relative;
  float: right;
  margin-bottom: 9.4rem;
  ${media.down('sm')} {
    width: 9.375rem;
    height: 1rem;
    background-size: 9.375rem;
    margin-top: 1.938rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: 9.875rem;
  }
`;
export const ContainerBigLeft = styled.div`
  margin-top: 16.875rem;
  font-family: Poppins;
  font-size: 3.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: -1.25px;
  ${media.down('sm')} {
    margin-top: 9.125rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: 12.938rem;
  }
  &:last-child {
    margin-top: 0;
  }
`;
export const Icon = styled.svg`
  padding-right: 1.875rem;
  width: 3.5rem;
  height: 1.5rem;
  object-fit: contain;

  ${media.down('sm')} {
    width: 2.3rem;
    height: 1rem;
    padding-right: 1.25rem;
  }
`;
export const IconWhitePhone = styled(Icon)`
  background-image: url(${PhoneIconWhite});
  background-repeat: no-repeat;
  background-size: 1.5rem;

  ${media.down('sm')} {
    background-size: 1rem;
  }
`;
export const IconWhiteMail = styled(IconWhitePhone)`
  background-image: url("${MailIconWhite}");
`
export const IconWhiteLinkedin = styled(IconWhitePhone)`
  background-image: url("${LinkedinIconWhite}");
`
export const ConteinerContact = styled.div`
  padding-bottom: 3.5rem;
  background-color: ${props => props.theme.colors.blueButtonBackground};

  ${media.down('sm')} {
    padding-bottom: 0.9685rem;
  }
`;
export const Title = styled.h1`
  color: ${props => props.theme.colors.lightText};
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 1.3;
  margin: 0 0 0;

  ${media.down('sm')} {
    font-size: 1.875rem;
    line-height: 1.27;
    letter-spacing: -0.75px;
 
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    font-size: 2.5rem;
  }
`;

export const Title2 = styled.h2`
  color: ${props => props.theme.colors.lightText};
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0 0 1.275rem;
  letter-spacing: -1.25px;
  ${media.down('sm')} {
    font-size: 1.875rem;
    line-height: 1.27;
    letter-spacing: -0.75px;
  }
  @media (min-width: 900px) and (max-width: 1280px) {
    font-size: 2.5rem;
  }
`;
export const DescriptionTitle = styled.div`
  white-space: pre-line;
  width: 100%;
  line-height: 1.5;
  font-size: 1.5rem;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.3px;
  ${media.down('sm')} {
    font-size: 1.188rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    font-size: 1.313rem;
  }
  
`;
export const BlackTextRow = styled(Row)`
  margin-top: 9.500rem;
  ${media.down('sm')} {
    margin-top: 3.75rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    font-size: 9.375rem;
  }

`;
export const Logo = styled.div`
  background-image: url("${LogoImage}");
  width: 15.375rem;
  height: 11.25rem;
  
`;
export const TextNextToLogo = styled.div`
  width: 44rem;
  height: 10.938rem;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.3px;
  color: ${props => props.theme.colors.lightText};
  ${media.down('sm')} {
    height: auto;
    width: 100%;
    font-size: 1.188rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    font-size: 1.313rem;
  }
`;
export const MoreAboutUsIcon = styled.div`
  transition: 0.3s;
  background-image: url(${moreAboutUsIcon});
  width: 1.5rem;
  height: 1.55rem;
  margin-left: 0.625rem;

`;
export const MoreAboutUs = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  margin-top: 1.375rem;
  margin-right: 0.625rem;
  margin-bottom: 9.050rem;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.lightText};
  display: flex;
  align-items: center;
  ${media.down('sm')} {
    margin-bottom: 3.375rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: -0.625rem;
    font-size: 1.313rem;
    margin-bottom: 9.438rem;
  }

&:hover ${MoreAboutUsIcon}{
  background-image: url(${moreAboutUsIcon2});
}
`;
export const RectangleSeparator = styled.div`
  width: 0.063rem;
  height: 9.375rem;
  background-color: ${props => props.theme.colors.lines};
`;
export const ItemGroup = styled.div`
  display: flex;
`;
export const ItemText = styled.div`
  white-space: pre-line;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.3px;
  color: ${props => props.theme.colors.lightText};
  margin-left: 3.063rem;
  margin-bottom: 11.563rem;
  ${media.down('sm')} {
    font-size: 1.188rem;
    letter-spacing: -0.24px;
    color: ${props => props.theme.colors.lightText};
    margin-bottom: 3.125rem;
    width: 14.25rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-bottom: 3.125rem;
  }
`;
export const WrapperMapBackground = styled.div<IMainBanner>`
  overflow: hidden;
  background-image: url(${(props) => props.backgroundBanner});
  position: absolute;
  background-repeat: no-repeat;
  width: 111.25rem;
  height: 133.813rem;
  padding-left: 8.75rem;
  ${media.down('sm')} {
    display: none;
  }
  @media (max-width: 1798px) {
    width: 100%;
    left: -7rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    background-size: 95rem;
    left: -11rem;
  }

`;
export const OfficeBox = styled(Col)`
  margin-top: 16.125rem;
  margin-bottom: 18.75rem;
  ${media.down('sm')} {
    margin-top: 2.438rem;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3.75rem;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }
`;
export const CultureTitle1 = styled.div`
  font-size: 2.313rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.46px;
  color: ${props => props.theme.colors.darkText};
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
  ${media.down('sm')} {
    font-size: 1.5rem;
    letter-spacing: -0.3px;
    color: ${props => props.theme.colors.darkText};
    margin-bottom: 1.25rem;
  }
`;
export const CultureContent1 = styled.div`
  width: 18.375rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${props => props.theme.colors.darkText};
  ${media.down('sm')} {
    display: none;
  }
`;

export const MapTitle = styled.h1`
  margin-top: 16.125rem;
  font-size: 2.313rem;
  font-weight: 300;
  line-height: 1.35;
  letter-spacing: -0.46px;
  margin-bottom: 1.25rem;
  ${media.down('sm')} {
    margin-top: 3.75rem;
    font-size: 1.5rem;
    letter-spacing: -0.3px;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: 9.75rem;
  }
`
export const MapMobile = styled.div<IMainBanner>`
   ${media.up('sm')} {
    display: none;
  }
  background-image: url(${(props) => props.backgroundBanner});
  width: 100%;
  height: 19rem;
  background-repeat: no-repeat;
  margin-bottom: 2.438rem;
  margin-top: 2.5rem;
`;
export const MapDescription = styled.div`
  font-size: 1rem;
  font-style: normal;
  line-height: 1.375rem;
  letter-spacing: normal;
  margin-bottom: 1.75rem;
  width: 17.313rem;
  ${media.down('sm')} {
    line-height: 1.375rem;
    font-size: 0.875rem;
    width: 19.313rem;
  }
`;

export const MoreAboutLocal = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.darkText};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const MoreAboutLocalIcon = styled.div`
  background-image: url(${moreAboutLocalIcon});
  width: 0.875rem;
  height: 0.875rem;
  background-size: 0.875rem 0.875rem;
  margin-left: 0.313rem;
  ${MoreAboutLocal}:hover & {
    background-image: url(${arrowBlue});
  }
`;
export const StickPhoto = styled.div`
  background-image: url(${CulturePhoto3});
  position: absolute;
  top: 1.4375rem;
  width: calc(50vw - 615px + 100%);
  height: 67.5rem;
  background-position-x: right;
    background-size: cover;
  ${media.down('sm')} {
    position: relative;
    display:none;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    top: 0;
    background-image: url(${CulturePhotoMd});
    width: 53.125rem;
    height: 39.125rem;
    background-size: cover;
  }
`;
export const Quote = styled.div`
  margin-left: 5rem;
  margin-top: 12.5rem;
  background-image: url(${quote});
  background-position: center center;
  background-size: 3.75rem 3.5rem;
  width: 3.75rem;
  height: 3.5rem;
  ${media.down('sm')} {
    background-size: 1.875rem 1.75rem;
    width: 1.875rem;
    height: 1.75rem;
    margin-top: 0;
    margin-left: 0;
  }
  @media (min-width: 900px) and (max-width: 1280px) { 
    margin-top: 9.375rem;
  }
`
export const StickText = styled.div`
  width: 19.75rem;
  height: 15.313rem;
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.28px;
  color: ${props => props.theme.colors.lightText};
  margin-left: 5rem;
  margin-top: 2.5rem;
  margin-bottom: 1.625rem;
  white-space: pre-wrap;
  ${media.down('sm')} {
    height: 8.75rem;
    margin-top: 1.25rem;
    margin-left: 0;
    font-size: 0.875rem;
    letter-spacing: -0.16px;
    width: 11.063rem;
    margin-bottom: 0; 
  }
`;
export const Andrzej = styled.div`
  margin-left: 5rem;
  width: 5.438rem;
  height: 1.25rem;
  font-family: 'HeyAliceMono';
  font-size: 1.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  color: ${props => props.theme.colors.lightText};
  ${media.down('sm')} {
    margin-left: 0;
    font-size: 1.188rem;
    letter-spacing: normal;
  }
`;
export const StickText2 = styled.div`
  width: 10.938rem;
  height: 1.438rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.lightText};
  margin-left: 5rem;
  margin-top: 0.875rem;
  ${media.down('sm')} {
    margin-top: 0.188rem;
    margin-left: 0;
    font-size: 0.688rem;
    letter-spacing: normal;
  }

`
export const ConteinerDescription = styled.div`
  color: ${props => props.theme.colors.lightText};
`;
export const ListContact = styled.ul`
  max-width: 44.063rem;
  width: 100%;
  font-size: 1.5rem;
  color: ${props => props.theme.colors.lightText};
  list-style-type: none;
  margin: 0;
  padding-left: 3.125rem;

  ${media.down('md')} {
    font-size: 0.875rem;
    padding-left: 1.438rem;
  }
`;


export const InfoAdresUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    font-size: 1.5rem;
  }

  ${media.down('md')} {
    li {
      font-size: 0.875rem;
    }
  }

`;

export const ButtonClosedesktop = styled.div`
  background-image: url("${CloseButtonIconDesktop}");
  width: 6.875rem;
  height: 6.875rem;
  object-fit: contain;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  
  ${media.down('md')} {
    margin-top: 2.688rem;
    width: 3.75rem;
    height: 3.75rem;
    background-image: url("${CloseButtonIconPhone}");
  }
`;

export const LeftColNormalCulture = styled(Col)`
  ${media.down('sm')} {
    padding-left: 0;
    padding-right: 0.3125rem;
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    height: 23.438rem;
    display: flex;
    align-items: flex-end;
  }
`;
export const LeftColNormalHidden = styled(Col)`
  @media (min-width: 800px) and (max-width: 1280px) { 
    display: none
  }
  ${media.down('sm')} {
    padding-left: 0;
    padding-right: 0.3125rem;
  }

`

export const RightColNormalCulture = styled(Col)`

  ${media.down('sm')} {
    padding-left: 0.3125rem;
    padding-right: 0.7125rem;
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    height: 23.438rem;
    display: flex;
    align-items: flex-end;
  }
`;
export const StickFullWidthMobile = styled.div<{ image: string }>`
  display: none;
  ${media.down('sm')} {
    display: block;
    background-size: cover;
    width: 100%;
    height: 21.25rem;
    background-image: url(${(props) => props.image});
    padding-left: 1.875rem;
    padding-top: 3.125rem;
  }
`;
export const RowMarginCulture = styled(Row)`
  margin-top: 1.875rem;
  margin-bottom: 18.75rem;
  ${media.down('sm')} {
    margin-bottom: 0.625rem;
  }
 @media (min-width: 1000px) and (max-width: 1280px) {
   margin-top: 1.25rem;
 }

`;
export const CultureContentMobile = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${props => props.theme.colors.darkText};
  margin-bottom: 3.75rem;
`
export const ContentBox = styled.div`
  ${media.down("sm")}{
    width: 100%;
  }
`;
export const ContainerFull = styled(Container)`

${media.down('md')} {
  padding: 0;
  margin: 0;
  width: 100vw;
}
  ${media.down("sm")} {
    padding:0;
  }
`;
export const Main = styled.div`
  overflow-x: hidden;
  white-space: break-spaces;
`;
export const ContainerHead = styled(Container)`
  @media (min-width: 1000px) and (max-width: 1280px) { 
    max-width: 60rem;
    margin: 0 auto;
  }
  ${Col}.displayNone1280{
    @media (min-width: 1000px) and (max-width: 1280px) {
      display: none;

    }
  }
  ${Col}.displayNone1280UP{
    @media (min-width: 1281px) {
      display: none;
    }
    ${media.down('sm')} { 
      display: none;
      padding: 0 15px;
    }
  }
`
export const HeaderCol = styled(Col)`
 ${media.down('sm')} {
  padding: 0;
}
`
export const WrapperSlider = styled.div`
${media.up("md")} {
  margin-bottom: 9.375rem;
}
`
