import React, { FunctionComponent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import TagManager from 'react-gtm-module';

import { languageSelect, changeLanguage } from 'features/language/';
import { fetchBootstrap } from 'features/bootstrap';
import { Theme, GridTheme } from './core/theme';
import { RenderRoutes } from './core/router/render-routes';
import { useLocation } from 'react-router-dom';
import { BaseLayout } from './view/layout';
import GlobalStyle from './misc/global-style';

const App: FunctionComponent = () => {
  TagManager.initialize({
    gtmId: 'GTM-K6NZG4J',
    dataLayer: '',
  });
  const { pathname } = useLocation();

  const [entryLocationSent, setEntryLocationSent] = useState(false);
  const { i18n } = useTranslation();
  const { lang } = useSelector(languageSelect);
  const dispatch = useDispatch();

  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'entryLocationSet', entryLocation: document.location.href } });
    setEntryLocationSent(true);
  }, []);

  useEffect(() => {
    const langFromUrl = pathname.startsWith('/en') ? 'en' : 'pl';
    if (langFromUrl !== lang) {
      const action = changeLanguage(langFromUrl);
      dispatch(action);
      i18n.changeLanguage(langFromUrl);
    }
  }, [pathname, lang, dispatch, i18n]);

  useEffect(() => {
    dispatch(fetchBootstrap(lang));
  }, [dispatch, lang]);

  if (!window.location.host.startsWith('www')) {
    const newUrl = `${window.location.protocol}//www.${window.location.host}${window.location.pathname}`;
    // @ts-ignore: Unreachable code erro
    window.location = newUrl;
  }

  return (
    <ThemeProvider theme={Theme}>
      <GridThemeProvider gridTheme={GridTheme}>
        <Fragment>
          <GlobalStyle />
          {entryLocationSent && (
            <BaseLayout>
              <RenderRoutes />
            </BaseLayout>
          )}
        </Fragment>
      </GridThemeProvider>
    </ThemeProvider>
  );
};

export default App;
