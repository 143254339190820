import styled from 'styled-components';
import { media } from 'core/theme/media';

export const Main = styled.div`
    padding: 9.375rem 0;

    ${media.down('sm')} {
      padding: 3.75rem 0;
    }
  `,
  Header = styled.h2`
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.46px;
    color: #000000;
    padding-bottom: 3.625rem;
  `;
