import styled from 'styled-components';
import { media } from 'core/theme';
import { DivDescription } from './components/MainDescription/styles';
import { DetailsLink } from '../whereWorking/components/styles';
import Container from 'view/components/content/container';
import { IBackgroundImage } from './interface';

export const WrapperHeadLocalization = styled.div<IBackgroundImage>`
  background-image: url(${(props) => props.BackgroundPhone});

  //to do props
  height: 27.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;

  ${media.up('md')} {
    background-image: url(${(props) => props.BackgroundDesktop});
    height: 43.75rem;
  }
`;
export const DepartmentTitle = styled.div`
  color: ${(props) => props.theme.colors.lightText};

  h1 {
    letter-spacing: -0.75px;
    line-height: normal;
  }

  padding-bottom: 3rem;
  ${media.up('md')} {
    h1 {
      letter-spacing: -0.62px;
    }
    padding-bottom: 2rem;
  }
`;
export const Divisions = styled(DivDescription)`
  max-width: unset;
  width: 100%;

  h3 {
    line-height: normal;
    letter-spacing: normal;
    font-size: 0.875rem;
    font-weight: 400;
  }

  ul {
    padding-left: 0;
    margin-bottom: 1.375rem;
  }

  li {
    &:before {
      width: 1rem;
    }
  }

  li::before {
    margin-left: 0;
  }

  ${media.up('lg')} {
    width: 57%;

    h3 {
      font-size: 1.5rem;
      letter-spacing: -0.3px;
    }

    ul {
      columns: 2;
      margin-bottom: 3.125rem;
    }

    li {
      letter-spacing: -0.2px;
      margin-right: 3.438rem;
      width: 19.5rem;
      margin-bottom: 0.625rem;
      padding-left: 1rem;

      &::before {
        margin-left: -1rem;
      }
    }
  }
`;
export const MoreAbout = styled(DetailsLink)`
  margin: 0;
  display: none;

  ${media.up('md')} {
    display: flex;
  }
`;
export const MoreAboutPhone = styled(DetailsLink)`
  margin: 0 0 1.813rem;

  ${media.up('md')} {
    display: none;
  }
`;

export const ContainerMap = styled(Container)`
  margin-bottom: 6.25rem;

  ${media.down('md')} {
    padding-left: 0;
    margin-bottom: 1.875rem;
    padding-right: 0.93rem;
  }

  ${media.down('sm')} {
    padding-left: 1.875rem;
  }
`;
export const TitleOfWorkingTeams = styled.h2`
  text-align: center;
  margin-top: 3.75rem;
  margin-bottom: 2.375rem;
  letter-spacing: normal;

  ${media.up('md')} {
    margin-top: 9.375rem;
    margin-bottom: 7.187rem;
  }
`;
export const NameOfTeam = styled.div`
  ${media.up('lg')} {
    width: 43%;
  }
`;
export const WrapperDivisions = styled.div`
    border-top: 0.063rem solid ${(props) => props.theme.colors.linesLight};

    h2 {
      margin-top: 1.5rem;
      margin-bottom: 0.625rem;
    }

    ${media.up('lg')} {
      display: flex;
      flex-direction: row;
      padding-top: 1.625rem;

      h2 {
        margin-top: 1.5rem;
        margin-bottom: 0.813rem;
      }
    }
  `,
  BannerBox = styled.div`
    margin: 3rem 0;
  `;
