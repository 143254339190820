import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from 'core/theme/media';
import arrowRight from 'assets/images/icons/arrow-right-white.svg';
import arrowRightHover from 'assets/images/icons/arrow-right-white-hover.svg';

export const Main = styled.div`
width: 100%;
height: 14.375rem;
position: relative;
border: solid 1px ${props => props.theme.colors.lines};
display: flex;
justify-content: space-between;
flex-direction: column;
padding: 1.875rem;

${media.down('md')}{
  height: auto;
}
`,
  TextBox = styled.div`
  display: flex;
  flex-direction: column;
`,
  StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.lightText};
  font-family: ${(props) => props.theme.font};
  text-decoration: none;
`,
  Triangle = styled.div`
  background-color: white;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: left top;
  transform: rotate(45deg);
  width: 40px;
`,
  Ribbon = styled(({ className, children }) => (<div className={className}><Triangle />{children}</div>))`
  color: white;
  font-size: 0.875rem;
  letter-spacing: -0.58px;
  font-weight: 500;
  background-color: #ef7d00;
  padding: 0.1rem 0.37rem 0.1rem 2rem;
  position: absolute;
  right: 0;
  top: 0;
  text-transform: uppercase;
`,
  NewOfferRibbon = styled(Ribbon)`
   background-color: ${props => props.theme.colors.offers.new};
   overflow: hidden;
`,
  BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.down('sm')}{
  flex-wrap: wrap;
  }
`,
  City = styled.span`
  color: #232733;
  font-size: 0.875rem;
  line-height: 2.375rem;
  display: flex;
`,
  PinIcon = styled.img`
  margin-right: 0.5rem;
`,
  Team = styled.span`
  font-size: 0.938rem;
  width: 100%;
  padding-bottom: 0.625rem;

  ${media.down('md')}{
    font-size: 0.875rem;
  }
`,
  Title = styled.span`
  width: 100%;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: normal;

    ${media.up('md')}{
      text-overflow: ellipsis;
  overflow: hidden;
      max-height: 4.4rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 2.2rem;
    }
    ${media.down('md')}{
      height: auto;
      font-size: 1.188rem;
      line-height: 1.8rem;
      min-height: 3.913rem;
    }
`,
  DetailsButton = styled.button`
  border: 0;
  font-weight: 500;
  letter-spacing: -0.01125rem;
  text-transform: uppercase;
  line-height: normal;
  font-size: 15px;
  width: 9.313rem;
  height: 2.5rem;
  color: ${(props) => props.theme.colors.lightText};
  font-family: ${(props) => props.theme.font};
  background-color: ${(props) => props.theme.colors.blueButtonBackground};
  cursor: pointer; 
  transition-duration: 0.2s;
  display: flex;
  padding: 0.5rem 1.063rem;
  justify-content: space-between;
  align-items: center;
  outline: none;
  margin-top: 0.3rem;

  &:after {
    content: "";
    background: url("${arrowRight}") no-repeat;
    background-size: 100%;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
  }

  &:hover{
  background-color: ${(props) => props.theme.colors.blueButtonBackgroundHover};
  
  &:after {
    background: url("${arrowRightHover}") no-repeat;
    background-size: 100% 100%;}
  }
  ${media.down('md')}{
    width: 8.5rem;
    height: 2.5rem;
  }
`;
