import styled, { css } from 'styled-components';
import { IStyledButtonProps, IStyledIconProps } from './interfaces';
import { media } from 'core/theme';

import TestIcon from 'assets/images/header/icon-test.svg';
import TestIconMobile from 'assets/images/header/icon-test-mobile.svg';
import OfferIcon from 'assets/images/header/icon-offer.svg';
import OfferIconMobile from 'assets/images/header/icon-offer-mobile.svg';

export const Box = styled.div`
    z-index: 5;
    border-left: 1px solid #c9d2e3;
    order: 1;
    margin-top: 1.875rem;
    display: flex;
    padding: 0 1.875rem;

    ${media.up('sm')} {
      padding: 0;
      display: block;
      margin-top: 0;
      order: 1;
      float: right;
      height: 100%;
    }

    & > a {
      width: calc(50% - (0.938rem / 2));
      &.blue {
        margin: 0 0 0 auto;
      }
      ${media.up('sm')} {
        width: auto;
        &.blue {
          margin: 0;
        }
      }
      &.disabled {
        ${media.down('sm')} {
          display: none;
        }
      }
    }
  `,
  Icon = styled.span<IStyledIconProps>`
    background-repeat: no-repeat;
    display: inline-block;

    ${(props) =>
      props.type === 'offer' &&
      css`
        background-image: url('${OfferIconMobile}');
        width: 32px;
        height: 30px;

        ${media.up('sm')} {
          background-image: url('${OfferIcon}');
          width: 16px;
          height: 16px;
        }
      `}

    ${(props) =>
      props.type === 'test' &&
      css`
        background-image: url('${TestIconMobile}');
        width: 31px;
        height: 29px;

        ${media.up('sm')} {
          background-image: url('${TestIcon}');
          width: 16px;
          height: 15px;
          font-size: 0.875rem;
        }
      `}
    
     vertical-align: middle;
    margin-right: 0.625rem;

    ${media.down('sm')} {
      margin: 0 auto 1rem auto;
      display: block;
    }
  `,
  StyledButton = styled.button<IStyledButtonProps>`
    height: 100%;
    font-family: Poppins;
    font-weight: 500;
    color: #000000;
    background: ${(props) => props.theme.colors.lightText};
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 2rem 0;
    border: solid 1px ${(props) => props.theme.colors.lines};
    width: 100%;
    line-height: 1.29;

    &:focus {
      outline: none;
    }
    ${media.up('sm')} {
      padding: 0 2.5rem;
      border: 0;
      width: auto;
      line-height: normal;
      font-size: 1rem;
    }
    @media (min-width: 900px) and (max-width: 1550px) {
      font-size: 0.775rem;
      padding: 0 2rem;
    }

    ${(props) =>
      props.buttonType === 'blue' &&
      css`
        color: ${(props) => props.theme.colors.lightText};
        background-color: ${(props) => props.theme.colors.blueButtonBackground};
        border: 0;
      `}

    span:not(${Icon}) {
      word-break: break-word;
      word-spacing: 100px;
      ${media.up('sm')} {
        word-spacing: normal;
        word-break: normal;
      }
    }
  `;
